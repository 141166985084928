import request from '@/utils/request'

// 查询同记录列表
export function listContract(query) {
  return request({
    url: 'business/contract/getBusinessContractRecord',
    method: 'get',
    params: query
  })
}

// 查询法大大合同记录详细
export function getContract(id) {
  return request({
    url: '/business/contract/' + id,
    method: 'get'
  })
}

// 新增法大大合同记录
export function addContract(data) {
  return request({
    url: '/business/contract',
    method: 'post',
    data: data
  })
}

// 修改法大大合同记录
export function updateContract(data) {
  return request({
    url: '/business/contract',
    method: 'put',
    data: data
  })
}

// 删除法大大合同记录
export function delContract(id) {
  return request({
    url: '/business/contract/' + id,
    method: 'delete'
  })
}


export function signContract(params) {
  return request({
    url: 'business/contract/signContract',
    method: 'GET',
    params
  })
}
