<template>
  <div>
    <template>
      <div class="scrollImg">
        <img ref="image" class="carouselImg" src="../assets/zhangdanBg.png" />
      </div>
      <div class="box-tabs">
        <div class="box-content">
          <span class="box-tabs-name">我的账单</span>
          <!-- <boxTabs></boxTabs> -->
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              :label="item.label"
              :name="item.name"
              v-for="item in tabsList"
              :key="item.name"
            ></el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <!-- 待支付 -->
      <transition name="el-fade-in">
        <div class="box-content" v-if="activeName == '0'">
          <div class="box-table">
            <el-table
              :data="tableData"
              style="width: 100%"
              :header-cell-style="{
                background: '#F2F2F2',
                'text-align': 'center',
              }"
              border
              width="80"
            >
              <el-table-column label="账单时间" align="center" prop="billTime">
              </el-table-column>
              <el-table-column label="账单号" align="center" prop="billNum">
              </el-table-column>
              <el-table-column
                label="账单总金额(元)"
                align="center"
                prop="billTotalMoney"
              >
              </el-table-column>
              <el-table-column
                label="已回款金额(元)"
                align="center"
                prop="receivedAmount"
              >
              </el-table-column>
              <el-table-column
                label="剩余回款(元)"
                align="center"
                prop="remainingCollectionAmount"
              >
              </el-table-column>
              <el-table-column
                label="物流费剩余回款"
                align="center"
                prop="logisticsExpensesRemainingCollectionAmount"
              >
              </el-table-column>
              <el-table-column
                label="仓储费剩余回款"
                align="center"
                prop="storageFeeRemainingCollectionAmount"
              >
              </el-table-column>
              <el-table-column label="状态" align="center" prop="billState">
                <template slot-scope="scope">
                  <div>
                    {{ getDictValue("bill_status", scope.row.billState) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="账单类型" align="center" prop="billType">
                <template slot-scope="scope">
                  <div>{{ getDictValue("bill_type", scope.row.billType) }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <div class="box-select-btn" @click="billDetails(scope.row)">
                    查看详情
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="box-pagination">
            <PaginationPage
              v-show="total > 0"
              :total="total"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="getData"
            />
          </div>
        </div>
      </transition>
      <!-- 已支付 -->
      <transition name="el-fade-in">
        <div class="box-content" v-if="activeName == '1'">
          <div class="box-table">
            <el-table
              :data="tableData"
              style="width: 100%"
              :header-cell-style="{
                background: '#F2F2F2',
                'text-align': 'center',
              }"
              border
              width="80"
            >
              <el-table-column label="账单时间" align="center" prop="billTime">
              </el-table-column>
              <el-table-column label="账单号" align="center" prop="billNum">
              </el-table-column>
              <el-table-column
                label="账单总金额(元)"
                align="center"
                prop="billTotalMoney"
              >
              </el-table-column>
              <el-table-column
                label="已回款金额(元)"
                align="center"
                prop="receivedAmount"
              >
              </el-table-column>
              <el-table-column
                label="剩余回款(元)"
                align="center"
                prop="remainingCollectionAmount"
              >
              </el-table-column>
              <el-table-column
                label="物流费剩余回款"
                align="center"
                prop="logisticsExpensesRemainingCollectionAmount"
              >
              </el-table-column>
              <el-table-column
                label="仓储费剩余回款"
                align="center"
                prop="storageFeeRemainingCollectionAmount"
              >
              </el-table-column>
              <el-table-column label="状态" align="center" prop="billState">
                <template slot-scope="scope">
                  <div>
                    {{ getDictValue("bill_status", scope.row.billState) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="账单类型" align="center" prop="billType">
                <template slot-scope="scope">
                  <div>{{ getDictValue("bill_type", scope.row.billType) }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <div class="box-select-btn" @click="billDetails(scope.row)">
                    查看详情
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="box-pagination">
            <PaginationPage
              v-show="total > 0"
              :total="total"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="getData"
            />
          </div>
        </div>
      </transition>
    </template>
  </div>
</template>
<script>
import { listBill } from "@/api/business/bill";
export default {
  name: "CessNav",
  components: {},
  data() {
    return {
      dicts: ["bill_type", "bill_status"],
      dict: {},
      activeName: "0",
      bannerHeight: "",
      tableData: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      tabsList: [
        {
          label: "待支付",
          name: "0",
        },
        {
          label: "已支付",
          name: "1",
        },
      ],
    };
  },
  computed: {
    getDictValue() {
      return (type, value) => {
        if (this.dict[type]) {
          const item = this.dict[type].find((item) => item.dictValue == value);
          return item ? item.dictLabel : value;
        }
      };
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      listBill({
        isPay: this.activeName,
        ...this.queryParams,
      }).then((res) => {
        this.tableData = res.data.data.list;
        this.total = res.data.data.total;
      });
    },
    handleClick() {
      this.getData();
    },
    billDetails(row) {
      this.$router.push({
        path: "/billDetails",
        query: {
          id: row.id,
        },
      });
    },
  },
  mounted() {
    for (let i = 0; i < this.dicts.length; i++) {
      this.getDicts(this.dicts[i]).then((res) => {
        this.dict[this.dicts[i]] = res.data.data;
      });
    }
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.bannerHeight = (600 / 1550) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (600 / 1550) * this.screenWidth;
    };
  },
};
</script>
<style scoped>
.box-addBtn {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  background: #40b8ad;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
  cursor: pointer;
}

.box-table {
  margin-top: 30px;
}

.edit-content {
  float: right;
  margin-right: 30px;
  display: flex;
  margin-top: 30px;
}

.re-certification {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #40b8ad;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-left: 20px;
  cursor: pointer;
}

.personal-edit {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #ffffff;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #40b8ad;
  cursor: pointer;
}

.personal-phone {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 16px;
  text-align: left;
}

.personal-name {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  margin-top: 16px;
  text-align: left;
}

.personal-corporation {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 72px;
  text-align: left;
}

.personal-corporation span {
  margin-left: 20px;
  padding: 4px 6px;
  background: #ffffff;
  border: 1px solid #ff9000;
  border-radius: 5px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ff9000;
}

.personal-text {
  margin-left: 30px;
}

.box-personal-session {
  float: left;
  margin-left: 50px;
  height: 100%;
  display: flex;
}

.block {
  margin-top: 52px;
}

.box-personal {
  height: 238px;
  background: #f6f6f6;
  margin: 60px 0;
  position: relative;
}

.box-tabs-name {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  height: 90px;
  line-height: 90px;
  float: left;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-tabs__header {
  margin: 0;
}

/deep/ .el-tabs__content {
  display: none;
}

/deep/ .el-tabs__item {
  height: 90px;
  line-height: 90px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

/deep/ .el-tabs {
  float: right;
}

.box-tabs {
  height: 90px;
  background: #f6f6f6;
}

.box-select-submit {
  display: block;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 100px;
  width: 160px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 24px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

.box-select-submit:hover {
  opacity: 0.8;
}

.card-address {
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  text-align: left;
  margin-top: 10px;
}

.card-title {
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  text-align: left;
}

.card-img {
  width: 100%;
  height: 246px;
}

.box-card {
  width: 380px;
  height: 340px;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-bottom: 30px;
  transition: all 0.3s;
  /* 上浮这个过程需要的时间 */
  cursor: pointer;
}

.box-card:hover {
  box-shadow: 0px 0px 10px 0px rgba(65, 181, 172, 0.35);
  transform: translate(0, -3px);
}

.box-card-content {
  padding: 20px;
  height: 54px;
}

.box-tab-card {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
}

.box-card-title {
  text-align: center;
  height: 42px;
  line-height: 42px;
  font-size: 42px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231714;
  margin-top: 167px;
}

.borderShare {
  border-right: 1px dashed #eeeeee;
}

.box-tab {
  text-align: center;
  width: 25%;
  box-sizing: border-box;
  cursor: pointer;
}

.box-tab span {
  display: block;
  text-align: center;
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  margin-top: 10px;
}

.box-tab img {
  display: block;
  width: 92px;
  margin: 0 auto;
  margin-top: 13px;
}

.box-fixed-tab {
  position: relative;
  margin: 0 auto;
  margin-top: -88px;
  width: 1200px;
  height: 150px;
  padding: 13px 0;
  background: #ffffff;
  box-shadow: 0px -6px 24px 0px rgba(65, 181, 172, 0.35);
  z-index: 99;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.carouselImg {
  width: 100%;
  height: inherit;
}

.scrollImg {
  width: 100%;
  height: auto;
  /* max-height: 780px; */
  position: relative;
}

/deep/ .el-carousel--horizontal {
  max-height: 780px;
}

/deep/ .el-carousel__container {
  max-height: 780px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.demo {
  display: inline-block;
  width: 600px;
  height: 338px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
</style>
