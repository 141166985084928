<template>
  <div>
    <template>
      <div class="scrollImg">
        <img ref="image" class="carouselImg" src="../assets/mineBg.png" />
      </div>
      <div class="box-tabs">
        <div class="box-content">
          <span class="box-tabs-name">全部功能</span>
          <boxTabs :tabsIndex="0"></boxTabs>
        </div>
      </div>
      <div class="box-content">
        <div class="box-avatar">
          <div class="box-avatar-img">
            <img
              :src="avatar ? `${avatar}` : require('../assets/avator.png')"
            />
          </div>
          <el-upload
            class="upload-demo"
            :show-file-list="false"
            :on-success="upload"
            :action="uploadImgUrl"
            :headers="headers"
          >
            <div class="box-avatar-sub box-all-btn">修改头像</div>
          </el-upload>
        </div>
        <div class="box-form-subtext">
          <el-form ref="form" label-width="120px">
            <el-form-item label="联系人姓名">
              <el-input v-model="contactName"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input
                disabled
                v-model="getUserSession.customer.phone"
              ></el-input>
            </el-form-item>
            <el-form-item label="企业类型">
              <el-radio-group
                v-model="orgType"
                style="float: left; margin-top: 12px"
              >
                <el-radio :label="1">贸易企业</el-radio>
                <el-radio :label="2">生产企业</el-radio>
                <el-radio :label="3">金融企业</el-radio>
                <el-radio :label="4">其他企业</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
        <div class="box-submit box-all-btn" @click="submit">提交</div>
      </div>
    </template>
  </div>
</template>
<script>
import boxTabs from "@/components/boxTabs.vue";
import { mapGetters } from "vuex";
import {
  updateContactName,
  updateAvatar,
  updateOrgType,
  getUser,
} from "@/utils/api";
export default {
  name: "CessNav",
  components: {
    boxTabs,
  },
  data() {
    return {
      radio: null,
      sessiontext: "我的货物",
      bannerHeight: "",
      contactName: "",
      orgType: "",
      avatar: "",
      uploadImgUrl: process.env.VUE_APP_BASE_API + "/common/ossUpload", // 上传的图片服务器地址
      headers: {
        Authorization: "Bearer " + localStorage.getItem("Token"),
      },
    };
  },
  computed: {
    ...mapGetters(["getUserSession"]),
  },
  methods: {
    submit() {
      if (!this.avatar || !this.orgType || !this.contactName) {
        this.$message.warning("请填写需要修改的信息");
        return;
      }
      let all = [];
      if (this.avatar) {
        all.push(updateAvatar);
        updateAvatar({
          avatar: this.avatar,
        });
      }
      if (this.orgType) {
        all.push(updateOrgType);
        updateOrgType({
          orgType: this.orgType,
        });
      }
      if (this.contactName) {
        all.push(updateContactName);
        updateContactName({
          contactName: this.contactName,
        });
      }

      Promise.all(all).then((res) => {
        this.$message.success("操作成功");
        getUser().then((response) => {
          this.$store.commit("setUserSession", response.data);
        });
      });
    },
    upload(response) {
      this.avatar = response.fileName;
    },
  },
  mounted() {
    this.contactName = this.getUserSession.customer.contactName;
    this.orgType = this.getUserSession.customer.orgType;
    this.avatar = this.getUserSession.customer.avatar;
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.bannerHeight = (600 / 1550) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (600 / 1550) * this.screenWidth;
    };
  },
};
</script>
<style scoped>
.box-avatar-sub {
  width: 96px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
}

.box-avatar-img img {
  width: 100%;
  height: 100%;
}

.box-avatar-img {
  width: 134px;
  height: 134px;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}

.box-avatar {
  margin: 80px 0;
}

.box-submit {
  width: 450px;
  height: 62px;
  line-height: 62px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 31px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 140px;
  margin-bottom: 140px;
}

.box-form-subtext {
  width: 720px;
  margin: 0 auto;
}

.box-addBtn {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  background: #40b8ad;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
  cursor: pointer;
}

.box-table {
  margin-top: 30px;
}

.edit-content {
  float: right;
  margin-right: 30px;
  display: flex;
  margin-top: 30px;
}

.re-certification {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #40b8ad;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-left: 20px;
  cursor: pointer;
}

.personal-edit {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #ffffff;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #40b8ad;
  cursor: pointer;
}

.personal-phone {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 16px;
  text-align: left;
}

.personal-name {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  margin-top: 16px;
  text-align: left;
}

.personal-corporation {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 72px;
  text-align: left;
}

.personal-corporation span {
  margin-left: 20px;
  padding: 4px 6px;
  background: #ffffff;
  border: 1px solid #ff9000;
  border-radius: 5px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ff9000;
}

.personal-text {
  margin-left: 30px;
}

.box-personal-session {
  float: left;
  margin-left: 50px;
  height: 100%;
  display: flex;
}

.block {
  margin-top: 52px;
}

.box-personal {
  height: 238px;
  background: #f6f6f6;
  margin: 60px 0;
  position: relative;
}

.box-tabs-name {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  height: 90px;
  line-height: 90px;
  float: left;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-tabs__header {
  margin: 0;
}

/deep/ .el-tabs__content {
  display: none;
}

/deep/ .el-tabs__item {
  height: 90px;
  line-height: 90px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

/deep/ .el-tabs {
  float: right;
}

.box-tabs {
  height: 90px;
  background: #f6f6f6;
}

.box-select-submit {
  display: block;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 100px;
  width: 160px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 24px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

.box-select-submit:hover {
  opacity: 0.8;
}

.card-address {
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  text-align: left;
  margin-top: 10px;
}

.card-title {
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  text-align: left;
}

.card-img {
  width: 100%;
  height: 246px;
}

.box-card {
  width: 380px;
  height: 340px;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-bottom: 30px;
  transition: all 0.3s;
  /* 上浮这个过程需要的时间 */
  cursor: pointer;
}

.box-card:hover {
  box-shadow: 0px 0px 10px 0px rgba(65, 181, 172, 0.35);
  transform: translate(0, -3px);
}

.box-card-content {
  padding: 20px;
  height: 54px;
}

.box-tab-card {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
}

.box-card-title {
  text-align: center;
  height: 42px;
  line-height: 42px;
  font-size: 42px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231714;
  margin-top: 167px;
}

.borderShare {
  border-right: 1px dashed #eeeeee;
}

.box-tab {
  text-align: center;
  width: 25%;
  box-sizing: border-box;
  cursor: pointer;
}

.box-tab span {
  display: block;
  text-align: center;
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  margin-top: 10px;
}

.box-tab img {
  display: block;
  width: 92px;
  margin: 0 auto;
  margin-top: 13px;
}

.box-fixed-tab {
  position: relative;
  margin: 0 auto;
  margin-top: -88px;
  width: 1200px;
  height: 150px;
  padding: 13px 0;
  background: #ffffff;
  box-shadow: 0px -6px 24px 0px rgba(65, 181, 172, 0.35);
  z-index: 99;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.carouselImg {
  width: 100%;
  height: inherit;
}

.scrollImg {
  width: 100%;
  height: auto;
  /* max-height: 780px; */
  position: relative;
}

/deep/ .el-carousel--horizontal {
  max-height: 780px;
}

/deep/ .el-carousel__container {
  max-height: 780px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.demo {
  display: inline-block;
  width: 600px;
  height: 338px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
</style>
