<template>
  <div>
    <template>
      <div class="scrollImg">
        <el-image
          ref="image"
          class="carouselImg"
          :src="require('../assets/ckimg.jpg')"
        />
      </div>
      <div class="box-content">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="150px"
          class="demo-ruleForm"
          @submit.native.prevent
        >
          <titlePage :titleText="sessiontext" class="li-top"></titlePage>
          <div class="box-form">
            <!-- <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"> -->
            <el-row>
              <!-- <el-col :span="24">
								<el-col :span="8">
									<el-form-item label="订单号" prop="orderNum">
										<el-input v-model="ruleForm.orderNum" placeholder="请输入订单号"></el-input>
									</el-form-item>
								</el-col>
							</el-col> -->
              <el-col :span="24">
                <el-col :span="8">
                  <el-form-item label="选择出库仓库" prop="warehouseName">
                    <div @click="clickDetail">
                      <el-input
                        v-model="ruleForm.warehouseName"
                        class="input-with-select"
                        readonly="readonly"
                      >
                        <el-button
                          slot="append"
                          icon="el-icon-search"
                        ></el-button>
                      </el-input>
                    </div>
                  </el-form-item>
                </el-col>
              </el-col>
              <el-col :span="24">
                <el-col :span="8">
                  <el-form-item
                    label="预计出库时间"
                    prop="estimatedDeliveryTime"
                  >
                    <el-date-picker
                      v-model="ruleForm.estimatedDeliveryTime"
                      placeholder="选择日期"
                      :picker-options="pickerOptions"
                      type="datetime"
                      value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-row>
            <!-- </el-form> -->
          </div>
          <!-- 商品信息 -->
          <titlePage :titleText="shoptext" class="li-top"></titlePage>

          <div class="box-form">
            <el-table
              :data="selectLibraryShopList"
              style="width: 100%"
              :header-cell-style="{
                background: '#F2F2F2',
                'text-align': 'center',
                color: '#333333',
              }"
              border
              width="80"
            >
              <el-table-column label="商品分类" align="center" prop="typeName">
                <template slot-scope="{ row }">
                  {{ row.typeName ? row.typeName : row.goodsTypeName }}
                </template>
              </el-table-column>
              <el-table-column label="商品名称" align="center" prop="goodsName">
              </el-table-column>
              <el-table-column
                label="商品厂家"
                align="center"
                prop="goodsManufacturer"
              >
                <template slot-scope="{ row }">
                  {{
                    row.goodsManufacturer
                      ? row.goodsManufacturer
                      : row.goodsManufacturerName
                  }}
                </template>
              </el-table-column>
              <el-table-column label="型号" align="center" prop="goodsModel">
                <template slot-scope="{ row }">
                  {{ row.goodsModel ? row.goodsModel : row.goodsModelName }}
                </template>
              </el-table-column>
              <el-table-column label="规格" align="center" prop="goodsSpec">
                <template slot-scope="{ row }">
                  {{ row.goodsSpec ? row.goodsSpec : row.goodsSpecName }}
                </template>
              </el-table-column>
              <el-table-column label="总数量" align="center" prop="quantity">
                <template slot-scope="{ row }">
                  {{ row.quantity ? row.quantity : "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="出库数量"
                align="center"
                prop="totalQuantity"
              >
                <template slot-scope="{ row }">
                  {{ row.totalQuantity ? row.totalQuantity : "--" }}
                </template>
              </el-table-column>
              <el-table-column label="总数量单位" align="center">
                <template slot-scope="{ row }">
                  {{
                    row.quantityUnit || row.totalQuantityUnit
                      ? dict.sys_user_count_unit[
                          row.quantityUnit
                            ? row.quantityUnit
                            : row.totalQuantityUnit
                        ]
                      : "--"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="总重量" align="center" prop="weight">
                <template slot-scope="{ row }">
                  {{ row.weight ? row.weight : "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="出库重量"
                align="center"
                prop="totalWeight"
              >
                <template slot-scope="{ row }">
                  {{ row.totalWeight ? row.totalWeight : "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="总重量单位"
                align="center"
                prop="weightUnit"
              >
                <template slot-scope="{ row }">
                  {{
                    row.weightUnit || row.totalWeightUnit
                      ? dict.sys_user_weight_unit[
                          row.weightUnit ? row.weightUnit : row.totalWeightUnit
                        ]
                      : "--"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="净重" align="center" prop="netWeight">
                <template slot-scope="{ row }">
                  {{ row.netWeight ? row.netWeight : "--" }}
                </template>
              </el-table-column>
              <el-table-column label="出库净重" align="center">
                <template slot-scope="{ row }">
                  {{ row.totalNetWeight ? row.totalNetWeight : "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="净重单位"
                align="center"
                prop="netWeightUnit"
              >
                <template slot-scope="{ row }">
                  {{
                    row.netWeightUnit
                      ? dict.sys_user_weight_unit[scope.row.netWeightUnit]
                      : "--"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="入库要求"
                align="center"
                prop="warehousingRequirements"
              >
                <template slot-scope="scope">
                  {{
                    dict.warehousing_requirements[
                      scope.row.warehousingRequirements
                    ]
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="是否带托入库"
                align="center"
                prop="isWarehousingWithTray"
              >
                <template slot-scope="scope">
                  {{ dict.sys_yes_no[scope.row.isWarehousingWithTray] }}
                </template>
              </el-table-column>
              <el-table-column
                label="关联订单"
                align="center"
                prop="inventoryVOList"
                width="180"
              >
                <template slot-scope="scope">
                  <span
                    v-for="(item, index) in scope.row.inventoryVOList"
                    :key="index"
                    ><p>{{ item.orderNum }}</p></span
                  >
                  <!-- <span>{{scope.row.inventoryVOList}}</span> -->
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" fixed="right">
                <template slot-scope="scope">
                  <el-button
                    @click="handleAssociation(scope.$index)"
                    type="text"
                    size="small"
                    >关联订单</el-button
                  >
                  <el-button
                    @click="handleRemove(scope.$index)"
                    type="text"
                    size="small"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="box-addBtn" @click="shopDetail()">添加商品</div>

          <titlePage :titleText="goodstext" class="li-top"></titlePage>
          <div class="box-form">
            <!-- <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm"> -->
            <el-row>
              <el-col :span="24">
                <el-col :span="8">
                  <el-form-item
                    label=" 是否需要平台司机"
                    prop="isNeedPlatformDriver"
                  >
                    <el-select
                      v-model="ruleForm.isNeedPlatformDriver"
                      placeholder="请选择"
                    >
                      <el-option label="使用平台司机" value="1"></el-option>
                      <el-option label="自备司机" value="0"></el-option>
                    </el-select>
                    <div class="customer-sign">
                      <el-link
                        @click="createWlht"
                        :underline="false"
                        v-if="
                          ruleForm.isNeedPlatformDriver == 1 &&
                          !this.isCustomerSign
                        "
                        >去签署物流合同
                      </el-link>
                    </div>
                  </el-form-item>
                </el-col>
              </el-col>
              <template v-if="ruleForm.isNeedPlatformDriver == 1">
                <el-col :span="24">
                  <el-col :span="8">
                    <el-form-item label="收货地址">
                      <maps
                        @mapHere="costPlannedAmountChange"
                        style="width: 600px"
                      >
                      </maps>
                    </el-form-item>
                  </el-col>
                </el-col>
                <el-col :span="24">
                  <el-col :span="8">
                    <el-form-item label="提货详细地址" prop="receiptAddress">
                      <el-input v-model="ruleForm.receiptAddress"></el-input>
                    </el-form-item>
                  </el-col>
                </el-col>
                <el-col :span="24">
                  <el-col :span="8">
                    <el-form-item label="收货联系人" prop="receiptContact">
                      <el-input
                        v-model="ruleForm.receiptContact"
                        placeholder="请输入收货联系人"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-col>
                <el-col :span="24">
                  <el-col :span="8">
                    <el-form-item
                      label="联系人手机号"
                      prop="receiptContactPhone"
                    >
                      <el-input
                        v-model="ruleForm.receiptContactPhone"
                        placeholder="请输入联系人手机号"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-col>
                <el-col :span="24">
                  <el-col :span="24">
                    <el-form-item label="收货凭证单" prop="receiptVoucherPic">
                      <ImageUpload
                        v-model="ruleForm.receiptVoucherPic"
                        :limit="1"
                      ></ImageUpload>
                    </el-form-item>
                  </el-col>
                </el-col>
              </template>
            </el-row>
            <!-- </el-form> -->
          </div>
          <div class="box-table" v-if="ruleForm.isNeedPlatformDriver == '0'">
            <!-- <el-form :model="ruleForm" :rules="rules" ref="ruleForm-three" label-width="0px"
						class="demo-ruleForm"> -->
            <el-form-item
              prop="isNeedPlatformDriver"
              v-if="ruleForm.isNeedPlatformDriver == '0'"
            >
              <vxe-table
                border
                show-overflow
                keep-source
                ref="driverTable"
                max-height="400"
                :data="driverTableData"
                :edit-rules="driverValidRules"
                :edit-config="{
                  trigger: 'click',
                  mode: 'cell',
                  showStatus: true,
                  icon: 'none',
                }"
                align="center"
              >
                <vxe-column
                  field="driverName"
                  title="司机姓名"
                  :edit-render="{ autofocus: '.vxe-input--inner' }"
                >
                  <template #edit="{ row }">
                    <vxe-input v-model="row.driverName" type="text"></vxe-input>
                  </template>
                </vxe-column>
                <vxe-column
                  field="driverPhone"
                  title="手机号"
                  :edit-render="{ autofocus: '.vxe-input--inner' }"
                >
                  <template #edit="{ row }">
                    <vxe-input
                      v-model="row.driverPhone"
                      type="text"
                    ></vxe-input>
                  </template>
                </vxe-column>
                <vxe-column
                  field="driverIdCard"
                  title="身份证号"
                  :edit-render="{ autofocus: '.vxe-input--inner' }"
                >
                  <template #edit="{ row }">
                    <vxe-input
                      v-model="row.driverIdCard"
                      type="text"
                    ></vxe-input>
                  </template>
                </vxe-column>
                <vxe-column
                  field="carNumber"
                  title="车牌号"
                  :edit-render="{ autofocus: '.vxe-input--inner' }"
                >
                  <template #edit="{ row }">
                    <vxe-input v-model="row.carNumber" type="text"></vxe-input>
                  </template>
                </vxe-column>
                <vxe-column title="运输商品">
                  <template #default="{ row }">
                    <vxe-button @click="addGoods(row)">{{
                      row.driverGoodsList ? "编辑" : "添加"
                    }}</vxe-button>
                  </template>
                </vxe-column>
                <vxe-column title="操作">
                  <template #default="{ row }">
                    <vxe-button @click="removeRowEvent(row)">删除</vxe-button>
                  </template>
                </vxe-column>
              </vxe-table>
            </el-form-item>
            <!-- </el-form> -->
          </div>

          <div
            class="box-addBtn"
            v-if="ruleForm.isNeedPlatformDriver == '0'"
            @click="driverInsertEvent"
          >
            新增司机
          </div>
          <!-- <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"> -->
          <el-row>
            <el-col :span="24">
              <el-form-item
                label="简要备注"
                prop="remark"
                style="margin-top: 60px"
              >
                <el-input
                  v-model="ruleForm.remark"
                  type="textarea"
                  placeholder="请输入备注"
                  class="box-textarea"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="agreeOn-success" @click="submitFrom">提交并出库</div>
      </div>
    </template>
    <taostOutShop
      ref="company"
      @warehouseSelect="warehouseSelect"
    ></taostOutShop>
    <dialogPage taostTips="选择在库商品" width="1000px" ref="shop">
      <template v-slot:content>
        <div>
          <el-table
            :data="libraryShopList"
            style="width: 100%"
            height="700"
            :header-cell-style="{
              background: '#F2F2F2',
              'text-align': 'center',
              color: '#333333',
            }"
            border
            width="80"
          >
            <el-table-column label="商品分类" align="center" prop="typeName">
            </el-table-column>
            <el-table-column label="商品名称" align="center" prop="goodsName">
            </el-table-column>
            <el-table-column
              label="商品厂家"
              align="center"
              prop="goodsManufacturer"
            >
            </el-table-column>
            <el-table-column label="型号" align="center" prop="goodsModel">
            </el-table-column>
            <el-table-column label="规格" align="center" prop="goodsSpec">
            </el-table-column>
            <el-table-column label="总数量" align="center" prop="quantity">
              <template slot-scope="{ row }">
                {{ row.quantityUnit ? row.quantity : "--" }}
              </template>
            </el-table-column>
            <el-table-column
              label="总数量单位"
              align="center"
              prop="quantityUnit"
            >
              <template slot-scope="{ row }">
                {{
                  row.quantityUnit
                    ? dict.sys_user_count_unit[row.quantityUnit]
                    : "--"
                }}
              </template>
            </el-table-column>
            <el-table-column label="总重量" align="center" prop="weight">
              <template slot-scope="{ row }">
                {{ row.weightUnit ? row.weight : "--" }}
              </template>
            </el-table-column>
            <el-table-column
              label="总重量单位"
              align="center"
              prop="weightUnit"
            >
              <template slot-scope="{ row }">
                {{
                  row.weightUnit
                    ? dict.sys_user_weight_unit[row.weightUnit]
                    : "--"
                }}
              </template>
            </el-table-column>
            <el-table-column label="净重" align="center" prop="netWeight">
              <template slot-scope="{ row }">
                {{ row.netWeightUnit ? row.netWeight : "--" }}
              </template>
            </el-table-column>
            <el-table-column
              label="净重单位"
              align="center"
              prop="netWeightUnit"
            >
              <template slot-scope="{ row }">
                {{
                  row.netWeightUnit
                    ? dict.sys_user_weight_unit[row.netWeightUnit]
                    : "--"
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="入库要求"
              align="center"
              prop="warehousingRequirements"
            >
              <template slot-scope="scope">
                {{
                  dict.warehousing_requirements[
                    scope.row.warehousingRequirements
                  ]
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="是否带托入库"
              align="center"
              prop="isWarehousingWithTray"
            >
              <template slot-scope="scope">
                {{ dict.sys_yes_no[scope.row.isWarehousingWithTray] }}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row)"
                  type="text"
                  size="small"
                  >选择</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </dialogPage>
    <dialogPage taostTips="选择关联订单" width="80%" ref="orders">
      <template v-slot:content>
        <div class="">
          <el-table
            :data="selectOrderList"
            style="width: 100%"
            :header-cell-style="{
              background: '#F2F2F2',
              'text-align': 'center',
              color: '#333333',
            }"
            border
            width="80"
          >
            <el-table-column label="商品分类" align="center" prop="typeName">
            </el-table-column>
            <el-table-column label="商品名称" align="center" prop="goodsName">
            </el-table-column>
            <el-table-column
              label="商品厂家"
              align="center"
              prop="goodsManufacturer"
            >
            </el-table-column>
            <el-table-column label="型号" align="center" prop="goodsModel">
            </el-table-column>
            <el-table-column label="规格" align="center" prop="goodsSpec">
            </el-table-column>
            <el-table-column label="总数量" align="center" prop="quantity">
              <template slot-scope="{ row }">
                {{ row.quantity ? row.quantity : "--" }}
              </template>
            </el-table-column>
            <el-table-column label="出库数量" align="center">
              <template slot-scope="{ row }">
                <el-input
                  :disabled="!row.quantityUnit"
                  class="table_input"
                  v-model="row.totalQuantity"
                  @change="ruleTotal(row, 'quantity', 'totalQuantity')"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="总数量单位"
              align="center"
              prop="quantityUnit"
            >
              <template slot-scope="{ row }">
                {{
                  row.quantityUnit
                    ? dict.sys_user_count_unit[row.quantityUnit]
                    : "--"
                }}
              </template>
            </el-table-column>
            <el-table-column label="总重量" align="center">
              <template slot-scope="{ row }">
                {{ row.weight ? row.weight : "--" }}
              </template>
            </el-table-column>
            <el-table-column label="出库重量" align="center">
              <template slot-scope="{ row }">
                <el-input
                  :disabled="!row.weightUnit"
                  class="table_input"
                  v-model="row.totalWeight"
                  @change="ruleTotal(row, 'weight', 'totalWeight')"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="总重量单位"
              align="center"
              prop="weightUnit"
            >
              <template slot-scope="{ row }">
                {{
                  row.weightUnit
                    ? dict.sys_user_weight_unit[row.weightUnit]
                    : "--"
                }}
              </template>
            </el-table-column>
            <el-table-column label="净重" align="center" prop="netWeight">
              <template slot-scope="{ row }">
                {{ row.netWeight ? row.netWeight : "--" }}
              </template>
            </el-table-column>
            <el-table-column label="出库净重" align="center">
              <template slot-scope="{ row }">
                <el-input
                  :disabled="!row.netWeightUnit"
                  class="table_input"
                  v-model="row.totalNetWeight"
                  @change="ruleTotal(row, 'netWeight', 'totalNetWeight')"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="净重单位"
              align="center"
              prop="netWeightUnit"
            >
              <template slot-scope="{ row }">
                {{
                  row.netWeightUnit
                    ? dict.sys_user_weight_unit[row.netWeightUnit]
                    : "--"
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="入库要求"
              align="center"
              prop="warehousingRequirements"
            >
              <template slot-scope="scope">
                {{
                  dict.warehousing_requirements[
                    scope.row.warehousingRequirements
                  ]
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="是否带托入库"
              align="center"
              prop="isWarehousingWithTray"
            >
              <template slot-scope="scope">
                {{ dict.sys_yes_no[scope.row.isWarehousingWithTray] }}
              </template>
            </el-table-column>
            <el-table-column
              label="所在仓库"
              align="center"
              prop="warehouseName"
            >
            </el-table-column>
            <el-table-column label="订单号" align="center" prop="orderNum">
            </el-table-column>
            <!-- <el-table-column label="操作" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button
                  @click="orderHandleClick(scope.row)"
                  type="text"
                  size="small"
                  >选择</el-button
                >
              </template>
            </el-table-column> -->
          </el-table>
          <div class="box-addBtn" @click="orderHandleClick">确定</div>
        </div>
      </template>
    </dialogPage>
    <dialogPage taostTips="添加运输商品" ref="confirmGoods" width="80%">
      <el-table
        :data="selectGoodsList"
        style="width: 100%"
        :header-cell-style="{
          background: '#F2F2F2',
          'text-align': 'center',
          color: '#333333',
        }"
        border
        width="80"
        ref="selectGoods"
      >
        <el-table-column label="商品分类" align="center">
          <template slot-scope="{ row }">
            {{ row.typeName || row.goodsTypeName }}
          </template>
        </el-table-column>
        <el-table-column label="商品名称" align="center" prop="goodsName">
        </el-table-column>
        <el-table-column
          label="商品厂家"
          align="center"
          prop="goodsManufacturer"
        >
          <template slot-scope="{ row }">
            {{ row.goodsManufacturer || row.goodsManufacturerName }}
          </template>
        </el-table-column>
        <el-table-column label="型号" align="center" prop="goodsModel">
          <template slot-scope="{ row }">
            {{ row.goodsModel || row.goodsModelName }}
          </template>
        </el-table-column>
        <el-table-column label="规格" align="center" prop="goodsSpec">
          <template slot-scope="{ row }">
            {{ row.goodsSpec || row.goodsSpecName }}
          </template>
        </el-table-column>
        <el-table-column label="总数量" align="center" prop="totalQuantity">
          <template slot-scope="{ row }">
            {{ row.totalQuantity ? row.totalQuantity : "--" }}
          </template>
        </el-table-column>
        <el-table-column label="总数量单位" align="center" prop="quantityUnit">
          <template slot-scope="{ row }">
            {{
              row.quantityUnit
                ? dict.sys_user_count_unit[row.quantityUnit]
                : "--"
            }}
          </template>
        </el-table-column>
        <el-table-column label="总重量" align="center" prop="totalWeight">
          <template slot-scope="{ row }">
            {{ row.totalWeight ? row.totalWeight : "--" }}
          </template>
        </el-table-column>
        <el-table-column label="总重量单位" align="center" prop="weightUnit">
          <template slot-scope="{ row }">
            {{
              row.weightUnit ? dict.sys_user_weight_unit[row.weightUnit] : "--"
            }}
          </template>
        </el-table-column>
        <el-table-column label="净重" align="center" prop="netWeight">
          <template slot-scope="{ row }">
            {{ row.netWeight ? row.netWeight : "--" }}
          </template>
        </el-table-column>
        <el-table-column label="净重单位" align="center" prop="netWeightUnit">
          <template slot-scope="{ row }">
            {{
              row.netWeightUnit
                ? dict.sys_user_weight_unit[row.netWeightUnit]
                : "--"
            }}
          </template>
        </el-table-column>
        <el-table-column
          label="入库要求"
          align="center"
          prop="warehousingRequirements"
        >
          <template slot-scope="scope">
            <span>{{
              dict.warehousing_requirements[scope.row.warehousingRequirements]
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="是否带托入库"
          align="center"
          prop="isWarehousingWithTray"
        >
          <template slot-scope="scope">
            <span>{{ dict.sys_yes_no[scope.row.isWarehousingWithTray] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="运输量" align="center">
          <template slot-scope="scope">
            <el-input
              placeholder="请输入"
              @change="totalChange(scope.row)"
              v-model="scope.row.total"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
      <el-button type="primary" style="margin-top: 50px" @click="confirmGoods"
        >确定</el-button
      >
    </dialogPage>
  </div>
</template>
<script>
import VXETable from "vxe-table";
import maps from "@/components/maps/index";
import taostOutShop from "@/components/taostOutShop.vue";
// import taostStorehouse from "@/components/taostStorehouse.vue"
import {
  postWarehousing,
  goodsTypeListAll,
  goodsAttrListAll,
  libraryShop,
  updateOutboundOrder,
  outOrderLits,
} from "../api/warehouseOut.js";
import { isCustomerSignLogisticsContract } from "@/api/business/warehousingOrder";
import {
  getOutboundOrderDetail,
  getTransportationDocuments,
  getInventoryGoodsListGroupCargoNumber,
} from "@/api/business/warehousingOutOrder";
export default {
  name: "CessNav",
  components: {
    taostOutShop,
    // taostStorehouse,
    maps,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          const date = new Date();
          return time.getTime() < date.getTime() - 8.64e7; //禁止选取当天之后的日期（不包括静止当天）
        },
      },
      warehouseId: undefined,
      dicts: [
        "sys_yes_no",
        "sys_user_count_unit",
        "sys_user_weight_unit",
        "warehousing_requirements",
      ],
      //商品分类集合
      goodsTypeList: [],
      //商品集合
      goodsList: [],
      // 厂家列表
      manufacturerList: [],
      //型号列表
      goodsModeList: [],
      //规格列表
      goodsSpecList: [],
      gridData: [],
      deepGridData: [],
      activeDriverList: [],
      selectGoodsList: [],
      //仓位列表
      warehouseBinList: [],
      //是否显示净重等
      ifShowUnit: null,
      //司机表格
      driverTableData: [],
      //司机校验
      driverValidRules: {
        driverName: [
          {
            required: true,
            message: "司机名称不能为空",
          },
          {
            min: 1,
            max: 20,
            message: "司机名称长度在 1 到 20 个",
          },
        ],
        driverPhone: [
          {
            required: true,
            message: "司机联系方式不能为空",
          },
          {
            pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
            message: "请输入正确的手机号",
          },
        ],
        driverIdCard: [
          {
            required: true,
            message: "司机身份证号不能为空",
          },
          {
            pattern:
              /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/,
            message: "请输入正确的身份证号",
          },
        ],
        carNumber: [
          {
            required: true,
            message: "车牌号不能为空",
          },
          {
            pattern:
              /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z][A-HJ-NP-Z0-9]{4,5}[A-HJ-NP-Z0-9挂学警港澳]$/,
            message: "请输入正确的车牌号",
          },
        ],
      },
      bannerHeight: "",
      sessiontext: "基本信息",
      shoptext: "商品信息",
      goodstext: "收货信息",
      tableData: [],
      isCustomerSign: null,
      ruleForm: {
        warehouseId: "",
        warehouseName: "",
        driverList: [],
      },
      rules: {
        estimatedDeliveryTime: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        isNeedPlatformDriver: [
          {
            required: true,
            message: "请选择是否平台司机",
            trigger: "change",
          },
        ],
        receiptAddress: [
          {
            required: true,
            message: "请选择/填写详细地址",
            trigger: "change",
          },
        ],
        receiptContact: [
          {
            required: true,
            message: "请输入收货联系人",
            trigger: "change",
          },
        ],
        receiptContactPhone: [
          {
            required: true,
            message: "请输入联系人手机号",
            trigger: "change",
          },
        ],
        receiptVoucherPic: [
          {
            required: true,
            message: "请上传收货凭证单",
            trigger: "change",
          },
        ],
        warehouseName: [
          {
            required: true,
            message: "请选择一个仓库",
            trigger: "blur",
          },
        ],
      },
      dict: {
        sys_yes_no: {},
        sys_user_count_unit: {},
        sys_user_weight_unit: {},
        warehousing_requirements: {},
      },
      libraryShopList: [],
      selectLibraryShopList: [],
      selectOrderList: [],
      associationOrderIndex: "",
    };
  },
  computed: {},
  created() {
    //获取商品分类集合
    this.goodsTypeListAll();
    this.getunitType();
    this.isCustomerSignLogisticsContract();
    if (this.$route.query.orderId) {
      this.getInfo();
    }
  },
  methods: {
    ruleTotal(row, key1, key2) {
      if (row[key1] < row[key2]) {
        this.$message.warning("出库数量大于剩余数量！");
        row[key2] = row[key1];
      }
    },
    getTotal(arr, type) {
      if (arr && arr.length) {
        let total = arr.reduce((prev, item) => {
          return Number(prev) + Number(item[type]);
        }, 0);
        return total ? total : 0;
      } else {
        return 0;
      }
    },
    getInfo() {
      getOutboundOrderDetail(this.$route.query.orderId).then((response) => {
        this.ruleForm = response.data.data;
        this.selectLibraryShopList = response.data.data.goodsDetailRecordList;
        this.selectLibraryShopList.map((item, index) => {
          item.inventoryVOList = item.inventoryRecordList;
          item.subscriptn = index;
          item.weight = item.totalWeight ? "--" : "";
          item.quantity = item.totalQuantity ? "--" : "";
          item.netWeight = item.netWeight ? "--" : "";
        });

        if (this.ruleForm.driverRecordList) {
          this.driverTableData = this.ruleForm.driverRecordList.map((item) => {
            let arr = item.goodsList.map((item) => {
              let data = response.data.data.goodsDetailRecordList.find(
                (child) => {
                  return child.id == item.outboundOrderGoodsDetailId;
                }
              );
              if (data) {
                return {
                  ...data,
                  weight: item.totalWeight,
                  weightUnit: item.totalWeightUnit,
                  quantity: item.totalQuantity,
                  quantityUnit: item.totalQuantityUnit,
                };
              }
              return data;
            });
            return {
              driverIdCard: item.driverRecord.driverIdCard,
              driverName: item.driverRecord.driverName,
              driverPhone: item.driverRecord.driverPhone,
              carNumber: item.carNumber,
              outboundOrderDriverId: item.outboundOrderDriverId,
              driverGoodsList: arr,
            };
          });
        }
      });
    },
    isCustomerSignLogisticsContract() {
      isCustomerSignLogisticsContract().then((res) => {
        this.isCustomerSign = res.data.data;
      });
    },
    handleSelectGoods(row) {
      // let arr = row.map((item) => {
      //   let data = this.deepGridData.find((gridData) => {
      //     return gridData.subscriptn == item.subscriptn;
      //   });
      //   return data;
      // });
      this.selectGoodsList = JSON.parse(JSON.stringify(row));
    },
    confirmGoods(row) {
      let item = this.selectGoodsList.find((item) => item.total);
      if (!item) {
        this.$message.warning("输入司机运输量");
        return;
      }
      this.selectGoodsList.forEach((item, index) => {
        item[this.$weightUnit(item)] = item.total;
        if (item.totalQuantity) {
          item.totalQuantity = item.total;
          item.totalQuantityUnit = item.quantityUnit;
        }
        if (item.totalWeight) {
          item.totalWeight = item.total;
          item.totalWeightUnit = item.weightUnit;
        }
        if (item.netWeight) {
          item.netWeight = item.total;
        }
      });
      this.activeDriverList.driverGoodsList = JSON.parse(
        JSON.stringify(this.selectGoodsList)
      );
      this.selectGoodsList = [];
      this.$refs.confirmGoods.closeDialog();
    },
    deteleConfirmGoods(row) {
      this.$confirm("是否删除该条数据？", "提示", { type: "warning" }).then(
        (res) => {
          this.$delete(this.selectGoodsList, this.selectGoodsList.indexOf(row));
        }
      );
    },
    totalChange(row) {
      let total = 0;
      if (row.netWeight) {
        total = row.netWeight;
      }
      if (row.totalQuantity) {
        total = row.totalQuantity;
      }
      if (row.totalWeight) {
        total = row.totalWeight;
      }
      if (Number(row.total) > Number(total)) {
        this.$message.warning("运输量不能大于剩余运输量");
        row.total = total;
        return;
      }
    },
    addGoods(row) {
      if (!this.selectLibraryShopList.length) {
        this.$message.warning("请先选择商品");
        return;
      }
      this.gridData = this.selectLibraryShopList.map((item, index) => {
        return {
          ...item,
          subscriptn: index,
        };
      });
      this.activeDriverList = row;
      if (row.driverGoodsList) {
        row.driverGoodsList.map((item, index) => {
          item.totalWeight =
            this.selectLibraryShopList[item.subscriptn].totalWeight;
          item.totalQuantity =
            this.selectLibraryShopList[item.subscriptn].totalQuantity;
          item.netWeight =
            this.selectLibraryShopList[item.subscriptn].netWeight;
        });
        this.$set(
          this,
          "selectGoodsList",
          JSON.parse(JSON.stringify(row.driverGoodsList))
        );
      } else {
        this.$set(
          this,
          "selectGoodsList",
          JSON.parse(JSON.stringify(this.gridData))
        );
      }
      this.$refs.confirmGoods.showDialog();
    },
    orderHandleClick() {
      let arr = this.selectOrderList.filter((item) => {
        return item.totalWeight || item.totalQuantity || item.netWeight;
      });
      this.$set(
        this.selectLibraryShopList[this.associationOrderIndex],
        "inventoryVOList",
        arr
      );
      this.$set(
        this.selectLibraryShopList[this.associationOrderIndex],
        "totalWeight",
        this.getTotal(arr, "totalWeight")
      );
      this.$set(
        this.selectLibraryShopList[this.associationOrderIndex],
        "totalQuantity",
        this.getTotal(arr, "totalQuantity")
      );
      this.$set(
        this.selectLibraryShopList[this.associationOrderIndex],
        "totalNetWeight",
        this.getTotal(arr, "totalNetWeight")
      );
      this.$refs.orders.closeDialog();
    },
    //关联订单
    handleAssociation(index) {
      this.associationOrderIndex = index;
      outOrderLits({
        cargoNumber: this.selectLibraryShopList[index].cargoNumber,
        outboundOrderId: this.ruleForm.id,
      }).then((response) => {
        if (response.data.code == 200) {
          this.$refs.orders.showDialog();
          if (this.selectLibraryShopList[index].inventoryVOList) {
            response.data.rows.forEach((item, indx) => {
              let data = this.selectLibraryShopList[index].inventoryVOList.find(
                (child) => {
                  return child.goodsInventoryId == item.goodsInventoryId;
                }
              );
              if (data) {
                item.totalWeight = data.totalWeight;
                item.totalQuantity = data.totalQuantity;
              }
            });
          }
          this.selectOrderList = response.data.rows;
        } else {
          this.$message.error("预约失败！");
        }
      });
    },
    //删除商品
    handleRemove(index) {
      let arr = JSON.parse(JSON.stringify(this.selectLibraryShopList));
      arr.splice(index, 1);
      this.selectLibraryShopList = arr;
    },
    getShopList(id) {
      if (id) {
        getInventoryGoodsListGroupCargoNumber({
          outboundOrderId: this.ruleForm.id,
          warehouseId: id,
        }).then((response) => {
          if (response.data.code == 200) {
            this.libraryShopList = response.data.data;
          } else {
            this.$message.error("在库商品查询失败！");
          }
        });
      }
    },
    handleClick(tab) {
      let item = this.selectLibraryShopList.find((item) => {
        return item.cargoNumber == tab.cargoNumber;
      });

      if (item == undefined) {
        this.$refs.shop.closeDialog();
        tab.inventoryVOList = [];
        this.selectLibraryShopList.push(tab);
      } else {
        this.$message({
          message: "商品已经被选择了!！",
          type: "warning",
        });
      }
    },
    //清空表单
    clearForm() {
      this.ruleForm = {
        warehouseId: "",
        warehouseName: "",
        driverList: [],
        goodsDetailList: [],
        customerId: 0,
        receiptAddress: "",
        receiptAddressLatitude: "",
        receiptAddressLongitude: "",
        receiptContact: "",
        receiptContactPhone: "",
        receiptVoucherPic: "",
        estimatedDeliveryTime: "",
        isNeedPlatformDriver: "",
        remark: "",
      };
      this.selectLibraryShopList = [];
      this.tableData = [];
      this.goodsList = [];
      this.warehouseId = undefined;
    },
    //提交表单
    async submitFrom() {
      let xTable = this.selectLibraryShopList;
      if (xTable.length == 0) {
        this.$message.warning("请添加商品");
        return;
      }
      let find = xTable.find((item) => {
        return !item.inventoryVOList.length;
      });
      if (find) {
        this.$message.warning(
          "您添加的商品未关联订单，请先关联订单后再提交该操作。"
        );
        return;
      }
      let isCustomerSign = await this.isCustomerSignLogisticsContract();
      if (this.ruleForm.isNeedPlatformDriver == 1 && !this.isCustomerSign) {
        this.$message.warning("请签署物流合同");
        return;
      }

      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          xTable = xTable.map((item) => {
            let inventoryVOList = item.inventoryVOList.map((child) => {
              child.netWeight = child.totalNetWeight;
              return child;
            });
            item.inventoryVOList = inventoryVOList;
            return item;
          });
          this.ruleForm.goodsDetailList = xTable;
          this.boxSub();
        }
      });
    },
    boxSub() {
      this.ruleForm.goodsDetailList = this.ruleForm.goodsDetailList.map(
        (item) => {
          let inventoryVOList = item.inventoryVOList.map((child) => {
            child.quantity = child.totalQuantity;
            child.netWeight = child.totalNetWeight;
            child.weight = child.totalWeight;
            return child;
          });
          item.quantity = this.getTotal(item.inventoryVOList, "totalQuantity");
          item.netWeight = this.getTotal(
            item.inventoryVOList,
            "totalNetWeight"
          );
          item.weight = this.getTotal(item.inventoryVOList, "totalWeight");
          item.inventoryVOList = inventoryVOList;
          return item;
        }
      );

      if (this.ruleForm.isNeedPlatformDriver == "0") {
        if (this.$refs.driverTable.getTableData().fullData.length > 0) {
          let tables = this.$refs.driverTable.getTableData().fullData;
          this.ruleForm.driverList = tables;
        } else {
          this.$message({
            message: "请填写自备司机信息",
            type: "warning",
          });
          return;
        }
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.$route.query.orderId) {
        this.ruleForm.outboundOrderId = this.ruleForm.id;
        updateOutboundOrder(this.ruleForm)
          .then((response) => {
            if (response.data.code == 200) {
              this.$message({
                message: "提交成功！",
                type: "success",
              });
              this.$router.go(-1);
            } else {
              this.$message.error("预约失败！");
            }
          })
          .finally(() => {
            loading.close();
          });
      } else {
        postWarehousing(this.ruleForm)
          .then((response) => {
            if (response.data.code == 200) {
              this.$message({
                message: "提交成功！",
                type: "success",
              });
              this.$router.go(-1);
            } else {
              this.$message.error("预约失败！");
            }
          })
          .finally(() => {
            loading.close();
          });
      }
    },

    //司机表格 删除行
    async removeRowEvent(row) {
      const type = await VXETable.modal.confirm("您确定要删除该数据?");
      const $table = this.$refs.driverTable;
      if (type === "confirm") {
        $table.remove(row);
      }
    },
    //选择收货地址
    costPlannedAmountChange(value) {
      this.$set(
        this.ruleForm,
        "receiptAddress",
        `${value.district}${value.address}`
      );
      this.ruleForm.receiptAddressLongitude = value.location.lng;
      this.ruleForm.receiptAddressLatitude = value.location.lat;
    },
    async driverInsertEvent() {
      let xTable = this.selectLibraryShopList;
      if (xTable.length == 0) {
        this.$message.warning("请先添加商品");
        return;
      }
      let find = xTable.find((item) => {
        return !item.inventoryVOList.length;
      });
      if (find) {
        this.$message.warning(
          "您添加的商品未关联订单，请先关联订单后再提交该操作。"
        );
        return;
      }
      const $table = this.$refs.driverTable;
      const { row: newRow } = await $table.insert();
      // 插入一条数据并触发校验
      const errMap = await $table.validate(newRow).catch((errMap) => errMap);
      if (errMap) {
        // 校验不通过
      }
    },
    //获取商品分类集合
    goodsTypeListAll() {
      goodsTypeListAll().then((response) => {
        this.goodsTypeList = response.data.data;
        this.goodsList = [];
        this.manufacturerList = [];
        this.goodsModeList = [];
        this.goodsSpecList = [];
      });
    },
    warehouseSelect(e) {
      this.ruleForm.warehouseName = e.warehouseName;
      this.ruleForm.warehouseId = e.id;
      this.$refs.company.closeDialog();
    },
    //获取字典
    getunitType() {
      this.dicts.map((item) => {
        this.getDicts(item).then((res) => {
          // this.dictOption[item] = res.data.data;
          this.dict[item] = {};
          res.data.data.map((value) => {
            this.dict[item][value.dictValue] = value.dictLabel;
          });
        });
      });
    },
    clickDetail() {
      this.$refs.company.showDialog();
    },
    shopDetail() {
      this.getShopList(this.ruleForm.warehouseId);
      this.$refs.shop.showDialog();
    },
  },
  mounted() {
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.bannerHeight = (600 / 1550) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (600 / 1550) * this.screenWidth;
    };
  },
};
</script>
<style lang="less" scoped>
.box-textarea /deep/ textarea {
  width: 1000px;
  height: 270px;
}
.table_input {
  width: 100% !important;
}
.box-form {
  margin-top: 60px;
}

.box-form /deep/ .el-select {
  width: 590px;
}

.box-form /deep/ .el-input {
  width: 590px;
}

.table_input {
  ::v-deep .el-input__inner {
    padding: 0;
  }
}

.customer-sign {
  display: flex;
  justify-content: flex-end;
  width: 590px;
}
.box-addBtn {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  background: #40b8ad;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 40px;
  cursor: pointer;
}

.box-table {
  margin-top: 30px;
}

.box-allweight {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  margin-top: 40px;
  text-align: left;
}

.li-top {
  margin-top: 80px;
}

.agreeOn-success {
  width: 450px;
  height: 62px;
  line-height: 62px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 31px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 158px;
  margin-bottom: 140px;
  cursor: pointer;
}

.carouselImg {
  width: 100%;
  height: inherit;
}

.scrollImg {
  width: 100%;
  height: auto;
  /* max-height: 780px; */
  position: relative;
}

/deep/ .el-carousel--horizontal {
  max-height: 780px;
}

/deep/ .el-carousel__container {
  max-height: 780px;
}
</style>
