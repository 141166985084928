import Vue from 'vue'
import App from './App.vue'
import router from './router';
import ElementUI from 'element-ui';
import axios from 'axios';
import store from './store.js'

import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import taostPage from '@/components/taostPage.vue';
import titlePage from '@/components/titlePage.vue'
import dialogPage from '@/components/dialogPage.vue'
import FileUpload from "@/components/FileUpload/index.vue"
import ImageUpload from "@/components/ImageUpload/index.vue"
import dateMain from "./dateMain.js";
import { getDicts } from "@/api/dict/data";
import plugins from './plugins' // plugins
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree } from "@/utils/ruoyi";
// 分页组件
import PaginationPage from "@/components/PaginationPage/index.vue";
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
Vue.use(VXETable)

import mapPage from '@/components/mapPage.vue'
Vue.config.productionTip = false
Vue.component('mapPage', mapPage);
Vue.component('taostPage', taostPage);
Vue.component('titlePage', titlePage);
Vue.component('dialogPage', dialogPage);
Vue.component('FileUpload', FileUpload);
Vue.component('ImageUpload', ImageUpload);
Vue.component("PaginationPage", PaginationPage);

Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.handleTree = handleTree
Vue.prototype.getDicts = getDicts
Vue.prototype.dateMain = dateMain;
Vue.prototype.axios = axios;
Vue.prototype.$weight = function(data) {
	if (data.totalQuantity) {
		return Number(data.totalQuantity)
	}
	if (data.totalWeight) {
		return Number(data.totalWeight)
	}
	if (data.netWeight) {
		return Number(data.netWeight)
	}
}
Vue.prototype.$weightUnit = function(data) {
	if (data.totalQuantity) {
		return 'totalQuantity'
	}
	if (data.totalWeight) {
		return 'totalWeight'
	}
	if (data.netWeight) {
		return 'netWeight'
	}
}




// Vue.prototype.Vuexcs = Vuexcs;
Vue.use(ElementUI);
Vue.use(plugins)
let vueThis = new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
export default vueThis