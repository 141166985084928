<template>
  <div>
    <template>
      <div class="scrollImg">
        <img ref="image" class="carouselImg" src="../assets/ckimg.jpg" />
      </div>
      <div class="box-tabs">
        <div class="box-content">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              :label="item.typeName"
              :name="item.id.toString()"
              v-for="(item, index) in types"
              :key="index"
            ></el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="box-content">
        <div class="box-tab-card">
          <div
            class="box-card"
            v-for="(item, index) in warehouseList"
            :key="index"
            @click="selectDetails(item.id)"
          >
            <img
              class="card-img"
              :src="getImgOne(item.listPic)"
              alt=""
              srcset=""
            />
            <div class="box-card-content">
              <div class="card-title">
                {{ item.warehouseName }}
              </div>
              <div class="card-address">
                {{ item.city }} 距离您{{ item.distance }}km 总容量{{
                  item.capacityCount
                }}{{ item.capacityCountUnit }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-pagination">
        <PaginationPage
          v-show="total > 0"
          :limit.sync="queryParams.pageSize"
          :page.sync="queryParams.pageNum"
          :total="total"
          @pagination="getList"
        />
      </div>
    </template>
  </div>
</template>
<script>
import { getRecommendList, getPageTypeList } from "../api/warehouse.js";
export default {
  name: "CessNav",
  components: {},
  data() {
    return {
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 9,
        longitude: "115.55761",
        latitude: "38.878718",
        warehouseType: "",
      },
      total: 0,
      types: [],
      warehouseList: [],
      activeName: "",
      bannerHeight: "",
      tabData: [
        {
          name: "预约入库",
          url: require("../assets/warehousing.png"),
        },
        {
          name: "预约出库",
          url: require("../assets/outbound.png"),
        },
        {
          name: "发布回购",
          url: require("../assets/buyback.png"),
        },
        {
          name: "转让货权",
          url: require("../assets/rollout.png"),
        },
      ],
    };
  },
  methods: {
    // 获取仓库列表信息
    getList() {
      getRecommendList(this.queryParams)
        .then((response) => {
          if (response.data.code == 200) {
            this.warehouseList = response.data.rows;
            this.total = response.data.total;
          }
        })
        .catch(() => {
          this.$message.error("获取失败！");
        });
    },
    //获取仓库分类列表信息、
    getTypeList() {
      getPageTypeList(this.queryParams)
        .then((response) => {
          if (response.data.code == 200) {
            this.types = response.data.rows;
            this.activeName = response.data.rows[0].id.toString();
            this.queryParams.warehouseType =
              response.data.rows[0].id.toString();
            this.getList();
          }
        })
        .catch(() => {
          this.$message.error("获取失败！");
        });
    },
    // 查看仓库信息
    selectDetails(id) {
      this.$router.push({
        path: "/storehouseDetails",
        query: {
          id: id,
        },
      });
    },
    //返回图片第一张
    getImgOne(e) {
      if (!e) {
        return "";
      }
      let n = e.split(",")[0];
      return n;
    },
    handleClick() {
      this.queryParams.warehouseType = this.activeName;
      this.getList();
    },
  },
  mounted() {
    this.getTypeList();
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.bannerHeight = (600 / 1550) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (600 / 1550) * this.screenWidth;
    };
  },
};
</script>
<style scoped>
/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-tabs__header {
  margin: 0;
}

/deep/ .el-tabs__content {
  display: none;
}

/deep/ .el-tabs__item {
  height: 90px;
  line-height: 90px;
}

.box-tabs {
  height: 90px;
  background: #f6f6f6;
}

.box-select-submit {
  display: block;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 100px;
  width: 160px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 24px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

.box-select-submit:hover {
  opacity: 0.8;
}

.card-address {
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  text-align: left;
  margin-top: 10px;
}

.card-title {
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  text-align: left;
}

.card-img {
  width: 100%;
  height: 246px;
}

.box-card {
  width: 380px;
  height: 340px;
  background: #ffffff;
  border: 1px solid #dedede;
  margin: var(--gutter) 0px 0px var(--gutter);
  /* 	margin-bottom: 30px;
		margin-right: 30px; */
  box-sizing: border-box;
  transition: all 0.3s;
  /* 上浮这个过程需要的时间 */
  cursor: pointer;
}

.box-card:hover {
  box-shadow: 0px 0px 10px 0px rgba(65, 181, 172, 0.35);
  transform: translate(0, -3px);
}

.box-card-content {
  padding: 20px;
  height: 54px;
}

.box-tab-card {
  display: flex;
  display: -webkit-flex;
  /* justify-content: space-between; */
  flex-direction: row;
  flex-wrap: wrap;
  /* gap: 30px; */
  --gutter: 30px;
  margin: calc(0px - var(--gutter)) 0px 0px calc(0px - var(--gutter));
  margin-top: 50px;
  min-height: 340px;
}

.box-card-title {
  text-align: center;
  height: 42px;
  line-height: 42px;
  font-size: 42px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231714;
  margin-top: 167px;
}

.borderShare {
  border-right: 1px dashed #eeeeee;
}

.box-tab {
  text-align: center;
  width: 25%;
  box-sizing: border-box;
  cursor: pointer;
}

.box-tab span {
  display: block;
  text-align: center;
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  margin-top: 10px;
}

.box-tab img {
  display: block;
  width: 92px;
  margin: 0 auto;
  margin-top: 13px;
}

.box-fixed-tab {
  position: relative;
  margin: 0 auto;
  margin-top: -88px;
  width: 1200px;
  height: 150px;
  padding: 13px 0;
  background: #ffffff;
  box-shadow: 0px -6px 24px 0px rgba(65, 181, 172, 0.35);
  z-index: 99;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.carouselImg {
  width: 100%;
  height: inherit;
}

.scrollImg {
  width: 100%;
  height: auto;
  /* max-height: 780px; */
  position: relative;
}

/deep/ .el-carousel--horizontal {
  max-height: 780px;
}

/deep/ .el-carousel__container {
  max-height: 780px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.demo {
  display: inline-block;
  width: 600px;
  height: 338px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
</style>
