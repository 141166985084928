<template>
  <div>
    <div id="container"></div>
    <!-- <div class="input-card">
      <h4>轨迹回放控制</h4>
      <div class="input-item">
        <input
          type="button"
          class="btn"
          value="开始动画"
          id="start"
          @click="startAnimation()"
        />
        <input
          type="button"
          class="btn"
          value="暂停动画"
          id="pause"
          @click="pauseAnimation()"
        />
      </div>
      <div class="input-item">
        <input
          type="button"
          class="btn"
          value="继续动画"
          id="resume"
          @click="resumeAnimation()"
        />
        <input
          type="button"
          class="btn"
          value="停止动画"
          id="stop"
          @click="stopAnimation()"
        />
      </div>
    </div> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    lineArr: Array,
  },
  data() {
    return {
      map: null,
      marker: null,
    };
  },
  watch: {
    getAmap() {
      this.initMap();
    },
  },
  computed: {
    ...mapGetters(["getAmap"]),
  },
  methods: {
    initMap() {
      let AMap = this.getAmap;
      this.map = new AMap.Map("container", {
        resizeEnable: true,
        center: this.lineArr[0],
        zoom: 500,
      });
      this.marker = new AMap.Marker({
        map: this.map,
        position: this.lineArr[0],
        icon: "https://webapi.amap.com/images/car.png",
        offset: new AMap.Pixel(-26, -15),
        // autoRotation: true,
        angle: -90,
      });

      // 绘制轨迹
      new AMap.Polyline({
        map: this.map,
        path: this.lineArr,
        showDir: true,
        strokeColor: "#28F", //线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 6, //线宽
        // strokeStyle: "solid"  //线样式
      });

      let passedPolyline = new AMap.Polyline({
        map: this.map,
        // path: this.lineArr,
        strokeColor: "#AF5", //线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 6, //线宽
        // strokeStyle: "solid"  //线样式
      });

      this.marker.on("moving", function (e) {
        passedPolyline.setPath(e.passedPath);
      });

      this.map.setFitView();
    },
    startAnimation() {
      this.marker.moveAlong(this.lineArr, {
        duration: 500,
        autoRotation: true,
        angle: -90,
      });
    },
    pauseAnimation() {
      this.marker.pauseMove();
    },
    resumeAnimation() {
      this.marker.resumeMove();
    },
    stopAnimation() {
      this.marker.stopMove();
    },
  },
  beforeDestroy() {
    this.map && this.map.destroy();
  },
  mounted() {
    if (this.getAmap) {
      this.initMap();
    }
  },
};
</script>
<style>
#container {
  width: 100%;
  height: 600px;
}

.input-card .btn {
  margin-right: 1.2rem;
  width: 9rem;
}

.input-card .btn:last-child {
  margin-right: 0;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: transparent;
  background-image: none;
  color: #25a5f7;
  border-color: #25a5f7;
  padding: 0.25rem 0.5rem;
  line-height: 1.5;
  border-radius: 1rem;
  -webkit-appearance: button;
  cursor: pointer;
}

.input-item {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 3rem;
}

.input-card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
  width: 22rem;
  border-width: 0;
  border-radius: 0.4rem;
  box-shadow: 0 2px 6px 0 rgba(114, 124, 245, 0.5);
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.75rem 1.25rem;
}

.amap-icon img {
  top: 0;
  left: 0;
}
</style>
