<template>
  <div>
    <template>
      <div class="scrollImg">
        <el-carousel
          :interval="5000"
          arrow="always"
          :height="bannerHeight + 'px'"
        >
          <el-carousel-item v-for="item in 4" :key="item">
            <img
              ref="image"
              class="carouselImg"
              src="../assets/ckimg.jpg"
              alt=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="box-tabs">
        <div class="box-content">
          <span class="box-tabs-name">我的合同</span>
        </div>
      </div>
      <div class="box-content">
        <div class="box-personal">
          <div class="box-details-number">
            <div class="box-number">单号：RKCS01202212150001</div>
            <div class="box-details-content">
              <div class="box-content-left">
                <div class="box-tecxt">仓库：顺义区空港物流基地多层仓库</div>
                <div class="box-tecxt">预计入库时间：2022-12-15 12:00</div>
                <div class="box-tecxt">商务负责人：孙悟空</div>
                <div class="box-tecxt">备注：这里是客户下单备注</div>
              </div>
              <div class="box-content-right">
                <div class="box-tecxt">下单时间：2022-12-15 12:00</div>
                <div class="box-tecxt">最晚入库时间：2022-12-15 12:00</div>
                <div class="box-tecxt">订单完成时间：2022-12-15 12:00</div>
              </div>
            </div>
          </div>
          <div class="box-types">已签署</div>
        </div>
      </div>
      <div class="box-content">
        <titlePage :titleText="contracttext" class=""></titlePage>
        <div class="box-card-title">仓租协议</div>

        <div class="box-contract">
          甲方：XXXXXXXXXXXXXX公司 乙方：XXXXXXXXXXXX公司
          甲乙双方本着诚实信用的原则，平等互利，经公平协商签订以下租赁协议。甲方：XXXXXXXXXXXXXX公司
          乙方：XXXXXXXXXXXX公司
          甲乙双方本着诚实信用的原则，平等互利，经公平协商签订以下租赁协议。甲方：XXXXXXXXXXXXXX公司
          乙方：XXXXXXXXXXXX公司
          甲乙双方本着诚实信用的原则，平等互利，经公平协商签订以下租赁协议。甲方：XXXXXXXXXXXXXX公司
          乙方：XXXXXXXXXXXX公司
          甲乙双方本着诚实信用的原则，平等互利，经公平协商签订以下租赁协议。甲方：XXXXXXXXXXXXXX公司
          乙方：XXXXXXXXXXXX公司
          甲乙双方本着诚实信用的原则，平等互利，经公平协商签订以下租赁协议。
          甲方：XXXXXXXXXXXXXX公司 乙方：XXXXXXXXXXXX公司
          甲乙双方本着诚实信用的原则，平等互利，经公平协商签订以下租赁协议。
        </div>
        <titlePage :titleText="contracttext" class="li-top"></titlePage>
        <div class="box-details-session" style="margin-bottom: 40px">
          <div class="details-address box-new-session">
            <span>转让方签订时间：2022-11-11 12:00:00</span>
            <span>接收方签订时间：2022-11-11 12:00:00</span>
          </div>
          <div class="details-address box-new-session">
            <span>转让方签订时间：2022-11-11 12:00:00</span>
            <span>接收方签订时间：2022-11-11 12:00:00</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "CessNav",
  components: {},
  data() {
    return {
      contracttext: "合同详情",
      activeName: null,
      bannerHeight: "",
      tableDataSignature: [
        {
          date: "阿皮",
          name: "王小虎",
          address: "阿肥",
        },
      ],
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      tabsList: [
        {
          label: "个人资料",
          name: "1",
        },
        {
          label: "企业认证",
          name: "2",
        },
        {
          label: "我的订单",
          name: "3",
        },
        {
          label: "我的合同",
          name: "4",
        },
        {
          label: "我的货物",
          name: "5",
        },
        {
          label: "我的账单",
          name: "6",
        },
        {
          label: "我的待办",
          name: "7",
        },
      ],
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    };
  },
  methods: {
    selectContract() {
      this.$refs.contract.showDialog();
    },
    selectVideo() {
      this.$refs.video.showDialog();
    },
    selectPicture() {
      this.$refs.picture.showDialog();
    },
    selectTrajectory() {
      this.$refs.trajectory.showDialog();
    },
    selectSignature() {
      this.$refs.signature.showDialog();
    },
  },
  mounted() {
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.bannerHeight = (600 / 1550) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (600 / 1550) * this.screenWidth;
    };
  },
};
</script>
<style scoped>
.box-new-session {
  width: 750px;
  margin-bottom: 20px;
}

.box-btns-top {
  margin-top: 40px;
}

.box-img-list {
  width: 598px;
  height: 280px;
  background: #ffffff;
  /* border: 1px solid #BCBCBC; */
  margin: 0 auto;
}

.box-picture-list {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.box-picture {
  width: 250px;
  height: 250px;
  margin-bottom: 30px;
}

.box-picture img {
  width: 100%;
  height: 100%;
}

.box-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-btns-warning {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background: #ff9000;
  box-sizing: border-box;
  margin-left: 30px;
}

.box-btns-emg {
  width: 150px;
  height: 52px;
  line-height: 50px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #40b8ad;
  background: #ffffff;
  border: 1px solid #40b8ad;
  box-sizing: border-box;
}

.box-btns-primary {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background: #40b8ad;
  margin-left: 30px;
}

.box-tips-text {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #40b8ad;
  text-align: left;
  margin-bottom: 30px;
}

.box-types {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #40b8ad;
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -12px;
}

.box-details-number {
  width: 670px;
}

.box-tecxt {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-bottom: 10px;
  text-align: left;
}

.box-details-content {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.box-number {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  text-align: left;
}

.box-voucher {
  flex: 1;
  margin-left: 200px;
}

.box-voucher span {
  float: left;
}

.box-voucher img {
  float: left;
  width: 146px;
  height: 146px;
}

.box-details-text {
  margin-top: 50px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 48px;
}

.box-top {
  margin-top: 20px;
}

.box-details-session {
  /* padding: 40px 45px; */
  /* background: #F0F0F0; */
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
}

.details-address {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

/* 
	.details-address:after {
		content: ' ';
		flex: auto;
	} */

/* 
	.details-address span {
		flex: 1;
	} */
.details-address span {
  line-height: 18px;
}

.li-top {
  margin: 60px 0 40px 0;
}

.box-card-title {
  text-align: center;
  height: 42px;
  line-height: 42px;
  font-size: 42px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231714;
  margin-top: 60px;
  margin-bottom: 45px;
}

.box-addBtn {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  background: #40b8ad;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
  cursor: pointer;
}

.box-table {
  margin-top: 30px;
}

.edit-content {
  position: absolute;
  right: 30px;
  display: flex;
  top: 30px;
}

.re-certification {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #40b8ad;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-left: 20px;
  cursor: pointer;
}

.personal-edit {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #ffffff;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #40b8ad;
  cursor: pointer;
}

.personal-phone {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 16px;
  text-align: left;
}

.personal-name {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  margin-top: 16px;
  text-align: left;
}

.personal-corporation {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 72px;
  text-align: left;
}

.personal-corporation span {
  margin-left: 20px;
  padding: 4px 6px;
  background: #ffffff;
  border: 1px solid #ff9000;
  border-radius: 5px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ff9000;
}

.personal-text {
  margin-left: 30px;
}

.box-personal-session {
  float: left;
  margin-left: 50px;
  height: 100%;
  display: flex;
}

.block {
  margin-top: 52px;
}

.box-personal {
  height: auto;
  background: #f6f6f6;
  margin: 60px 0;
  padding: 30px;
  position: relative;
}

.box-tabs-name {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  height: 90px;
  line-height: 90px;
  float: left;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-tabs__header {
  margin: 0;
}

/deep/ .el-tabs__content {
  display: none;
}

/deep/ .el-tabs__item {
  height: 90px;
  line-height: 90px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

/deep/ .el-tabs {
  float: right;
}

.box-tabs {
  height: 90px;
  background: #f6f6f6;
}

.box-select-submit {
  display: block;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 100px;
  width: 160px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 24px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

.box-select-submit:hover {
  opacity: 0.8;
}

.card-address {
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  text-align: left;
  margin-top: 10px;
}

.card-title {
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  text-align: left;
}

.card-img {
  width: 100%;
  height: 246px;
}

.box-card {
  width: 380px;
  height: 340px;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-bottom: 30px;
  transition: all 0.3s;
  /* 上浮这个过程需要的时间 */
  cursor: pointer;
}

.box-card:hover {
  box-shadow: 0px 0px 10px 0px rgba(65, 181, 172, 0.35);
  transform: translate(0, -3px);
}

.box-card-content {
  padding: 20px;
  height: 54px;
}

.box-tab-card {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
}

.borderShare {
  border-right: 1px dashed #eeeeee;
}

.box-tab {
  text-align: center;
  width: 25%;
  box-sizing: border-box;
  cursor: pointer;
}

.box-tab span {
  display: block;
  text-align: center;
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  margin-top: 10px;
}

.box-tab img {
  display: block;
  width: 92px;
  margin: 0 auto;
  margin-top: 13px;
}

.box-fixed-tab {
  position: relative;
  margin: 0 auto;
  margin-top: -88px;
  width: 1200px;
  height: 150px;
  padding: 13px 0;
  background: #ffffff;
  box-shadow: 0px -6px 24px 0px rgba(65, 181, 172, 0.35);
  z-index: 99;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.carouselImg {
  width: 100%;
  height: inherit;
}

.scrollImg {
  width: 100%;
  height: auto;
  /* max-height: 780px; */
  position: relative;
}

/deep/ .el-carousel--horizontal {
  max-height: 780px;
}

/deep/ .el-carousel__container {
  max-height: 780px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.demo {
  display: inline-block;
  width: 600px;
  height: 338px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
</style>
