import { render, staticRenderFns } from "./taostStorehouse.vue?vue&type=template&id=55c489fb&scoped=true&"
import script from "./taostStorehouse.vue?vue&type=script&lang=js&"
export * from "./taostStorehouse.vue?vue&type=script&lang=js&"
import style0 from "./taostStorehouse.vue?vue&type=style&index=0&id=55c489fb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55c489fb",
  null
  
)

export default component.exports