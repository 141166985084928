/****   request.js   ****/
import Vue from '@/main'
// 导入axios
import axios from 'axios'
// 使用element-ui Message做消息提醒
import {
	Notification,
	Message,
	MessageBox
} from 'element-ui';
// 是否显示重新登录
export let isRelogin = {
	show: false
};
// import Cookies from 'js-cookie';
//1. 创建新的axios实例，
const service = axios.create({
	// 公共接口--这里注意后面会讲
	baseURL: process.env.VUE_APP_BASE_API,
	// 超时时间 单位是ms，这里设置了3s的超时时间
	timeout: 60 * 1000,
	headers: {
		'Content-Type': 'application/json'
	}
})
// 2.请求拦截器
service.interceptors.request.use(config => {
	//发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
	// config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
	// config.headers = {
	// 	'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' //配置请求头
	// }
	//注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
	const token = localStorage.getItem('Token'); //这里取token之前，你肯定需要先拿到token,存一下
	if (token) {
		config.headers = {
			'Authorization': 'Bearer ' + token
		} //如果要求携带在参数中
		// config.headers.token= token; //如果要求携带在请求头中
	}
	return config
}, error => {
	Promise.reject(error)
})

// 3.响应拦截器
service.interceptors.response.use(response => {
	//接收到响应数据并成功后的一些共有的处理，关闭loading等
	let code = response.data.code || 200;
	let msg = response.data.msg;
	if (
		response.request.responseType === "blob" ||
		response.request.responseType === "arraybuffer"
	) {
		return response;
	}
	if (code === 401) {
		localStorage.setItem('Token', null)
		Vue.$router.push('/loginPage')
		// if (!isRelogin.show) {
		// 	isRelogin.show = true;
		// 	MessageBox.confirm(
		// 		"登录状态已过期，您可以继续留在该页面，或者重新登录",
		// 		"系统提示", {
		// 		confirmButtonText: "重新登录",
		// 		cancelButtonText: "取消",
		// 		type: "warning",
		// 	}
		// 	)
		// 		.then(() => {
		// 			isRelogin.show = false;
		// 			localStorage.setItem('Token', null)
		// 			Vue.$router.push('/loginPage')
		// 		})
		// 		.catch(() => { });
		// }
		// return Promise.reject("无效的会话，或者会话已过期，请重新登录。");
	} else if (code === 500) {
		Message({
			message: msg,
			type: "error",
		});
		return Promise.reject(new Error(msg));
	} else {
		return response;
	}
	// switch (code) {
	// 	case 400:
	// 		Message.error(msg)
	// 		break;
	// 	case 401:
	// 		Message.error(msg)
	// 		localStorage.setItem('Token',null)
	// 		console.log(Vue)
	// 		// Vue.$router.push('/loginPage')
	// 		// this.$router
	// 		break;
	// 	case 403:
	// 		Message.error(msg)
	// 		break;
	// 	case 404:
	// 		Message.error(msg)
	// 		// window.location.href = "/NotFound"
	// 		break;
	// 	case 405:
	// 		Message.error(msg)
	// 		break;
	// 	case 408:
	// 		Message.error(msg)
	// 		break;
	// 	case 500:
	// 		Message.error(msg)
	// 		break;
	// 	case 501:
	// 		Message.error(msg)
	// 		break;
	// 	case 502:
	// 		Message.error(msg)
	// 		break;
	// 	case 503:
	// 		Message.error(msg)
	// 		break;
	// 	case 504:
	// 		Message.error(msg)
	// 		break;
	// 	case 505:
	// 		Message.error(msg)
	// 		break;
	// 	default:

	// }
	// return response
}, error => {
	/***** 接收到异常响应的处理开始 *****/
	if (error && error.response) {
		// 1.公共错误处理
		// 2.根据响应码具体处理
		switch (error.response.status) {
			case 400:
				Message.error('错误请求')
				break;
			case 401:
				Message.error('未授权，请重新登录')
				break;
			case 403:
				Message.error('拒绝访问')
				break;
			case 404:
				Message.error('请求错误,未找到该资源')
				// window.location.href = "/NotFound"
				break;
			case 405:
				Message.error('请求方法未允许')
				break;
			case 408:
				Message.error('请求超时')
				break;
			case 500:
				Message.error('服务器端出错')
				break;
			case 501:
				Message.error('网络未实现')
				break;
			case 502:
				Message.error('网络错误')
				break;
			case 503:
				Message.error('服务不可用')
				break;
			case 504:
				Message.error('网络超时')
				break;
			case 505:
				Message.error('http版本不支持该请求')
				break;
			default:
				Message.error(`连接错误`)
		}
	} else {
		// 超时处理
		if (JSON.stringify(error).includes('timeout')) {
			Message.error('服务器响应超时，请刷新当前页')
		}
		Message.error = '连接服务器失败'
	}

	Message.error(Message.error)
	/***** 处理结束 *****/
	//如果不需要错误处理，以上的处理过程都可省略
	return Promise.resolve(error.response)
})
//4.导入文件
export default service