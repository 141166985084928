<template>
  <div class="box-tabs-list">
    <div
      class="box-tabs-pane"
      :class="tabsIndex == item.name ? 'active' : ''"
      v-for="(item, index) in tabsList"
      @click="handleClick(index)"
      :key="index"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<script>
import { customerAuth } from "@/api/business/customer";
export default {
  name: "CessNav",
  components: {},
  props: {
    tabsIndex: {
      type: Number,
      defualt: undefined,
    },
  },
  data() {
    return {
      sessiontext: "我的货物",
      activeName: null,
      bannerHeight: "",
      tabsList: [
        {
          label: "个人资料",
          name: "1",
        },
        {
          label: "企业认证",
          name: "2",
        },
        {
          label: "企业信息 ",
          name: "8",
        },
        {
          label: "我的订单",
          name: "3",
        },
        {
          label: "我的合同",
          name: "4",
        },
        {
          label: "我的货物",
          name: "5",
        },
        {
          label: "我的账单",
          name: "6",
        },
        {
          label: "我的待办",
          name: "7",
        },
      ],
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    };
  },
  methods: {
    handleClick(index) {
      this.activeName = index;
      if (index == 0) {
        this.$router.push("/personalCenter");
        // this.$router.push("/modifyPage");
      } else if (index == 1) {
        // this.$router.push("/authenticationPage");
        customerAuth().then((res) => {
          window.open(res.data.data);
        });
      } else if (index == 2) {
        this.$router.push("/Information");
      } else if (index == 3) {
        this.$router.push("/orderPage");
      } else if (index == 4) {
        this.$router.push("/contractPage");
      } else if (index == 5) {
        this.$router.push("/goodsPage");
      } else if (index == 6) {
        this.$router.push("/billPage");
      } else if (index == 7) {
        this.$router.push("/waitingPage");
      }
    },
  },
  mounted() {
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.bannerHeight = (600 / 1550) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (600 / 1550) * this.screenWidth;
    };
  },
};
</script>

<style scoped>
.box-tabs-list {
  float: right;
}

.box-tabs-list .box-tabs-pane {
  display: inline-block;
  height: 90px;
  line-height: 90px;
  padding: 0 20px;
  cursor: pointer;
  box-sizing: border-box;
}
.box-tabs-list .box-tabs-pane:hover {
  color: #40b8ad;
}
.box-tabs-list .box-tabs-pane:last-child {
  padding-right: 0px;
}
.box-tabs-pane.active {
  color: #40b8ad;
  border-bottom: 2px solid #40b8ad;
}

.el-tabs__item.clearColor {
  color: #333333 !important;
  border: none !important;
}
</style>
