import request from '@/utils/request'

export function releaseTransferOrder(data) {
  return request({
    url: '/business/transferOrder/releaseTransferOrder',
    method: 'POST',
    data
  })
}
export function relatedReceiver(params) {
  return request({
    url: '/business/transferOrder/relatedReceiver',
    method: 'GET',
    params
  })
}
export function getCustomerTransferOrderList(params) {
  return request({
    url: '/business/transferOrder/getCustomerTransferOrderList',
    method: 'GET',
    params
  })
}
export function insertReceiver(data) {
  return request({
    url: '/business/transferOrder/insertReceiver',
    method: 'POST',
    data
  })
}

export function getTransferOrderDetail(id) {
  return request({
    url: '/business/transferOrder/getTransferOrderDetail/' + id,
    method: 'POST',
  })
}
export function signTransferOrderContract(transferOrderId) {
  return request({
    url: '/business/transferOrder/signTransferOrderContract/' + transferOrderId,
    method: 'POST',
  })
}
export function getTransferConfirmationFormContract(params) {
  return request({
    url: '/business/transferOrder/getTransferConfirmationFormContract',
    method: 'GET',
    params
  })
}
export function cancelTransferOrder(data) {
  return request({
    url: '/business/transferOrder/cancelTransferOrder',
    method: 'POST',
    data
  })
}
