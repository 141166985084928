<template>
  <div>
    <template>
      <div class="scrollImg">
        <el-image
          ref="image"
          class="carouselImg"
          :src="require('../assets/ckimg.jpg')"
        />
      </div>
      <div class="box-content" style="padding-bottom: 136px">
        <!-- 基本信息 -->
        <titlePage :titleText="collectiontext" class="li-top"></titlePage>
        <el-form label-width="150px">
          <el-row>
            <el-col :span="15">
              <el-form-item label="选择仓库">
                <div @click="clickDetail">
                  <el-input
                    v-model="ruleForm.warehouseName"
                    readonly="readonly"
                  >
                    <el-button slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div
          class="box-details-session"
          v-for="(item, index) of ruleForm.goodsDetailRecordList"
          :key="index"
        >
          <div style="width: 95%">
            <el-row :gutter="20">
              <el-col :span="6">
                <span>商品品类：{{ item.typeName }}</span>
              </el-col>
              <el-col :span="6">
                <span>商品名称：{{ item.goodsName }}</span>
              </el-col>
              <el-col :span="6">
                <span> 厂家：{{ item.goodsManufacturer }}</span>
              </el-col>
              <el-col :span="6">
                <span>型号：{{ item.goodsModel }}</span>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <span v-if="item.quantity"
                  >规格：{{ dict.sys_user_count_unit[item.quantityUnit] }}</span
                >
                <span v-if="item.weight"
                  >规格：{{ dict.sys_user_weight_unit[item.weightUnit] }}</span
                >
                <span v-if="item.netWeight"
                  >规格：{{
                    dict.sys_user_weight_unit[item.netWeightUnit]
                  }}</span
                >
              </el-col>
              <el-col :span="6">
                <span
                  >剩余库存：{{
                    item.quantity
                      ? item.quantity
                      : item.weight
                      ? item.weight
                      : item.netWeight
                  }}
                </span>
              </el-col>
              <el-col :span="6" v-if="item.quantityUnit">
                <span
                  >转让数量：{{
                    getTotal(item.inventoryVOList, "totalQuantity")
                  }}
                </span>
              </el-col>
              <el-col :span="6" v-if="item.weightUnit">
                <span
                  >转让重量：{{ getTotal(item.inventoryVOList, "totalWeight") }}
                </span>
              </el-col>
              <el-col :span="6" v-if="item.netWeightUnit">
                <span
                  >转让净重：{{
                    getTotal(item.inventoryVOList, "totalNetWeight")
                  }}
                </span>
              </el-col>
            </el-row>
            <div class="box-form" style="margin-top: 40px">
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="150px"
                class="demo-ruleForm"
              >
                <el-row>
                  <el-col :span="24">
                    <el-col :span="8">
                      <el-form-item label="选择关联订单">
                        <div @click="handleAssociation(item, index)">
                          <el-input
                            :value="
                              item.inventoryVOList
                                ? item.inventoryVOList
                                    .map((item) => item.orderNum)
                                    .join(',')
                                : ''
                            "
                            readonly="readonly"
                          >
                            <el-button
                              slot="append"
                              icon="el-icon-search"
                            ></el-button>
                          </el-input>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-col>
                  <!-- <el-col :span="24">
				  <el-col :span="8"> </el-col>
				</el-col> -->
                </el-row>
              </el-form>
            </div>
          </div>
          <div class="goods_detele">
            <el-button type="danger" @click="deteleGoods(item)">删除</el-button>
          </div>
        </div>

        <div
          class="box-addBtn"
          @click="shopDetail()"
          v-show="ruleForm.warehouseId"
        >
          添加商品
        </div>
        <!-- 关联新货权方 -->
        <titlePage :titleText="transferencePay" class="li-top"></titlePage>
        <div class="box-form" style="margin-top: 40px">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="150px"
            class="demo-ruleForm"
          >
            <el-row>
              <!-- <el-col :span="24">
                <el-col :span="8">
                  <el-form-item label="转让数量：">
                    <el-input :value="totalQuantity" readonly></el-input>
                  </el-form-item>
                </el-col>
              </el-col>
              <el-col :span="24">
                <el-col :span="8">
                  <el-form-item label="转让重量：">
                    <el-input :value="totalWeight" readonly></el-input>
                  </el-form-item>
                </el-col>
              </el-col>
              <el-col :span="24">
                <el-col :span="8">
                  <el-form-item label="转让净重量：">
                    <el-input :value="totalNetWeight" readonly></el-input>
                  </el-form-item>
                </el-col>
              </el-col> -->
              <el-col :span="24">
                <el-col :span="8">
                  <el-form-item label="单价：" prop="unitPrice">
                    <el-input
                      v-model="ruleForm.unitPrice"
                      placeholder="请输入单价"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <titlePage :titleText="returnPay" class="li-top"></titlePage>
        <div>
          <div class="return-pay_button">
            <el-button type="primary" @click="$refs.user.showDialog()">{{
              tabbarFormData.userName ? "修改新货权方" : "关联新货权方"
            }}</el-button>
          </div>
          <el-row v-if="tabbarFormData.orgName">
            <el-col :span="24">
              <div class="ownership">
                <div>新货权方公司名称：{{ tabbarFormData.orgName }}</div>
                <div>联系人：{{ tabbarFormData.contactName }}</div>
                <div>联系电话：{{ tabbarFormData.phone }}</div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="box-submit box-all-btn" @click="byBackList">提交</div>
      </div>
    </template>
    <taostStorehouse
      ref="company"
      @warehouseSelect="warehouseSelect"
    ></taostStorehouse>

    <dialogPage taostTips="选择在库商品" width="1000px" ref="shop">
      <template v-slot:content>
        <div class="">
          <el-table
            :data="libraryShopList"
            style="width: 100%"
            height="700"
            :header-cell-style="{
              background: '#F2F2F2',
              'text-align': 'center',
              color: '#333333',
            }"
            border
            width="80"
          >
            <el-table-column label="商品分类" align="center" prop="typeName">
            </el-table-column>
            <el-table-column label="商品名称" align="center" prop="goodsName">
            </el-table-column>
            <el-table-column
              label="商品厂家"
              align="center"
              prop="goodsManufacturer"
            >
            </el-table-column>
            <el-table-column label="型号" align="center" prop="goodsModel">
            </el-table-column>
            <el-table-column label="规格" align="center" prop="goodsSpec">
            </el-table-column>
            <el-table-column label="总数量" align="center" prop="quantity">
              <template slot-scope="{ row }">
                {{ row.quantityUnit ? row.quantity : "--" }}
              </template>
            </el-table-column>
            <el-table-column
              label="总数量单位"
              align="center"
              prop="quantityUnit"
            >
              <template slot-scope="{ row }">
                {{
                  row.quantityUnit
                    ? dict.sys_user_count_unit[row.quantityUnit]
                    : "--"
                }}
              </template>
            </el-table-column>
            <el-table-column label="总重量" align="center" prop="weight">
              <template slot-scope="{ row }">
                {{ row.weightUnit ? row.weight : "--" }}
              </template>
            </el-table-column>
            <el-table-column
              label="总重量单位"
              align="center"
              prop="weightUnit"
            >
              <template slot-scope="{ row }">
                {{
                  row.weightUnit
                    ? dict.sys_user_weight_unit[row.weightUnit]
                    : "--"
                }}
              </template>
            </el-table-column>
            <el-table-column label="净重" align="center" prop="netWeight">
              <template slot-scope="{ row }">
                {{ row.netWeightUnit ? row.netWeight : "--" }}
              </template>
            </el-table-column>
            <el-table-column
              label="净重单位"
              align="center"
              prop="netWeightUnit"
            >
              <template slot-scope="{ row }">
                {{
                  row.netWeightUnit
                    ? dict.sys_user_weight_unit[row.netWeightUnit]
                    : "--"
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="入库要求"
              align="center"
              prop="warehousingRequirements"
            >
              <template slot-scope="scope">
                <span>{{
                  dict.warehousing_requirements[
                    scope.row.warehousingRequirements
                  ]
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="是否带托入库"
              align="center"
              prop="isWarehousingWithTray"
            >
              <template slot-scope="scope">
                <span>{{
                  dict.sys_yes_no[scope.row.isWarehousingWithTray]
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row)"
                  type="text"
                  size="small"
                  >选择</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </dialogPage>
    <dialogPage taostTips="选择关联订单" width="870px" ref="orders">
      <template v-slot:content>
        <div class="">
          <el-table
            :data="selectOrderList"
            style="width: 100%"
            :header-cell-style="{
              background: '#F2F2F2',
              'text-align': 'center',
              color: '#333333',
            }"
            border
            width="80"
          >
            <el-table-column label="商品分类" align="center" prop="typeName">
            </el-table-column>
            <el-table-column label="商品名称" align="center" prop="goodsName">
            </el-table-column>
            <el-table-column
              label="商品厂家"
              align="center"
              prop="goodsManufacturer"
            >
            </el-table-column>
            <el-table-column label="型号" align="center" prop="goodsModel">
            </el-table-column>
            <el-table-column label="规格" align="center" prop="goodsSpec">
            </el-table-column>
            <el-table-column label="总数量" align="center" prop="quantity">
            </el-table-column>
            <el-table-column
              label="转让数量"
              align="center"
              prop="totalQuantity"
            >
              <template slot-scope="{ row }">
                <el-input
                  :disabled="!row.quantityUnit"
                  v-model="row.totalQuantity"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="总数量单位"
              align="center"
              prop="quantityUnit"
            >
              <template slot-scope="scope">
                {{ dict.sys_user_count_unit[scope.row.quantityUnit] }}
              </template>
            </el-table-column>
            <el-table-column label="总重量" align="center" prop="weight">
            </el-table-column>
            <el-table-column label="转让重量" align="center">
              <template slot-scope="{ row }">
                <el-input
                  :disabled="!row.weightUnit"
                  v-model="row.totalWeight"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="总重量单位"
              align="center"
              prop="weightUnit"
            >
              <template slot-scope="scope">
                {{ dict.sys_user_weight_unit[scope.row.weightUnit] }}
              </template>
            </el-table-column>
            <el-table-column label="净重" align="center" prop="netWeight">
            </el-table-column>
            <el-table-column label="转让净重" align="center">
              <template slot-scope="{ row }">
                <el-input
                  :disabled="!row.netWeightUnit"
                  v-model="row.totalnetWeight"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="净重单位"
              align="center"
              prop="netWeightUnit"
            >
              <template slot-scope="scope">
                {{ dict.sys_user_weight_unit[scope.row.netWeightUnit] }}
              </template>
            </el-table-column>
            <el-table-column
              label="入库要求"
              align="center"
              prop="warehousingRequirements"
            >
              <template slot-scope="scope">
                <span>{{
                  dict.warehousing_requirements[
                    scope.row.warehousingRequirements
                  ]
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="是否带托入库"
              align="center"
              prop="isWarehousingWithTray"
            >
              <template slot-scope="scope">
                <span>{{
                  dict.sys_yes_no[scope.row.isWarehousingWithTray]
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="所在仓库"
              align="center"
              prop="warehouseName"
            >
            </el-table-column>
            <el-table-column label="订单号" align="center" prop="orderNum">
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button
                  @click="orderHandleClick(scope.row)"
                  type="text"
                  size="small"
                  >选择</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </dialogPage>
    <dialogPage taostTips="选择关联新货权方" width="870px" ref="user">
      <template v-slot:content>
        <div style="display: flex; margin-bottom: 40px; width: 280px">
          <el-input
            v-model="tabbarFormData.phone"
            placeholder="请输入手机号"
          ></el-input>
          <el-button type="primary" @click="getRelatedReceiver">搜索</el-button>
        </div>
        <div v-show="!addUser">
          <el-table
            :data="gridData"
            :header-cell-style="{
              background: '#F2F2F2',
              'text-align': 'center',
              color: '#333333',
            }"
            border
          >
            <el-table-column
              align="center"
              property="orgName"
              label="公司名称"
            ></el-table-column>
            <el-table-column
              align="center"
              property="contactName"
              label="联系人"
            ></el-table-column>
            <el-table-column
              property="phone"
              label="联系电话"
              align="center"
            ></el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-link :underline="false" @click="association(scope.row)"
                  >关联</el-link
                >
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top: 30px">
            <el-link :underline="false" @click="addUser = true"
              >查询无此用户，请手动录入</el-link
            >
          </div>
        </div>
        <div v-show="addUser">
          <el-form :model="dialogForm" label-width="100px" ref="validateForm">
            <el-form-item
              label="公司名称"
              prop="orgName"
              :rules="[
                { required: true, message: '请输入公司名称', trigger: 'blur' },
              ]"
            >
              <el-input v-model="dialogForm.orgName"></el-input>
            </el-form-item>
            <el-form-item
              label="联系人"
              prop="contactName"
              :rules="[
                { required: true, message: '请输入联系人', trigger: 'blur' },
              ]"
            >
              <el-input v-model="dialogForm.contactName"></el-input>
            </el-form-item>
            <el-form-item
              label="联系人电话"
              prop="phone"
              :rules="[
                {
                  required: true,
                  message: '请输入联系人电话',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input v-model="dialogForm.phone"></el-input>
            </el-form-item>
          </el-form>
          <div>
            <el-button type="primary" @click="addUserFun">新增</el-button>
          </div>
        </div>
      </template>
    </dialogPage>
  </div>
</template>
<script>
import taostStorehouse from "@/components/taostStorehouse.vue";
import {
  libraryShop,
  getInventoryGoodsListGroupCargoNumber,
} from "../api/warehouseOut.js";
import {
  relatedReceiver,
  releaseTransferOrder,
  insertReceiver,
} from "@/api/business/transference.js";
export default {
  components: {
    taostStorehouse,
  },
  data() {
    return {
      dialogForm: {},
      addUser: false,
      tabbarFormData: {},
      // totalWeight: 0,
      // totalQuantity: 0,
      // totalNetWeight: 0,
      dialogTableVisible: false,
      gridData: [],
      dicts: [
        "sys_yes_no",
        "sys_user_count_unit",
        "sys_user_weight_unit",
        "warehousing_requirements",
      ],
      activeGoods: {},
      dict: {},
      collectiontext: "基本信息",
      returnPay: "关联新货权方",
      transferencePay: "转让信息",
      bannerHeight: "",
      libraryShopList: [],
      selectOrderList: [],
      associationOrderIndex: "",
      ruleForm: {
        warehouseId: "",
        warehouseName: "",
        unitPrice: "",
        goodsDetailRecordList: [],
        receiverId: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "blur",
          },
          {
            min: 3,
            max: 5,
            message: "长度在 3 到 5 个字符",
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: "请选择活动区域",
            trigger: "change",
          },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        resource: [
          {
            required: true,
            message: "请选择活动资源",
            trigger: "change",
          },
        ],
        desc: [
          {
            required: true,
            message: "请填写活动形式",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    getTotal() {
      return (arr, type) => {
        if (arr && arr.length) {
          let total = arr.reduce((prev, item) => {
            return Number(prev) + Number(item[type]);
          }, 0);
          return total ? total : 0;
        } else {
          return 0;
        }
      };
    },
  },
  created() {},
  methods: {
    addUserFun() {
      this.$refs.validateForm.validate((valid) => {
        if (valid) {
          insertReceiver(this.dialogForm).then((res) => {
            this.$message.success("添加成功!");
            this.dialogForm = {};
            this.addUser = false;
          });
        }
      });
    },
    association(row) {
      this.tabbarFormData = row;
      this.ruleForm.receiverId = row.id;
      this.$refs.user.closeDialog();
    },
    getRelatedReceiver() {
      if (!this.tabbarFormData.phone) {
        this.$message.warning("请输入要查询的手机号");
        return;
      }
      relatedReceiver(this.tabbarFormData).then((res) => {
        if (res.data.data) {
          this.gridData = [res.data.data];
          this.addUser = false;
        } else {
          this.$message.warning("未查询到新货权方");
        }
      });
    },
    deteleGoods(item) {
      this.$delete(
        this.ruleForm.goodsDetailRecordList,
        this.ruleForm.goodsDetailRecordList.indexOf(item)
      );
    },
    orderHandleClick(tab) {
      if (!tab.totalQuantity && !tab.totalWeight && !tab.totalNetWeight) {
        this.$message.warning("请输入要转让的货物数量");
        return;
      }
      let item = this.ruleForm.goodsDetailRecordList[
        this.activeGoods
      ].inventoryVOList.find((item) => {
        return item.orderNum == tab.orderNum;
      });
      if (tab.totalQuantity > tab.quantity) {
        this.$message.warning("超出订单库存数量");
        return;
      }

      if (tab.totalWeight > tab.weight) {
        this.$message.warning("超出订单库存重量");
        return;
      }

      if (tab.totalNetWeight > tab.netWeight) {
        this.$message.warning("超出订单库存净重量");
        return;
      }
      if (item) {
        this.$delete(
          this.ruleForm.goodsDetailRecordList[this.activeGoods].inventoryVOList,
          this.ruleForm.goodsDetailRecordList[
            this.activeGoods
          ].inventoryVOList.indexOf(tab)
        );
      }
      this.$refs.orders.closeDialog();
      this.ruleForm.goodsDetailRecordList[
        this.activeGoods
      ].inventoryVOList.push(tab);
      this.$forceUpdate();
    },
    //关联订单
    handleAssociation(row, index) {
      let cargoNumber = row.cargoNumber;
      libraryShop({
        cargoNumber: cargoNumber,
      }).then((response) => {
        if (response.data.code == 200) {
          this.$refs.orders.showDialog();
          this.activeGoods = index;
          if (this.ruleForm.goodsDetailRecordList[index].inventoryVOList) {
            response.data.rows.forEach((item, itemIndex) => {
              let data = this.ruleForm.goodsDetailRecordList[
                index
              ].inventoryVOList.find((child) => {
                return child.orderNum == item.orderNum;
              });
              if (data) {
                response.data.rows[itemIndex] = data;
              }
            });
          }
          this.selectOrderList = response.data.rows;
        } else {
          this.$message.error("关联失败！");
        }
      });
    },
    byBackList() {
      if (!this.ruleForm.warehouseId) {
        this.$message.warning("请选择仓库");
        return;
      }
      if (!this.ruleForm.goodsDetailRecordList.length) {
        this.$message.warning("请选择商品");
        return;
      }
      let filter = this.ruleForm.goodsDetailRecordList.find((item) => {
        return !item.inventoryVOList || !item.inventoryVOList.length;
      });
      if (filter) {
        this.$message.warning("请选择商品关联的订单");
        return;
      }
      if (!this.ruleForm.unitPrice) {
        this.$message.warning("请输入单价");
        return;
      }
      if (!this.ruleForm.receiverId) {
        this.$message.warning("请关联新货权方");
        return;
      }
      this.ruleForm.goodsDetailRecordList =
        this.ruleForm.goodsDetailRecordList.map((item) => {
          let inventoryVOList = item.inventoryVOList.map((child) => {
            child.quantity = child.totalQuantity;
            child.netWeight = child.totalNetWeight;
            child.weight = child.totalWeight;
            return child;
          });
          item.inventoryVOList = inventoryVOList;
          return item;
        });
      this.$confirm("是否发布转让订单?", "提示", { type: "warning" }).then(
        () => {
          releaseTransferOrder(this.ruleForm).then((res) => {
            if (res.data.code == 200) {
              this.$message.success("发布成功!");
              this.$router.go(-1);
            }
          });
        }
      );
    },
    handleClick(tab) {
      let item = this.ruleForm.goodsDetailRecordList.find(
        (item) => item.cargoNumber == tab.cargoNumber
      );
      if (item) {
        this.$message.warning("该商品已选择");
        return;
      }
      tab.inventoryVOList = [];
      this.ruleForm.goodsDetailRecordList.push(tab);
      this.$refs.shop.closeDialog();
    },
    agreeonWarehousing() {
      this.$router.push("/agreeonWarehousing");
    },
    clickDetail() {
      this.$refs.company.showDialog();
    },
    warehouseSelect(e) {
      this.ruleForm.warehouseName = e.warehouseName;
      this.ruleForm.warehouseId = e.id;
      this.$refs.company.closeDialog();
    },
    shopDetail() {
      this.getShopList(this.ruleForm.warehouseId);
      this.$refs.shop.showDialog();
      // this.warehouseId=this.ruleForm.warehouseId;
    },
    getunitType(type) {
      this.getDicts(type).then((res) => {
        this.dict[type] = {};
        res.data.data.map((item) => {
          this.dict[type][item.dictValue] = item.dictLabel;
        });
      });
    },
    getShopList(id) {
      if (id) {
        getInventoryGoodsListGroupCargoNumber({
          warehouseId: id,
        }).then((response) => {
          if (response.data.code == 200) {
            this.libraryShopList = response.data.data;
          } else {
            this.$message.error("在库商品查询失败！");
          }
        });
      }
    },
  },
  mounted() {
    for (let i = 0; i < this.dicts.length; i++) {
      this.getunitType(this.dicts[i]);
    }
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.bannerHeight = (600 / 1550) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (600 / 1550) * this.screenWidth;
    };
  },
};
</script>
<style scoped>
.box-submit {
  width: 450px;
  height: 62px;
  line-height: 62px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 31px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 145px;
}
.return-pay_button {
  text-align: start;
  padding: 20px 0;
}
.box-addBtn {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  background: #40b8ad;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  /* margin: 0 auto; */
  cursor: pointer;
}
.box-form /deep/ .el-select {
  width: 590px;
}

.box-form /deep/ .el-input {
  width: 590px;
}
.li-top {
  margin: 60px 0 40px 0;
}
.agreeOn-success {
  width: 450px;
  height: 62px;
  line-height: 62px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 31px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 158px;
  margin-bottom: 140px;
  cursor: pointer;
}
.box-details-text {
  margin-top: 50px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 48px;
}
.box-top {
  margin-top: 20px;
}

.ownership {
  text-align: start;
  padding: 20px;
  margin-bottom: 20px;
  background: #f0f0f0;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}
.ownership div {
  margin: 5px 0;
}
.box-details-session {
  display: flex;
  padding: 20px 0;
  margin-bottom: 20px;
  background: #f0f0f0;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}
.goods_detele {
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
}
.details-address {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.box-details-title {
  font-size: 36px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  text-align: center;
  margin: 80px 0 50px 0;
}

.carouselImg {
  width: 100%;
  height: inherit;
}

.scrollImg {
  width: 100%;
  height: auto;
  /* max-height: 780px; */
  position: relative;
}

/deep/ .el-carousel--horizontal {
  max-height: 780px;
}

/deep/ .el-carousel__container {
  max-height: 780px;
}
.el-row {
  margin-bottom: 20px;
}
</style>
