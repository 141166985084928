<template>
  <div>
    <template>
      <div class="scrollImg">
        <img ref="image" class="carouselImg" src="../assets/zhangdanBg.png" />
      </div>
      <div class="box-tabs">
        <div class="box-content">
          <span class="box-tabs-name">我的账单</span>
        </div>
      </div>
      <div class="box-content">
        <div class="box-personal">
          <div class="box-details-number">
            <div class="box-number">单号：{{ formData.billNum }}</div>
            <div class="box-details-content">
              <div class="box-content-item">
                <div class="box-tecxt">账单时间：{{ formData.billTime }}</div>
                <div class="box-tecxt">
                  账单金额：{{ formData.billTotalMoney }}
                </div>
              </div>
              <div class="box-content-right">
                <div class="box-tecxt">
                  已回款金额：{{ formData.receivedAmount }}
                </div>
                <div class="box-tecxt">
                  未回款金额：{{ formData.remainingCollectionAmount }}
                </div>
              </div>
            </div>
          </div>
          <div class="box-types">
            {{ dict.bill_status[formData.billState] }}
            <div class="tip">{{ tip }}</div>
          </div>
          <div class="edit-content">
            <div
              class="re-certification"
              v-if="formData.isPayFinish != '1'"
              @click="dialog = true"
            >
              上传凭证
            </div>
          </div>
        </div>
      </div>
      <div class="box-content">
        <div class="box-card-title">详情</div>
        <!-- 费用明细 -->
        <titlePage :titleText="sessiontext" class="li-top"></titlePage>
        <div class="box-content-center">
          <div
            class="box-content-item"
            v-for="(item, index) of formData.feeDetailRecordList"
            :key="index"
          >
            <div class="box-tips-title">单号：{{ item.orderNum }}</div>
            <div class="box-table">
              <el-table
                :data="item.billFeeDetailDetailRecordList"
                style="width: 100%"
                :header-cell-style="{
                  background: '#F2F2F2',
                  'text-align': 'center',
                  color: '#333333',
                }"
                border
                width="80"
              >
                <el-table-column
                  label="费用类型"
                  align="center"
                  prop="chargeFeeName"
                >
                </el-table-column>
                <el-table-column label="金额" align="center" prop="money">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <!-- 收款账户 -->
        <titlePage :titleText="contracttext" class="li-top"></titlePage>
        <template v-if="formData.logisticsExpensesAccountName">
          <div class="box-tips-title">物流费收款账户：</div>
          <div class="box-details-session" style="margin-top: 20px">
            <div class="details-address">
              <span>
                收款账户名称：{{ formData.logisticsExpensesAccountName }}</span
              >
              <span>开户行：{{ formData.logisticsExpensesOpeningBank }}</span>
              <span>银行账号：{{ formData.logisticsExpensesBankAccount }}</span>
            </div>
          </div>
        </template>
        <template v-if="formData.storageFeeAccountName">
          <div class="box-tips-title" style="margin-top: 20px">
            仓储费收款账户：
          </div>
          <div class="box-details-session" style="margin-top: 20px">
            <div class="details-address">
              <span> 收款账户名称：{{ formData.storageFeeAccountName }}</span>
              <span>开户行：{{ formData.storageFeeOpeningBank }}</span>
              <span>银行账号：{{ formData.storageFeeBankAccount }}</span>
            </div>
          </div>
        </template>
        <template v-if="formData.buyBackFeeAccountName">
          <div class="box-tips-title" style="margin-top: 20px">
            回购费收款账户：
          </div>
          <div class="box-details-session" style="margin-top: 20px">
            <div class="details-address">
              <span> 收款账户名称：{{ formData.buyBackFeeAccountName }}</span>
              <span>开户行：{{ formData.buyBackFeeOpeningBank }}</span>
              <span>银行账号：{{ formData.buyBackFeeBankAccount }}</span>
            </div>
          </div>
        </template>
        <template v-if="formData.transferFeeAccountName">
          <div class="box-tips-title" style="margin-top: 20px">
            转让费收款账户：
          </div>
          <div class="box-details-session" style="margin-top: 20px">
            <div class="details-address">
              <span> 收款账户名称：{{ formData.transferFeeAccountName }}</span>
              <span>开户行：{{ formData.transferFeeOpeningBank }}</span>
              <span>银行账号：{{ formData.transferFeeBankAccount }}</span>
            </div>
          </div>
        </template>
        <!-- 回款记录 -->
        <titlePage :titleText="collectiontext" class="li-top"></titlePage>
        <div class="box-table" style="margin-bottom: 60px">
          <el-table
            :data="formData.collectionRecordList"
            style="width: 100%"
            :header-cell-style="{
              background: '#F2F2F2',
              'text-align': 'center',
              color: '#333333',
            }"
            border
            width="80"
          >
            <el-table-column
              label="回款时间"
              align="center"
              prop="collectionTime"
            >
            </el-table-column>
            <el-table-column label="回款金额" align="center">
              <template slot-scope="{ row }">
                {{ row.collectionAmount ? row.collectionAmount : "0" }}
              </template>
            </el-table-column>
            <el-table-column label="财务备注" align="center" prop="remark">
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="{ row }">
                <div
                  class="box-select-btn box-btn-center"
                  @click="lookInfo(row)"
                >
                  查看凭证
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </template>
    <el-dialog title="上传凭证" :visible.sync="dialog">
      <el-form :model="dialogForm" label-width="120px">
        <el-form-item label="账单总金额：">
          <el-input
            :value="moneyFormat(formData.billTotalMoney)"
            autocomplete="off"
            :readonly="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="已回款金额：">
          <el-input
            :value="moneyFormat(formData.receivedAmount)"
            autocomplete="off"
            :readonly="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="剩余回款金额：">
          <el-input
            :value="moneyFormat(formData.remainingCollectionAmount)"
            autocomplete="off"
            :readonly="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="打款凭证：">
          <ImageUpload
            v-model="dialogForm.paymentVoucher"
            @input="uploadChange"
          ></ImageUpload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="凭证" :visible.sync="dialogVisible" width="50%">
      <el-image style="width: 100%" :src="dialogImg"> </el-image>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getBill, uploadVoucher } from "@/api/business/bill";
export default {
  name: "CessNav",
  components: {},
  data() {
    return {
      dicts: ["bill_status"],
      dict: {
        bill_status: {},
      },
      formData: {},
      dialogForm: {},
      dialog: false,
      dialogVisible: false,
      collectiontext: "回款记录",
      contracttext: "收款账户",
      sessiontext: "费用明细",
      bannerHeight: "",
      dialogImg: "",
    };
  },
  computed: {
    tip() {
      if (!this.formData.collectionRecordList) return "";
      let arr = this.formData.collectionRecordList;
      arr.reverse();
      let data = arr.find((item) => item.remark);
      return data ? data.remark : "";
    },
    moneyFormat() {
      return (money) => {
        if (money !== undefined) {
          const arr = money.toString().split(".");
          let num =
            (arr[0] || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") +
            (arr[1] ? "." + arr[1] : "");
          return `￥${num}`;
        } else {
          return `￥0`;
        }
      };
    },
  },
  methods: {
    lookInfo(row) {
      this.dialogImg = row.paymentVoucher;
      this.dialogVisible = true;
    },
    uploadChange(data) {
      console.log(data);
    },
    getunitType() {
      this.dicts.map((type) => {
        this.getDicts(type).then((res) => {
          this.dict[type] = {};
          res.data.data.map((item) => {
            this.dict[type][item.dictValue] = item.dictLabel;
          });
        });
      });
    },
    submit() {
      this.dialogForm.billId = this.formData.id;
      uploadVoucher(this.dialogForm).then((res) => {
        if (res.data.code == 200) {
          this.dialog = false;
          this.$message.success("上传成功！");
          this.getData();
        }
      });
    },
    getData() {
      getBill({
        billId: this.$route.query.id,
      }).then((res) => {
        this.formData = res.data.data;
      });
    },
  },
  created() {
    this.getData();
    this.getunitType();
  },

  mounted() {
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.bannerHeight = (600 / 1550) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (600 / 1550) * this.screenWidth;
    };
  },
};
</script>
<style scoped>
.box-content-center {
}

.box-tips-title {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  text-align: left;
}

.box-btns-top {
  margin-top: 40px;
}

.box-img-list {
  width: 598px;
  height: 280px;
  background: #ffffff;
  /* border: 1px solid #BCBCBC; */
  margin: 0 auto;
}

.box-picture-list {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.box-picture {
  width: 250px;
  height: 250px;
  margin-bottom: 30px;
}

.box-picture img {
  width: 100%;
  height: 100%;
}

.box-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-btns-warning {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background: #ff9000;
  box-sizing: border-box;
  margin-left: 30px;
}

.box-btns-emg {
  width: 150px;
  height: 52px;
  line-height: 50px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #40b8ad;
  background: #ffffff;
  border: 1px solid #40b8ad;
  box-sizing: border-box;
}

.box-btns-primary {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background: #40b8ad;
  margin-left: 30px;
}

.box-tips-text {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #40b8ad;
  text-align: left;
  margin-bottom: 30px;
}

.box-types {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #40b8ad;
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -12px;
  text-align: end;
}
.tip {
  margin-top: 5px;
  font-size: 16px;
  color: #ff7f01;
}
.box-details-number {
  width: 670px;
}

.box-tecxt {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-bottom: 10px;
  text-align: left;
}

.box-details-content {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.box-number {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  text-align: left;
}

.box-voucher {
  flex: 1;
  margin-left: 200px;
}

.box-voucher span {
  float: left;
}

.box-voucher img {
  float: left;
  width: 146px;
  height: 146px;
}

.box-details-text {
  margin-top: 50px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 48px;
}

.box-top {
  margin-top: 20px;
}

.box-details-session {
  /* padding: 40px 45px; */
  /* background: #F0F0F0; */
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
}

.details-address {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

/* 
	.details-address:after {
		content: ' ';
		flex: auto;
	} */

/* 
	.details-address span {
		flex: 1;
	} */
.details-address span {
  line-height: 18px;
}

.li-top {
  margin: 60px 0 40px 0;
}

.box-card-title {
  text-align: center;
  height: 42px;
  line-height: 42px;
  font-size: 42px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231714;
  margin-top: 60px;
  margin-bottom: 45px;
}

.box-addBtn {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  background: #40b8ad;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
  cursor: pointer;
}

.box-table {
  margin-top: 30px;
}

.edit-content {
  position: absolute;
  right: 30px;
  display: flex;
  top: 30px;
}

.re-certification {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #40b8ad;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-left: 20px;
  cursor: pointer;
}

.personal-edit {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #ffffff;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #40b8ad;
  cursor: pointer;
}

.personal-phone {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 16px;
  text-align: left;
}

.personal-name {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  margin-top: 16px;
  text-align: left;
}

.personal-corporation {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 72px;
  text-align: left;
}

.personal-corporation span {
  margin-left: 20px;
  padding: 4px 6px;
  background: #ffffff;
  border: 1px solid #ff9000;
  border-radius: 5px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ff9000;
}

.personal-text {
  margin-left: 30px;
}

.box-personal-session {
  float: left;
  margin-left: 50px;
  height: 100%;
  display: flex;
}

.block {
  margin-top: 52px;
}

.box-personal {
  height: auto;
  background: #f6f6f6;
  margin: 60px 0;
  padding: 30px;
  position: relative;
}

.box-tabs-name {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  height: 90px;
  line-height: 90px;
  float: left;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-tabs__header {
  margin: 0;
}

/deep/ .el-tabs__content {
  display: none;
}

/deep/ .el-tabs__item {
  height: 90px;
  line-height: 90px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

/deep/ .el-tabs {
  float: right;
}

.box-tabs {
  height: 90px;
  background: #f6f6f6;
}

.box-select-submit {
  display: block;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 100px;
  width: 160px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 24px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

.box-select-submit:hover {
  opacity: 0.8;
}

.card-address {
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  text-align: left;
  margin-top: 10px;
}

.card-title {
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  text-align: left;
}

.card-img {
  width: 100%;
  height: 246px;
}

.box-card {
  width: 380px;
  height: 340px;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-bottom: 30px;
  transition: all 0.3s;
  /* 上浮这个过程需要的时间 */
  cursor: pointer;
}

.box-card:hover {
  box-shadow: 0px 0px 10px 0px rgba(65, 181, 172, 0.35);
  transform: translate(0, -3px);
}

.box-card-content {
  padding: 20px;
  height: 54px;
}

.box-tab-card {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
}

.borderShare {
  border-right: 1px dashed #eeeeee;
}

.box-tab {
  text-align: center;
  width: 25%;
  box-sizing: border-box;
  cursor: pointer;
}

.box-tab span {
  display: block;
  text-align: center;
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  margin-top: 10px;
}

.box-tab img {
  display: block;
  width: 92px;
  margin: 0 auto;
  margin-top: 13px;
}

.box-fixed-tab {
  position: relative;
  margin: 0 auto;
  margin-top: -88px;
  width: 1200px;
  height: 150px;
  padding: 13px 0;
  background: #ffffff;
  box-shadow: 0px -6px 24px 0px rgba(65, 181, 172, 0.35);
  z-index: 99;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.carouselImg {
  width: 100%;
  height: inherit;
}

.scrollImg {
  width: 100%;
  height: auto;
  /* max-height: 780px; */
  position: relative;
}

/deep/ .el-carousel--horizontal {
  max-height: 780px;
}

/deep/ .el-carousel__container {
  max-height: 780px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.demo {
  display: inline-block;
  width: 600px;
  height: 338px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
</style>
