<template>
  <div>
    <div class="mapSearch">
      <el-input
        ref="input"
        placeholder="请输入你要查找的关键词"
        v-model="userInputs"
        id="input"
      ></el-input>
      <el-button type="primary" @click="biu">确定选址</el-button>
    </div>
    <div id="container"></div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "mapPage",
  props: {
    userInput: {
      type: String,
      default: null,
    },
  },
  watch: {
    getAmap() {
      setTimeout(() => {
        this.initMap();
      });
    },
    userInput: {
      handler(newValue) {
        if (newValue == null) {
          this.userInputs = "";
        } else {
          this.userInputs = newValue;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      map: null,
      autoOptions: {
        input: "input", //绑定的搜索关键字的input标签ID，用这个注册
      },
      auto: null,
      placeSearch: null,
      searchHere: null, //根据搜索组件搜索到以后的地点信息
      userInputs: this.userInput,
      geocoder: null,
      citySearch: null,
    };
  },
  computed: {
    ...mapGetters(["getAmap"]),
  },
  mounted() {
    if (this.getAmap) {
      setTimeout(() => {
        this.initMap();
      });
    }
  },
  methods: {
    // poi搜索以后 点击确认选址以后的操作
    biu() {
      if (this.searchHere) {
        //   this.$message({
        //       message: '确认地点：'+this.searchHere.name,
        //       type: 'success'
        //   });
        this.searchHere.userInput = this.userInputs;
        this.$emit("mapHere", this.searchHere);
      }
    },
    initMap() {
      let _this = this;

      let AMap = this.getAmap;
      this.map = new AMap.Map("container", {
        //设置地图容器id
        zoom: 17, //初始化地图级别
      });
      //引入高德地图的空间绑定使用
      this.map.addControl(new AMap.Scale());
      this.map.addControl(new AMap.ToolBar());
      this.map.addControl(new AMap.HawkEye());
      this.map.addControl(new AMap.MapType());
      this.map.addControl(new AMap.Geolocation());
      // this.map.addControl(new AMap.Geolocation())
      this.auto = new AMap.AutoComplete(this.autoOptions);
      //
      this.placeSearch = new AMap.PlaceSearch({
        map: this.map,
      });
      _this.map.setCenter(
        [
          _this.$store.state.accurateAddress.lng,
          _this.$store.state.accurateAddress.lat,
        ],
        true
      );
      //构造地点查询类
      this.auto.on("select", this.select); //绑定查询事件
      this.placeSearch.on("markerClick", (e) => {

        this.searchHere = {
          district:`${e.data.pname}${e.data.cityname}${e.data.adname}`,
          ...e.data
        };
      });
    },
    select(e) {
      this.placeSearch.setCity(e.poi.adcode); //依据城市ID搜索
      this.placeSearch.search(e.poi.name); //关键字查询查询
      this.map.setZoom(10, true);
      this.searchHere = e.poi;
      console.log(e.poi)
    },
  },
};
</script>

<style scoped>
.el-button--primary {
  background-color: #40b8ad !important;
  border-color: #40b8ad !important;
}

#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 300px;
}

.mapSearch {
  display: flex;
  margin-bottom: 8px;
}

.mapSearch .el-button {
  margin-left: 8px;
}

#tishikuang {
  margin-bottom: 200px;
  display: block;
}
</style>
<style>
.amap-sug-result {
  z-index: 2999 !important;
}
</style>
