import http from '@/utils/http'
// 查询入库单记录列表
export function listWarehousingOrder(params) {
  return http.get('/business/warehousingOrder/getCustomerWarehousingOrderList', params)
}

// 查询入库单记录详细
export function getWarehousingOrder(id) {
  return http.get('/business/warehousingOrder/getWarehousingOrderDetail/' + id)
}

// 新增入库单记录
export function addWarehousingOrder(params) {
  return http.post('/business/warehousingOrder', params)
}

// 修改入库单记录
export function updateWarehousingOrder(params) {
  return http.put('/business/warehousingOrder', params)
}

// 删除入库单记录
export function delWarehousingOrder(id) {
  return http.delete('/business/warehousingOrder/' + id)
}
//入库单确认
export function postConfirm(id) {
  return http.post('/business/warehousingOrder/customerConfirmation/' + id)
}

// 取消入库
export function applyCancel(data) {
  return http.post('/business/warehousingOrder/applyCancel', data,)
}
// 取消入库
export function customerAbandon(warehousingOrderDriverId) {
  return http.post('/business/warehousingOrder/customerAbandon/' + warehousingOrderDriverId)
}
export function getTransportationDocuments(data) {
  return http.get('/business/warehousingOrder/getTransportationDocuments', data)
}
export function isCustomerSignLogisticsContract() {
  return http.get('business/warehousingOrder/isCustomerSignLogisticsContract')
}
export function isCustomerSignWarehousingContract(data) {
  return http.get('/business/warehousingOrder/isCustomerSignWarehousingContract', data)
}
export function createWlht() {
  return http.post('/business/warehousingOrder/createWlht')
}
export function isCustomerSignPalletRentalContract() {
  return http.get('/business/warehousingOrder/isCustomerSignPalletRentalContract')
}
export function createTpzlht() {
  return http.post('/business/warehousingOrder/createTpzlht')
}
export function getWarehousingConfirmationFormContract(data) {
  return http.get('/business/warehousingOrder/getWarehousingConfirmationFormContract', data)
}
export function applyAddGoods(data) {
  return http.post('/business/warehousingOrder/applyAddGoods', data)
}
