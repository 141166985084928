<template>
  <div>
    <template>
      <div class="scrollImg">
        <img ref="image" class="carouselImg" src="../assets/mineBg.png" />
      </div>
      <div class="box-tabs">
        <div class="box-content">
          <span class="box-tabs-name">全部功能</span>
          <boxTabs :tabsIndex="1"></boxTabs>
        </div>
      </div>
      <div class="box-content">
        <div class="box-personal">
          <div class="box-personal-session">
            <div class="block">
              <el-avatar
                :size="134"
                :src="
                  getUserSession.customer.avatar
                    ? getUserSession.customer.avatar
                    : require('../assets/head.png')
                "
              ></el-avatar>
            </div>
            <div class="personal-text">
              <div class="personal-corporation">
                {{
                  getUserSession.data.userAuthState == 4
                    ? getUserSession.customer.orgName
                    : "未认证企业"
                }}<span v-if="getUserSession.data.userAuthState == 4"
                  >已认证</span
                ><span class="nodes" v-else>未认证</span>
              </div>
              <div class="personal-name">
                {{ getUserSession.data.nickName }}
              </div>
              <div class="personal-phone">
                {{
                  getUserSession.customer.phone
                    ? getUserSession.customer.phone
                    : ""
                }}
              </div>
            </div>
          </div>
          <div class="edit-content">
            <div class="personal-edit" @click="edit">编辑个人资料</div>
            <!-- <div class="re-certification">重新认证</div> -->
          </div>
        </div>
      </div>
      <div class="box-content">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <titlePage :titleText="sessiontext" class="li-top"></titlePage>
          <el-link :underline="false" @click="more">查看更多</el-link>
        </div>
        <div class="box-table">
          <el-table
            :data="tableData"
            style="width: 100%"
            :header-cell-style="{
              background: '#F2F2F2',
              'text-align': 'center',
            }"
            border
            width="80"
          >
            <el-table-column label="货物单号" align="center" prop="cargoNumber">
            </el-table-column>
            <el-table-column label="商品品类" align="center" prop="typeName">
            </el-table-column>
            <el-table-column label="商品名称" align="center" prop="goodsName">
            </el-table-column>
            <el-table-column label="型号" align="center" prop="goodsModel">
            </el-table-column>
            <el-table-column label="总个数" align="center" prop="quantity">
            </el-table-column>
            <el-table-column label="总重量" align="center" prop="weight">
            </el-table-column>
            <el-table-column label="总净重" align="center" prop="netWeight">
            </el-table-column>
            <el-table-column
              label="入库要求"
              align="center"
              prop="warehousingRequirements"
            >
              <template slot-scope="scope">
                {{
                  scope.row.warehousingRequirements == "1"
                    ? "批次入库"
                    : "非批次入库"
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="带托入库"
              align="center"
              prop="isWarehousingWithTray"
            >
              <template slot-scope="scope">
                {{ scope.row.isWarehousingWithTray ? "是" : "否" }}
              </template>
            </el-table-column>
            <el-table-column label="仓库" align="center" prop="warehouseName">
            </el-table-column>
            <el-table-column
              label="仓位"
              align="center"
              prop="warehouseBinName"
            >
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="box-select-btn" @click="lookInfo(scope.row)">
                  查看详情
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="box-addBtn" @click="logOut">退出登录</div>
      </div>
    </template>
  </div>
</template>
<script>
import { getInventoryGoodsListGroupCargoNumber } from "@/api/business/goods.js";
import { mapGetters } from "vuex";
import { getUser } from "../utils/api.js";
import boxTabs from "@/components/boxTabs.vue";
export default {
  name: "CessNav",
  components: {
    boxTabs,
  },
  data() {
    return {
      sessiontext: "我的货物",
      activeName: null,
      bannerHeight: "",
      tableData: [],
    };
  },
  computed: {
    ...mapGetters(["getUserSession"]),
  },
  created() {
    this.getData();
    this.getUserinfo();
  },
  methods: {
    getUserinfo() {
      getUser()
        .then((response) => {
          if (response.data.code == 200) {
            this.$store.commit("setUserSession", response.data);
          }
        })
        .catch(() => {
          this.$message.error("获取失败！");
        });
    },
    more() {
      this.$router.push("/goodsPage");
    },
    edit() {
      this.$router.push("/modifyPage");
    },
    lookInfo(row) {
      this.$router.push({
        path: "/goodsDetails",
        query: {
          id: row.cargoNumber,
        },
      });
    },
    getData() {
      getInventoryGoodsListGroupCargoNumber({}).then((res) => {
        if (res.data.data.length > 5) {
          res.data.data = res.data.data.slice(0, 5);
        }
        this.tableData = res.data.data;
      });
    },
    logOut() {
      localStorage.setItem("Token", "");
      this.$router.push("/loginPage");
    },
  },
  mounted() {
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.bannerHeight = (600 / 1550) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (600 / 1550) * this.screenWidth;
    };
  },
};
</script>
<style scoped>
.nodes {
  color: #666666 !important;
  border: 1px solid #666666 !important;
}
.box-addBtn {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  background: #40b8ad;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
  cursor: pointer;
}

.box-table {
  margin-top: 30px;
}

.edit-content {
  float: right;
  margin-right: 30px;
  display: flex;
  margin-top: 30px;
}

.re-certification {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #40b8ad;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-left: 20px;
  cursor: pointer;
}

.personal-edit {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #ffffff;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #40b8ad;
  cursor: pointer;
}

.personal-phone {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 16px;
  text-align: left;
}

.personal-name {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  margin-top: 16px;
  text-align: left;
}

.personal-corporation {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 72px;
  text-align: left;
}

.personal-corporation span {
  margin-left: 20px;
  padding: 4px 6px;
  background: #ffffff;
  border: 1px solid #ff9000;
  border-radius: 5px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ff9000;
}

.personal-text {
  margin-left: 30px;
}

.box-personal-session {
  float: left;
  margin-left: 50px;
  height: 100%;
  display: flex;
}

.block {
  margin-top: 52px;
}

.box-personal {
  height: 238px;
  background: #f6f6f6;
  margin: 60px 0;
  position: relative;
}

.box-tabs-name {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  height: 90px;
  line-height: 90px;
  float: left;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-tabs__header {
  margin: 0;
}

/deep/ .el-tabs__content {
  display: none;
}

/deep/ .el-tabs__item {
  height: 90px;
  line-height: 90px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

/deep/ .el-tabs {
  float: right;
}

.box-tabs {
  height: 90px;
  background: #f6f6f6;
}

.box-select-submit {
  display: block;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 100px;
  width: 160px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 24px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

.box-select-submit:hover {
  opacity: 0.8;
}

.card-address {
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  text-align: left;
  margin-top: 10px;
}

.card-title {
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  text-align: left;
}

.card-img {
  width: 100%;
  height: 246px;
}

.box-card {
  width: 380px;
  height: 340px;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-bottom: 30px;
  transition: all 0.3s;
  /* 上浮这个过程需要的时间 */
  cursor: pointer;
}

.box-card:hover {
  box-shadow: 0px 0px 10px 0px rgba(65, 181, 172, 0.35);
  transform: translate(0, -3px);
}

.box-card-content {
  padding: 20px;
  height: 54px;
}

.box-tab-card {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
}

.box-card-title {
  text-align: center;
  height: 42px;
  line-height: 42px;
  font-size: 42px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231714;
  margin-top: 167px;
}

.borderShare {
  border-right: 1px dashed #eeeeee;
}

.box-tab {
  text-align: center;
  width: 25%;
  box-sizing: border-box;
  cursor: pointer;
}

.box-tab span {
  display: block;
  text-align: center;
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  margin-top: 10px;
}

.box-tab img {
  display: block;
  width: 92px;
  margin: 0 auto;
  margin-top: 13px;
}

.box-fixed-tab {
  position: relative;
  margin: 0 auto;
  margin-top: -88px;
  width: 1200px;
  height: 150px;
  padding: 13px 0;
  background: #ffffff;
  box-shadow: 0px -6px 24px 0px rgba(65, 181, 172, 0.35);
  z-index: 99;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.carouselImg {
  width: 100%;
  height: inherit;
}

.scrollImg {
  width: 100%;
  height: auto;
  /* max-height: 780px; */
  position: relative;
}

/deep/ .el-carousel--horizontal {
  max-height: 780px;
}

/deep/ .el-carousel__container {
  max-height: 780px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.demo {
  display: inline-block;
  width: 600px;
  height: 338px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
</style>
