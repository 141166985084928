import request from '@/utils/request'

// 查询客户管理列表
export function listCustomer(query) {
  return request({
    url: '/business/customer/list',
    method: 'get',
    params: query
  })
}

// 查询客户管理详细
export function getCustomer(id) {
  return request({
    url: '/business/customer/' + id,
    method: 'get'
  })
}

// 新增客户管理
export function addCustomer(data) {
  return request({
    url: '/business/customer',
    method: 'post',
    data: data
  })
}

// 修改客户管理
export function updateCustomer(data) {
  return request({
    url: '/business/customer',
    method: 'put',
    data: data
  })
}

// 删除客户管理
export function delCustomer(id) {
  return request({
    url: '/business/customer/' + id,
    method: 'delete'
  })
}
// 获取省市区树形列表
export function getTree(query) {
  return request({
    url: '/baseCity/getTree',
    method: 'get',
    params: query
  })
}
// 查询商品类型管理不分页列表
export function listAll(query) {
  return request({
    url: '/business/goodsType/listAll',
    method: 'get',
    params: query
  })
}
// 补录客户
export function supplementaryEntryCustomer(data) {
  return request({
    url: '/business/customer/supplementaryEntryCustomer',
    method: 'POST',
    data: data
  })
}

// 合同配置 / 修改结算模式
export function updateSettlementMode(data) {
  return request({
    url: '/business/customer/updateSettlementMode',
    method: 'POST',
    data: data
  })
}

export function customerAuth() {
  return request({
    url: '/business/customer/login/customerAuth',
    method: 'POST',
  })
}

export function updateContact(data) {
  return request({
    url: '/business/customer/login/updateContactName',
    method: 'POST',
    data
  })
}