<template>
  <div>
    <template>
      <div class="scrollImg">
        <img
          ref="image"
          class="carouselImg"
          src="../assets/hetongBg.png"
        />
      </div>
      <div class="box-tabs">
        <div class="box-content">
          <span class="box-tabs-name">我的合同</span>
          <!-- <boxTabs></boxTabs> -->
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              :label="item.label"
              :name="item.name"
              v-for="item in tabsList"
              :key="item.name"
            ></el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <!-- 仓储 -->
      <transition name="el-fade-in">
        <div class="box-content">
          <div class="box-table">
            <el-table
              :data="tableData"
              style="width: 100%"
              :header-cell-style="{
                background: '#F2F2F2',
                'text-align': 'center',
              }"
              border
              width="80"
            >
              <el-table-column
                label="合同编号"
                align="center"
                prop="contractNum"
              >
              </el-table-column>
              <el-table-column
                label="签署公司"
                align="center"
                prop="customerName"
                v-if="activeName == 1 || activeName == 3"
              >
              </el-table-column>
              <el-table-column
                label="转让方"
                align="center"
                prop="transferorName"
                v-if="activeName > 3"
              >
              </el-table-column>
              <el-table-column
                label="接收方"
                align="center"
                prop="recipientName"
                v-if="activeName > 3"
              >
              </el-table-column>
              <el-table-column
                label="物流公司"
                align="center"
                prop="customerName"
                v-if="activeName == 2"
              >
              </el-table-column>
              <el-table-column label="签订时间" align="center" prop="signTime">
              </el-table-column>
              <el-table-column label="状态" align="center" prop="signState">
                <template slot-scope="{ row }">
                  {{ singState(row.signState) }}
                </template>
              </el-table-column>
              <el-table-column
                label="商务负责人"
                align="center"
                prop="contractSignatory"
                v-if="activeName == 1"
              >
              </el-table-column>
              <el-table-column
                label="合同签署人"
                align="center"
                prop="contractSignatory"
                v-if="activeName != 1"
              >
              </el-table-column>
              <el-table-column label="操作"  width="200">
                <template slot-scope="{ row }">
                  <div class="btn_container">
                    <div
                      class="box-select-btn"
                      @click="contractDetails(row, 0)"
                    >
                      查看详情
                    </div>
                    <div
                      v-if="row.signState < 3"
                      class="box-select-btn"
                      @click="contractDetails(row, 1)"
                    >
                      签署
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="box-pagination">
            <PaginationPage
              v-show="total > 0"
              :total="total"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="getList"
            ></PaginationPage>
          </div>
        </div>
      </transition>
    </template>
  </div>
</template>
<script>
import { listContract, signContract } from "@/api/business/contract";
export default {
  name: "CessNav",
  components: {},
  data() {
    return {
      sessiontext: "我的货物",
      activeName: "1",
      bannerHeight: "",
      tableData: [],
      queryParams: {},
      tabsList: [
        {
          label: "仓储",
          name: "1",
        },
        {
          label: "物流",
          name: "2",
        },
        {
          label: "托盘租赁",
          name: "3",
        },
        {
          label: "监管协议",
          name: "6",
        },
        {
          label: "解除监管协议",
          name: "8",
        },
        {
          label: "转让单",
          name: "9",
        },
        {
          label: "回购",
          name: "7",
        },
      ],
      total: 0,
    };
  },
  computed: {
    singState() {
      return (val) => {
        if (val == 1) {
          return "待签署";
        } else if (val == 2) {
          return "签署中";
        } else if (val == 3) {
          return "已签署";
        } else if (val == 4) {
          return "已作废";
        } else if (val == 5) {
          return "拒签";
        }
      };
    },
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      let parasm = {
        ...this.queryParams,
        contractType: this.activeName,
      };
      listContract(parasm).then((res) => {
        this.tableData = res.data.rows;
        this.total = res.data.total;
      });
    },
    handleClick() {
      this.getList();
    },
    contractDetails(row, isEdit) {
      if (!isEdit) {
        window.open(row.viewpdfUrl);
      } else {
        signContract({
          contractId: row.id,
        }).then((res) => {
          window.open(res.data.data);
        });
      }
    },
  },
  mounted() {
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.bannerHeight = (600 / 1550) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (600 / 1550) * this.screenWidth;
    };
  },
};
</script>
<style scoped>
.box-addBtn {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  background: #40b8ad;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
  cursor: pointer;
}

.box-table {
  margin-top: 30px;
}

.edit-content {
  float: right;
  margin-right: 30px;
  display: flex;
  margin-top: 30px;
}

.re-certification {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #40b8ad;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-left: 20px;
  cursor: pointer;
}

.personal-edit {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #ffffff;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #40b8ad;
  cursor: pointer;
}

.personal-phone {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 16px;
  text-align: left;
}

.personal-name {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  margin-top: 16px;
  text-align: left;
}

.personal-corporation {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 72px;
  text-align: left;
}

.personal-corporation span {
  margin-left: 20px;
  padding: 4px 6px;
  background: #ffffff;
  border: 1px solid #ff9000;
  border-radius: 5px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ff9000;
}

.personal-text {
  margin-left: 30px;
}

.box-personal-session {
  float: left;
  margin-left: 50px;
  height: 100%;
  display: flex;
}

.block {
  margin-top: 52px;
}

.box-personal {
  height: 238px;
  background: #f6f6f6;
  margin: 60px 0;
  position: relative;
}

.box-tabs-name {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  height: 90px;
  line-height: 90px;
  float: left;
}
.btn_container {
  display: flex;
  justify-content: center;
}
.box-select-btn {
  padding: 0 10px;
  margin: 0 5px;
}
/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-tabs__header {
  margin: 0;
}

/deep/ .el-tabs__content {
  display: none;
}

/deep/ .el-tabs__item {
  height: 90px;
  line-height: 90px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

/deep/ .el-tabs {
  float: right;
}

.box-tabs {
  height: 90px;
  background: #f6f6f6;
}

.box-select-submit {
  display: block;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 100px;
  width: 160px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 24px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

.box-select-submit:hover {
  opacity: 0.8;
}

.card-address {
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  text-align: left;
  margin-top: 10px;
}

.card-title {
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  text-align: left;
}

.card-img {
  width: 100%;
  height: 246px;
}

.box-card {
  width: 380px;
  height: 340px;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-bottom: 30px;
  transition: all 0.3s;
  /* 上浮这个过程需要的时间 */
  cursor: pointer;
}

.box-card:hover {
  box-shadow: 0px 0px 10px 0px rgba(65, 181, 172, 0.35);
  transform: translate(0, -3px);
}

.box-card-content {
  padding: 20px;
  height: 54px;
}

.box-tab-card {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
}

.box-card-title {
  text-align: center;
  height: 42px;
  line-height: 42px;
  font-size: 42px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231714;
  margin-top: 167px;
}

.borderShare {
  border-right: 1px dashed #eeeeee;
}

.box-tab {
  text-align: center;
  width: 25%;
  box-sizing: border-box;
  cursor: pointer;
}

.box-tab span {
  display: block;
  text-align: center;
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  margin-top: 10px;
}

.box-tab img {
  display: block;
  width: 92px;
  margin: 0 auto;
  margin-top: 13px;
}

.box-fixed-tab {
  position: relative;
  margin: 0 auto;
  margin-top: -88px;
  width: 1200px;
  height: 150px;
  padding: 13px 0;
  background: #ffffff;
  box-shadow: 0px -6px 24px 0px rgba(65, 181, 172, 0.35);
  z-index: 99;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.carouselImg {
  width: 100%;
  height: inherit;
}

.scrollImg {
  width: 100%;
  height: auto;
  /* max-height: 780px; */
  position: relative;
}

/deep/ .el-carousel--horizontal {
  max-height: 780px;
}

/deep/ .el-carousel__container {
  max-height: 780px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.demo {
  display: inline-block;
  width: 600px;
  height: 338px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
</style>
