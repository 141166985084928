<template>
  <div class="taostBackground" v-show="taostShow">
    <div class="taost">
      <div class="taost-title">
        <span class="taost-tips">{{ taostTips }}</span>
        <span class="taost-close" @click="taostShow = false"
          ><img src="../assets/taostClose.png" alt="" srcset=""
        /></span>
      </div>
      <div class="taost-content">
        <template>
          <div class="warp-content">
            <el-dropdown>
              <span class="el-dropdown-link card-title">
                {{ addr }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>黄金糕</el-dropdown-item>
                <el-dropdown-item>狮子头</el-dropdown-item>
                <el-dropdown-item>螺蛳粉</el-dropdown-item>
                <el-dropdown-item>双皮奶</el-dropdown-item>
                <el-dropdown-item>蚵仔煎</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="wrap">
              <!-- 标签导航 -->
              <div class="searchBox navBox">
                <div style="width: 100%">
                  <div
                    class="good_city"
                    :style="'transform: translateX(' + tabIndex + 'px);'"
                  >
                    <div
                      class="tabBox"
                      :id="'tab' + index"
                      v-for="(item, index) in types"
                      :key="index"
                      :class="tabChose == index ? 'tabChose' : ''"
                      @click="choseTab(index, item.id)"
                    >
                      <div>{{ item.typeName }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <i
                class="el-icon-arrow-left"
                :style="tabOn == 0 ? 'color:#CCCCCC' : 'color:#999999'"
                @click="tabLeft"
              ></i>
              <i
                class="el-icon-arrow-right"
                :style="
                  tabOn == types.length - 3 ? 'color:#CCCCCC' : 'color:#999999'
                "
                @click="tabRight"
              ></i>
            </div>
          </div>
        </template>
        <template v-if="warehouseList.length">
          <el-scrollbar style="height: 100%">
            <div class="box-tab-card">
              <div
                class="box-card"
                v-for="(item, index) in warehouseList"
                :key="index"
                @click="selectPage(item)"
              >
                <img
                  class="card-img"
                  :src="getImgOne(item.listPic)"
                  alt=""
                  srcset=""
                />
                <div class="box-card-content">
                  <div class="card-title">
                    {{ item.warehouseName }}
                  </div>
                  <div class="card-address">
                    {{ item.city }} 距离您{{ item.distance }}km 总容量{{
                      item.capacityCount
                    }}{{ item.capacityCountUnit }}
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </template>
        <template v-else>
          <el-empty style="margin-top: 50px;" :image-size="200"></el-empty>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { getRecommendList, getPageTypeList } from "../api/warehouse.js";
import { isCustomerSignWarehousingContract } from "@/api/business/warehousingOrder";
export default {
  name: "taostPage",
  components: {},
  props: {
    taostContent: {
      type: String,
      default: "测试的内容数据",
    },
    taostBtnName: {
      type: String,
      default: "我点击",
    },
    taostTips: {
      type: String,
      default: "选择仓库",
    },
  },
  data() {
    return {
      addr: "保定市",
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        longitude: "115.55761",
        latitude: "38.878718",
        warehouseType: "",
      },
      total: 0,
      types: [],
      warehouseList: [],
      taostShow: false,
      tabChose: 0, //选择的tab
      tabIndex: 0, //tab需要移动的宽
      tabOn: 0, //记录移动几块
    };
  },
  methods: {
    selectPage(e) {
      isCustomerSignWarehousingContract({
        warehouseId: e.id,
      }).then((res) => {
        if (res.data.data) {
          this.$emit("warehouseSelect", e);
        } else {
          this.$message("该仓库未签署仓储协议");
        }
      });
    },
    closeDialog() {
      this.taostShow = false;
    },
    showDialog() {
      this.taostShow = true;
    },
    handleClick(id) {
      this.queryParams.warehouseType = id.toString();
      this.getList();
    },
    choseTab(e, id) {
      //点击tab
      let indexBefore = 0,
        indexNow = 0,
        tabidnow = 0; //需要移动的宽
      this.types.forEach((item, index) => {
        if (item.index == this.tabChose) {
          indexBefore = index; //之前的下标
        }
        if (e == item.index) {
          indexNow = index;
        }
      });
      this.tabChose = e; //赋值当前选中的值
      for (let i = indexBefore; i < indexNow; i++) {
        //循环中间间隔了多少
        tabidnow += document.getElementById("tab" + i).clientWidth + 10;
        if (i == indexNow - 1) {
          this.tabIndex -= tabidnow;
        }
      }
      this.handleClick(id);
    },
    tabLeft() {
      //左箭头切换
      if (this.tabOn == 0) {
        return;
      }
      this.tabOn -= 1;
      this.tabIndex += document.getElementById("tab0").clientWidth + 10;
      //内容往左边移动上一个div的宽
    },
    tabRight() {
      //右箭头切换
      if (this.types.length - 3 <= this.tabOn) {
        return;
      }
      this.tabOn += 1;
      this.tabIndex -= document.getElementById("tab0").clientWidth + 10;
    },
    // 获取仓库列表信息
    getList() {
      getRecommendList(this.queryParams)
        .then((response) => {
          if (response.data.code == 200) {
            this.warehouseList = response.data.rows;
            this.total = response.data.total;
          }
        })
        .catch(() => {
          this.$message.error("获取失败！");
        });
    },
    //获取仓库分类列表信息、
    getTypeList() {
      getPageTypeList(this.queryParams)
        .then((response) => {
          if (response.data.code == 200) {
            this.types = response.data.rows;
            this.handleClick(response.data.rows[0].id.toString());
          }
        })
        .catch(() => {
          this.$message.error("获取失败！");
        });
    },
    // 查看仓库信息
    selectDetails(id) {
      this.$router.push({
        path: "/storehouseDetails",
        query: {
          id: id,
        },
      });
    },
    //返回图片第一张
    getImgOne(e) {
      if (!e) {
        return "";
      }
      let n = e.split(",")[0];
      return n;
    },
  },
  mounted() {
    this.getTypeList();
    this.getList();
  },
};
</script>

<style scoped>
.warp-content {
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-address {
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  text-align: left;
  margin-top: 10px;
}

.card-title {
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  text-align: left;
}

.card-img {
  width: 100%;
  height: 246px;
}

.box-card {
  width: 380px;
  height: 337px;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-bottom: 30px;
  transition: all 0.3s;
  /* 上浮这个过程需要的时间 */
  cursor: pointer;
}

.box-card:hover {
  box-shadow: 0px 0px 10px 0px rgba(65, 181, 172, 0.35);
  transform: translate(0, -3px);
}

.box-card-content {
  padding: 20px;
  height: 50px;
}

.box-tab-card {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 28px;
}

.box-card-title {
  text-align: center;
  height: 42px;
  line-height: 42px;
  font-size: 42px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231714;
  margin-top: 167px;
}

.box-city {
  float: left;
}

.box-city span {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}

.el-icon-arrow-left {
  position: absolute;
  left: -36px;
  top: 10px;
  font-size: 20px;
}

.el-icon-arrow-right {
  position: absolute;
  right: -36px;
  top: 10px;
  font-size: 20px;
}

.wrap {
  float: right;
  margin-right: 36px;
  width: 380px;
  position: relative;
}

.navBox {
  width: 380px;
  background: #ffffff;
  position: relative;
  overflow: hidden;
}

.tabBox {
  padding: 0 12px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  background: #eaeaea;
  float: left;
  margin-right: 10px;
}

.tabBox:hover {
  cursor: pointer;
}

.tabChose {
  background: #40b8ad;
  color: #ffffff;
}

.good_city {
  width: 9999px;
  height: 40px;
  transition: all 0.5s;
}

.contrlBox {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 80px;
  height: 80px;
  background: #ffffff;
}

.contrlBox i {
  font-size: 18px;
  flex-wrap: bold;
}

.contrlBox i:hover {
  cursor: pointer;
}

.taost-success {
  width: 246px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 35px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  position: absolute;
  bottom: 65px;
  left: 50%;
  margin-left: -123px;
  cursor: pointer;
}

.taost-content {
  padding: 30px;
  height: 774px;
  overflow: hidden;
}

.taost-title {
  width: 100%;
  height: 80px;
  background: #f2f2f2;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.taost-tips {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  height: 80px;
  line-height: 80px;
  margin-left: 20px;
}

.taost-close {
  width: 34px;
  height: 34px;
  margin-top: 23px;
  margin-right: 20px;
  cursor: pointer;
}

.taost-close img {
  width: 34px;
  height: 34px;
}

.taostBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
}

.taost {
  position: absolute;
  top: 50%;
  margin-top: -457px;
  left: 50%;
  margin-left: -425px;
  width: 850px;
  height: 914px;
  background: #ffffff;
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
}
</style>
