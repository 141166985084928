<template>
  <div>
    <template>
      <div class="scrollImg">
        <img ref="image" class="carouselImg" src="../assets/orderBg.png" />
      </div>
      <div class="box-tabs">
        <div class="box-content">
          <span class="box-tabs-name">我的订单</span>
        </div>
      </div>
      <div class="box-content">
        <div class="box-personal">
          <div class="box-details-number">
            <div class="box-number">
              单号：{{ formData.transferOrderRecord.orderNum }}
            </div>
            <div class="box-details-content">
              <div class="box-content-left">
                <div class="box-tecxt">
                  原货权方：{{ formData.transferor.orgName }}
                </div>
                <div class="box-tecxt">
                  新货权方：{{ formData.receiver.orgName }}
                </div>
                <div class="box-tecxt">
                  发布时间：{{ formData.transferOrderRecord.releaseTime }}
                </div>
                <div class="box-tecxt">
                  仓库：{{ formData.warehouseRecord.warehouseName }}
                </div>
              </div>
              <div class="box-content-right">
                <div class="box-tecxt">
                  原货权方手机号：{{ formData.transferOrderRecord.releaseTime }}
                </div>
                <div class="box-tecxt">
                  新货权方手机号：{{ formData.transferOrderRecord.releaseTime }}
                </div>
                <div
                  class="box-tecxt"
                  v-if="formData.transferOrderRecord.totalWeight"
                >
                  转让总重量：{{ formData.transferOrderRecord.totalWeight }}
                </div>
                <div
                  class="box-tecxt"
                  v-if="formData.transferOrderRecord.netWeight"
                >
                  转让总净量：{{ formData.transferOrderRecord.netWeight }}
                </div>
                <div
                  class="box-tecxt"
                  v-if="formData.transferOrderRecord.totalQuantity"
                >
                  转让总数量：{{ formData.transferOrderRecord.totalQuantity }}
                </div>
              </div>
            </div>
          </div>
          <div class="box-types">
            {{
              formData.transferOrderRecord.orderState &&
              dict.transfer_order_state[formData.transferOrderRecord.orderState]
            }}
          </div>
          <template
            v-if="formData.transferOrderRecord.orderState == 2 && isTransfer"
          >
            <div class="edit-content">
              <div
                class="re-certification"
                @click="$router.push('/transferencePage')"
              >
                重新申请
              </div>
            </div>
          </template>
          <template
            v-if="formData.transferOrderRecord.orderState == 1 && isTransfer"
          >
            <div class="edit-content">
              <div class="re-certification" @click="cancelTransferOrder">
                取消转让
              </div>
            </div>
          </template>
          <template
            v-if="
              isTransfer && !formData.transferOrderRecord.transferorSigningTime
            "
          >
            <div class="edit-content">
              <div
                class="re-certification"
                v-if="
                  formData.transferOrderRecord.orderState &&
                  formData.transferOrderRecord.orderState == 4
                "
                @click="sign"
              >
                确认并签章
              </div>
            </div>
          </template>
          <template
            v-if="
              !isTransfer && !formData.transferOrderRecord.receiverSigningTime
            "
          >
            <div class="edit-content">
              <div
                class="re-certification"
                v-if="
                  formData.transferOrderRecord.orderState &&
                  formData.transferOrderRecord.orderState == 4
                "
                @click="sign"
              >
                确认并签章
              </div>
            </div>
          </template>
          <template
            v-if="formData.transferOrderRecord.orderState == 6 && isTransfer"
          >
            <div class="edit-content">
              <div class="re-certification" @click="$router.push('/billPage')">
                查看并支付账单
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="box-content">
        <div class="box-card-title">详情</div>
        <titlePage :titleText="sessiontext" class=""></titlePage>
        <div class="box-table">
          <el-table
            :data="formData.detailList"
            style="width: 100%"
            :header-cell-style="{
              background: '#F2F2F2',
              'text-align': 'center',
              color: '#333333',
            }"
            border
            width="80"
          >
            <el-table-column
              label="商品类型"
              align="center"
              prop="goodsTypeName"
            >
            </el-table-column>
            <el-table-column label="商品名称" align="center" prop="goodsName">
            </el-table-column>
            <el-table-column
              label="厂家"
              align="center"
              prop="goodsManufacturerName"
            >
            </el-table-column>
            <el-table-column label="型号" align="center" prop="goodsModelName">
            </el-table-column>
            <el-table-column label="规格" align="center" prop="goodsSpecName">
            </el-table-column>
            <el-table-column label="总数量" align="center" prop="totalQuantity">
              <template slot-scope="{ row }">
                {{ row.totalQuantity ? row.totalQuantity : "--" }}
              </template>
            </el-table-column>
            <el-table-column label="总重量" align="center" prop="totalWeight">
              <template slot-scope="{ row }">
                {{ row.totalWeight ? row.totalWeight : "--" }}
              </template>
            </el-table-column>
            <el-table-column label="净重" align="center" prop="netWeight">
              <template slot-scope="{ row }">
                {{ row.netWeight ? row.netWeight : "--" }}
              </template>
            </el-table-column>
            <el-table-column
              label="入库要求"
              align="center"
              prop="warehousingRequirements"
            >
              <template slot-scope="scope">
                {{
                  scope.row.warehousingRequirements == "1"
                    ? "批次入库"
                    : "非批次入库"
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="带托入库"
              align="center"
              prop="isWarehousingWithTray"
            >
              <template slot-scope="scope">
                {{ scope.row.isWarehousingWithTray == "1" ? "是" : "否" }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 转让单 -->
        <template v-if="this.formData.transferOrderRecord.orderState > 4">
          <titlePage :titleText="transference" class="li-top"></titlePage>
          <div class="box-details-session">
            <div class="details-address">
              <span
                >转让方签订时间：{{
                  formData.transferOrderRecord.releaseTime
                }}</span
              >
              <span
                >接收方签订时间：{{
                  formData.transferOrderRecord.receiverSigningTime
                }}</span
              >
              <span
                >商务签订时间：{{
                  formData.transferOrderRecord.businessManagerSigningTime
                }}</span
              >
            </div>
          </div>
          <div class="box-btns box-btns-top">
            <div class="box-btns-emg box-all-btn" @click="selectContract">
              查看合同
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
import {
  getTransferOrderDetail,
  signTransferOrderContract,
  getTransferConfirmationFormContract,
  cancelTransferOrder,
} from "@/api/business/transference";
export default {
  name: "CessNav",
  components: {},
  data() {
    return {
      dicts: ["transfer_order_state"],
      dict: {},
      formData: {
        detailList: [],
        receiver: {},
        transferOrderRecord: {},
        transferOrderStateRecord: {},
        transferor: {},
        warehouseRecord: {},
      },
      transference: "转让单",
      sessiontext: "商品信息",
      bannerHeight: "",
      isTransfer: false,
      tableDataSignature: [
        {
          date: "阿皮",
          name: "王小虎",
          address: "阿肥",
        },
      ],
    };
  },
  computed: {
    userData() {
      return this.$store.state.userSession;
    },
  },
  created() {
    this.getData();
    this.getunitType();
  },
  methods: {
    cancelTransferOrder() {
      this.$prompt("请输入取消原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({ value }) => {
        if (!value) {
          this.$message.warning("请输入取消原因");
          return;
        }
        cancelTransferOrder({
          transferOrderId: this.formData.transferOrderRecord.id,
          reason: value,
        }).then((res) => {
          this.$message.success("取消成功");
          this.getData();
        });
      });
    },
    sign() {
      signTransferOrderContract(this.formData.transferOrderRecord.id).then(
        (res) => {
          if (res.data.code == 200) {
            window.open(res.data.data);
          }
        }
      );
    },
    getunitType() {
      this.dicts.map((type) => {
        this.getDicts(type).then((res) => {
          this.dict[type] = {};
          res.data.data.map((item) => {
            this.dict[type][item.dictValue] = item.dictLabel;
          });
        });
      });
    },
    selectContract() {
      getTransferConfirmationFormContract({
        transferOrderId: this.formData.transferOrderRecord.id,
      }).then((res) => {
        window.open(res.data.data.viewpdfUrl);
      });
    },
    getData() {
      getTransferOrderDetail(this.$route.query.id).then((res) => {
        this.formData = res.data.data;
        this.isTransfer =
          this.userData.data.businessUserId ==
          this.formData.transferOrderRecord.transferorId;
      });
    },
  },
  mounted() {
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.bannerHeight = (600 / 1550) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (600 / 1550) * this.screenWidth;
    };
  },
};
</script>
<style scoped>
.box-btns-top {
  margin-top: 40px;
}

.box-img-list {
  width: 598px;
  height: 280px;
  background: #ffffff;
  /* border: 1px solid #BCBCBC; */
  margin: 0 auto;
}

.box-picture-list {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.box-picture {
  width: 250px;
  height: 250px;
  margin-bottom: 30px;
}

.box-picture img {
  width: 100%;
  height: 100%;
}

.box-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-btns-warning {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background: #ff9000;
  box-sizing: border-box;
  margin-left: 30px;
}

.box-btns-emg {
  width: 150px;
  height: 52px;
  line-height: 50px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #40b8ad;
  background: #ffffff;
  border: 1px solid #40b8ad;
  box-sizing: border-box;
}

.box-btns-primary {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background: #40b8ad;
  margin-left: 30px;
}

.box-tips-text {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #40b8ad;
  text-align: left;
  margin-bottom: 30px;
}

.box-types {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #40b8ad;
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -12px;
}

.box-details-number {
  width: 670px;
}

.box-tecxt {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-bottom: 10px;
  text-align: left;
}

.box-details-content {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.box-number {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  text-align: left;
}

.box-voucher {
  flex: 1;
  margin-left: 200px;
}

.box-voucher span {
  float: left;
}

.box-voucher img {
  float: left;
  width: 146px;
  height: 146px;
}

.box-details-text {
  margin-top: 50px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 48px;
}

.box-top {
  margin-top: 20px;
}

.box-details-session {
  /* padding: 40px 45px; */
  /* background: #F0F0F0; */
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
}

.details-address {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

/* 
	.details-address:after {
		content: ' ';
		flex: auto;
	} */

/* 
	.details-address span {
		flex: 1;
	} */
.details-address span {
  line-height: 18px;
}

.li-top {
  margin: 60px 0 40px 0;
}

.box-card-title {
  text-align: center;
  height: 42px;
  line-height: 42px;
  font-size: 42px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231714;
  margin-top: 60px;
  margin-bottom: 45px;
}

.box-addBtn {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  background: #40b8ad;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
  cursor: pointer;
}

.box-table {
  margin-top: 30px;
}

.edit-content {
  position: absolute;
  right: 30px;
  display: flex;
  top: 30px;
}

.re-certification {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #40b8ad;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-left: 20px;
  cursor: pointer;
}

.personal-edit {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #ffffff;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #40b8ad;
  cursor: pointer;
}

.personal-phone {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 16px;
  text-align: left;
}

.personal-name {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  margin-top: 16px;
  text-align: left;
}

.personal-corporation {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 72px;
  text-align: left;
}

.personal-corporation span {
  margin-left: 20px;
  padding: 4px 6px;
  background: #ffffff;
  border: 1px solid #ff9000;
  border-radius: 5px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ff9000;
}

.personal-text {
  margin-left: 30px;
}

.box-personal-session {
  float: left;
  margin-left: 50px;
  height: 100%;
  display: flex;
}

.block {
  margin-top: 52px;
}

.box-personal {
  height: auto;
  background: #f6f6f6;
  margin: 60px 0;
  padding: 30px;
  position: relative;
}

.box-tabs-name {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  height: 90px;
  line-height: 90px;
  float: left;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-tabs__header {
  margin: 0;
}

/deep/ .el-tabs__content {
  display: none;
}

/deep/ .el-tabs__item {
  height: 90px;
  line-height: 90px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

/deep/ .el-tabs {
  float: right;
}

.box-tabs {
  height: 90px;
  background: #f6f6f6;
}

.box-select-submit {
  display: block;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 100px;
  width: 160px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 24px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

.box-select-submit:hover {
  opacity: 0.8;
}

.card-address {
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  text-align: left;
  margin-top: 10px;
}

.card-title {
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  text-align: left;
}

.card-img {
  width: 100%;
  height: 246px;
}

.box-card {
  width: 380px;
  height: 340px;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-bottom: 30px;
  transition: all 0.3s;
  /* 上浮这个过程需要的时间 */
  cursor: pointer;
}

.box-card:hover {
  box-shadow: 0px 0px 10px 0px rgba(65, 181, 172, 0.35);
  transform: translate(0, -3px);
}

.box-card-content {
  padding: 20px;
  height: 54px;
}

.box-tab-card {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
}

.borderShare {
  border-right: 1px dashed #eeeeee;
}

.box-tab {
  text-align: center;
  width: 25%;
  box-sizing: border-box;
  cursor: pointer;
}

.box-tab span {
  display: block;
  text-align: center;
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  margin-top: 10px;
}

.box-tab img {
  display: block;
  width: 92px;
  margin: 0 auto;
  margin-top: 13px;
}

.box-fixed-tab {
  position: relative;
  margin: 0 auto;
  margin-top: -88px;
  width: 1200px;
  height: 150px;
  padding: 13px 0;
  background: #ffffff;
  box-shadow: 0px -6px 24px 0px rgba(65, 181, 172, 0.35);
  z-index: 99;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.carouselImg {
  width: 100%;
  height: inherit;
}

.scrollImg {
  width: 100%;
  height: auto;
  /* max-height: 780px; */
  position: relative;
}

/deep/ .el-carousel--horizontal {
  max-height: 780px;
}

/deep/ .el-carousel__container {
  max-height: 780px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.demo {
  display: inline-block;
  width: 600px;
  height: 338px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
</style>
