<template>
  <div class="all">
    <headerPage v-if="show"></headerPage>
    <transition name="el-fade-in">
      <router-view></router-view>
    </transition>
    <footerPage v-if="show"></footerPage>
  </div>
</template>

<script>
import headerPage from "@/components/headerPage.vue";
import footerPage from "@/components/footerPage.vue";
export default {
  name: "IndexPage",
  components: {
    headerPage,
    footerPage,
  },
  data() {
    return {
      isRouterAlive: true,
      show: true,
    };
  },
  mounted() {
    // if (this.$route.path == '/loginPage'||this.$route.path == '/findpassPage'||this.$route.path == '/registerPage'||this.$route.path == '/authenticationPage') {
    // 	this.show = false;
    // } else {
    // 	this.show = true;
    // }
  },
};
</script>

<style scoped>
.all {
  width: 100%;
  height: 100%;
}
</style>
