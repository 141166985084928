<template>
  <div>
    <template>
      <div class="scrollImg">
        <img ref="image" class="carouselImg" src="../assets/huowuBg.png" />
      </div>
      <div class="box-tabs">
        <div class="box-content">
          <span class="box-tabs-name">我的货物</span>
        </div>
      </div>

      <!-- 我的货物 -->
      <transition name="el-fade-in">
        <div class="box-content">
          <el-form :model="queryParams" :inline="true" label-width="80px">
            <el-row>
              <el-form-item label="商品名称">
                <el-input v-model="queryParams.goodsName"></el-input>
              </el-form-item>
              <el-form-item label="仓库名称">
                <el-input v-model="queryParams.warehouseName"></el-input>
              </el-form-item>
              <el-form-item label="仓位名称">
                <el-input v-model="queryParams.warehouseBinName"> </el-input>
              </el-form-item>
              <el-form-item label="商品类型">
                <el-input v-model="queryParams.typeName"> </el-input>
              </el-form-item>
              <el-button icon="el-icon-search" @click="getData">搜索</el-button>
              <el-button icon="el-icon-refresh" @click="resetQuery"
                >重置</el-button
              >
            </el-row>
          </el-form>
          <div class="box-table">
            <el-table
              :data="tableData"
              style="width: 100%"
              :header-cell-style="{
                background: '#F2F2F2',
                'text-align': 'center',
              }"
              border
              width="80"
              height="550"
            >
              <el-table-column
                label="货物单号"
                align="center"
                prop="cargoNumber"
              >
              </el-table-column>
              <el-table-column label="商品品类" align="center" prop="typeName">
              </el-table-column>
              <el-table-column label="商品名称" align="center" prop="goodsName">
              </el-table-column>
              <el-table-column label="型号" align="center" prop="goodsModel">
              </el-table-column>
              <el-table-column label="总数量" align="center" prop="quantity">
                <template slot-scope="{ row }">
                  {{ row.quantity ? row.quantity : "--" }}
                </template>
              </el-table-column>
              <el-table-column label="总重量" align="center" prop="weight">
                <template slot-scope="{ row }">
                  {{ row.weight ? row.weight : "--" }}
                </template>
              </el-table-column>
              <el-table-column label="总净重" align="center" prop="netWeight">
                <template slot-scope="{ row }">
                  {{ row.netWeight ? row.netWeight : "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="入库要求"
                align="center"
                prop="warehousingRequirements"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.warehousingRequirements == "1"
                      ? "批次入库"
                      : "非批次入库"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="带托入库"
                align="center"
                prop="isWarehousingWithTray"
              >
                <template slot-scope="scope">
                  {{ scope.row.isWarehousingWithTray ? "是" : "否" }}
                </template>
              </el-table-column>
              <el-table-column label="仓库" align="center" prop="warehouseName">
              </el-table-column>
              <el-table-column
                label="仓位"
                align="center"
                prop="warehouseBinName"
              >
              </el-table-column>
              <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                  <div style="display: flex; justify-content: space-between">
                    <div class="box-select-btn" @click="lookMonitor(scope.row)">
                      查看监控
                    </div>
                    <div class="box-select-btn" @click="lookInfo(scope.row)">
                      查看详情
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- <div class="box-pagination">
            <paginationPage
              v-show="total > 0"
              :total="total"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="getData"
            />
          </div> -->
        </div>
      </transition>
    </template>
  </div>
</template>
<script>
import { getInventoryGoodsListGroupCargoNumber } from "@/api/business/goods.js";
export default {
  name: "CessNav",
  components: {},
  data() {
    return {
      sessiontext: "我的货物",
      bannerHeight: "",
      queryParams: {},
      tableData: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    lookMonitor(row) {
      this.$router.push({
        path: "/monitorPage",
        query: { cargoNumber: row.cargoNumber },
      });
    },
    resetQuery() {
      this.queryParams = {};
      this.getData()
    },
    lookInfo(row) {
      this.$router.push({
        path: "/goodsDetails",
        query: {
          id: row.cargoNumber,
        },
      });
    },
    getData() {
      getInventoryGoodsListGroupCargoNumber(this.queryParams).then((res) => {
        this.tableData = res.data.data;
      });
    },
  },
};
</script>
<style scoped>
.box-content {
  text-align: left;
  margin-top: 20px;
}
.box-addBtn {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  background: #40b8ad;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
  cursor: pointer;
}

.box-table {
  margin-top: 30px;
}

.edit-content {
  float: right;
  margin-right: 30px;
  display: flex;
  margin-top: 30px;
}

.re-certification {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #40b8ad;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-left: 20px;
  cursor: pointer;
}

.personal-edit {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #ffffff;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #40b8ad;
  cursor: pointer;
}

.personal-phone {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 16px;
  text-align: left;
}

.personal-name {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  margin-top: 16px;
  text-align: left;
}

.personal-corporation {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 72px;
  text-align: left;
}

.personal-corporation span {
  margin-left: 20px;
  padding: 4px 6px;
  background: #ffffff;
  border: 1px solid #ff9000;
  border-radius: 5px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ff9000;
}

.personal-text {
  margin-left: 30px;
}

.box-personal-session {
  float: left;
  margin-left: 50px;
  height: 100%;
  display: flex;
}

.block {
  margin-top: 52px;
}

.box-personal {
  height: 238px;
  background: #f6f6f6;
  margin: 60px 0;
  position: relative;
}

.box-tabs-name {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  height: 90px;
  line-height: 90px;
  float: left;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-tabs__header {
  margin: 0;
}

/deep/ .el-tabs__content {
  display: none;
}

/deep/ .el-tabs__item {
  height: 90px;
  line-height: 90px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

/deep/ .el-tabs {
  float: right;
}

.box-tabs {
  height: 90px;
  background: #f6f6f6;
}

.box-select-submit {
  display: block;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 100px;
  width: 160px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 24px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

.box-select-submit:hover {
  opacity: 0.8;
}

.card-address {
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  text-align: left;
  margin-top: 10px;
}

.card-title {
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  text-align: left;
}

.card-img {
  width: 100%;
  height: 246px;
}

.box-card {
  width: 380px;
  height: 340px;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-bottom: 30px;
  transition: all 0.3s;
  /* 上浮这个过程需要的时间 */
  cursor: pointer;
}

.box-card:hover {
  box-shadow: 0px 0px 10px 0px rgba(65, 181, 172, 0.35);
  transform: translate(0, -3px);
}

.box-card-content {
  padding: 20px;
  height: 54px;
}

.box-tab-card {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
}

.box-card-title {
  text-align: center;
  height: 42px;
  line-height: 42px;
  font-size: 42px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231714;
  margin-top: 167px;
}

.borderShare {
  border-right: 1px dashed #eeeeee;
}

.box-tab {
  text-align: center;
  width: 25%;
  box-sizing: border-box;
  cursor: pointer;
}

.box-tab span {
  display: block;
  text-align: center;
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  margin-top: 10px;
}

.box-tab img {
  display: block;
  width: 92px;
  margin: 0 auto;
  margin-top: 13px;
}

.box-fixed-tab {
  position: relative;
  margin: 0 auto;
  margin-top: -88px;
  width: 1200px;
  height: 150px;
  padding: 13px 0;
  background: #ffffff;
  box-shadow: 0px -6px 24px 0px rgba(65, 181, 172, 0.35);
  z-index: 99;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.carouselImg {
  width: 100%;
  height: inherit;
}

.scrollImg {
  width: 100%;
  height: auto;
  /* max-height: 780px; */
  position: relative;
}

/deep/ .el-carousel--horizontal {
  max-height: 780px;
}

/deep/ .el-carousel__container {
  max-height: 780px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.demo {
  display: inline-block;
  width: 600px;
  height: 338px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
.box-select-btn {
  padding: 0 5px;
}
</style>
