<template>
  <div id="app">
    <!--<headerPage v-if="show"></headerPage>
		<transition name="el-fade-in"> -->
    <router-view v-if="isRouterAlive"></router-view>
    <!--</transition>
		<footerPage v-if="show"></footerPage> -->
  </div>
</template>

<script>
// import headerPage from './components/headerPage.vue'
// import footerPage from './components/footerPage.vue'
import DevicePixelRatio from "./utils/devicePixelRatio";
export default {
  name: "App",
  components: {
    // headerPage,
    // footerPage
  },
  data() {
    return {
      isRouterAlive: true,
      show: true,
    };
  },
  created() {
    new DevicePixelRatio().init();
  },
  mounted() {
    if (
      this.$route.path == "/loginPage" ||
      this.$route.path == "/findpassPage" ||
      this.$route.path == "/registerPage" ||
      this.$route.path == "/authenticationPage"
    ) {
      this.show = false;
    } else {
      this.show = true;
    }
    this.$store.dispatch("getUserAddr");
  },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -o-appearance: none !important;
  -ms-appearance: none !important;
  appearance: none !important;
  margin: 0;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  -o-appearance: textfield;
  -ms-appearance: textfield;
  appearance: textfield;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #41b5ac !important;
  border-color: #41b5ac !important;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #41b5ac !important;
}

.box-btn-center {
  display: block;
  margin: 0 auto;
}

.el-table thead {
  color: #333333 !important;
}

.el-table--border {
  border: 1px solid #cccccc !important;
}
.l-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-table thead {
  border-right: 1px solid #cccccc !important;
}
.el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #cccccc !important;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #cccccc !important;
}

.box-all-btn {
  cursor: pointer;
  transition: all 0.3s;
}

.box-all-btn:hover {
  opacity: 0.8;
}

.box-select-btn {
  height: 38px;
  text-align: center;
  line-height: 38px;
  background: #40b8ad;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

.box-select-btn:hover {
  opacity: 0.8;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  overflow-y: auto;
  /* margin-top: 60px; */
}

html {
  overflow-y: hidden;
  overflow-x: hidden;
}

body {
  width: 100vw;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.box-content {
  width: 1200px;
  margin: 0 auto;
}

.box-pagination {
  margin: 30px 0 60px 0;
}

.el-pagination.is-background .el-pager .active {
  background-color: #41b5ac !important;
}

.el-tabs__item.is-active {
  color: #41b5ac !important;
}

.el-tabs__item:hover {
  color: #41b5ac !important;
}

.el-tabs__active-bar {
  background-color: #41b5ac !important;
}
</style>
