<template>
  <div class="taostBackground" v-show="taostShow">
    <div class="taost">
      <div class="taost-title">
        <span class="taost-tips">{{ formData.taostTips }}</span>
        <span class="taost-close" @click="taostShow = false"
          ><img src="../assets/taostClose.png" alt="" srcset=""
        /></span>
      </div>
      <div class="taost-content">
        <p>{{ formData.taostContent }}</p>
        <div class="taost-success" @click="submit">
          {{ formData.taostBtnName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "taostPage",
  components: {},
  props: {
    context: Object,
  },
  data() {
    return {
      taostShow: false,
      formData: {
        taostContent: "",
        taostBtnName: "",
        taostTips: "",
        path: "",
      },
    };
  },
  methods: {
    closeDialog() {
      this.taostShow = false;
    },
    showDialog() {
      this.taostShow = true;
    },
    submit() {
      if (this.formData.path && this.formData.pathType == "window") {
        window.open(this.formData.path);
        this.taostShow = false;
      }
      if (this.formData.path && this.formData.pathType == "router") {
        this.$router.push(this.formData.path);
        this.taostShow = false;
      } else {
        this.taostShow = false;
      }
    },
  },
  mounted() {
    if (this.context) {
      this.formData = this.context;
    }
  },
};
</script>

<style scoped>
.taost-success {
  width: 246px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 35px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  position: absolute;
  bottom: 65px;
  left: 50%;
  margin-left: -123px;
  cursor: pointer;
}

.taost-content {
  padding: 0 20px;
}

.taost-content p {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1fb496;
  text-align: center;
  margin-top: 130px;
  white-space: pre;
}

.taost-title {
  width: 100%;
  height: 80px;
  background: #f2f2f2;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.taost-tips {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  height: 80px;
  line-height: 80px;
  margin-left: 20px;
}

.taost-close {
  width: 34px;
  height: 34px;
  margin-top: 23px;
  margin-right: 20px;
  cursor: pointer;
}

.taost-close img {
  width: 34px;
  height: 34px;
}

.taostBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
}

.taost {
  position: absolute;
  top: 50%;
  margin-top: -240px;
  left: 50%;
  margin-left: -289px;
  width: 578px;
  height: 480px;
  background: #ffffff;
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
}
</style>
