<template>
  <div class="box-login-bg">
    <div class="box-login-center">
      <div class="box-login-left">
        <div class="box-login-titleImg">
          <img src="../assets/loginTips.png" />
        </div>
        <div class="box-login-img">
          <img src="../assets/loginPicture.png" />
        </div>
      </div>
      <div class="box-login-right">
        <el-tabs v-model="activeName">
          <el-tab-pane label="注册" name="1">
            <el-form
              :model="form"
              status-icon
              :rules="rules"
              ref="form"
              class="demo-ruleForm"
              style="margin-top: 50px"
            >
              <el-form-item prop="phone">
                <div class="box-login-input">
                  <span>
                    <img src="../assets/user.png" alt="" srcset="" /> </span
                  ><el-input
                    type="number"
                    placeholder="请输入11位手机号"
                    v-model="form.phone"
                    autocomplete="off"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="password">
                <div class="box-login-input">
                  <span>
                    <img src="../assets/pass.png" alt="" srcset="" /> </span
                  ><el-input
                    type="password"
                    placeholder="6 - 16位密码，区分大小写"
                    v-model="form.password"
                    autocomplete="off"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="newpassword">
                <div class="box-login-input">
                  <span>
                    <img src="../assets/pass.png" alt="" srcset="" /> </span
                  ><el-input
                    type="password"
                    placeholder="确认密码"
                    v-model="form.newpassword"
                    autocomplete="off"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="code">
                <div class="box-login-input">
                  <span>
                    <img src="../assets/pass.png" alt="" srcset="" /> </span
                  ><el-input
                    type="number"
                    placeholder="请输入验证码"
                    style="width: 340px"
                    v-model="form.code"
                    autocomplete="off"
                  ></el-input>
                  <div class="box-code box-all-btn" @click="getCode">
                    {{ codeText }}
                  </div>
                </div>
              </el-form-item>
              <el-form-item>
                <div class="box-login-input nones" style="margin-top: 20px">
                  <div>
                    <el-checkbox-group v-model="type" style="margin-left: 15px">
                      <el-checkbox label="勾选同意" name="type"
                        >勾选同意《<span
                          class="box-server"
                          @click="$router.push('/userAgreement')"
                          >用户服务协议</span
                        >》</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                  <!-- <div class="">
										忘记密码？
									</div> -->
                </div>
              </el-form-item>
              <el-form-item>
                <el-button
                  class="box-sub"
                  @click="registerSubmit"
                  v-loading.fullscreen.lock="loading"
                  >注册</el-button
                >
              </el-form-item>
              <div
                class="box-login-register box-all-btn"
                @click="$router.push('/loginPage')"
              >
                使用已有账户登录
              </div>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { registerPost, sendCode } from "../utils/api.js";
export default {
  name: "CessNav",
  components: {},
  data() {
    let checkPwd = (rule, value, callback) => {
      if (value.trim().length == 0) {
        callback(new Error("请输入确认密码"));
      } else if (value != this.form.password) {
        callback(new Error("两次密码不一致"));
      } else {
        callback();
      }
    };
    return {
      codeText: "获取验证码",
      type: [],
      activeName: "1",
      form: {
        code: "",
        password: "",
        newpassword: "",
        phone: "",
      },
      rules: {
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入原密码",
            trigger: "blur",
          },
        ],
        newpassword: [
          {
            validator: checkPwd,
            required: true,
            trigger: "blur",
          },
        ],
      },
      loading: false,
    };
  },
  methods: {
    getCode() {
      if (this.codeText != "获取验证码") return;
      sendCode(1, this.form.phone)
        .then((response) => {
          if (response.data.code == 200) {
            this.$message({
              message: "获取成功！",
              type: "success",
            });
            let time = 60;
            let inter = setInterval(() => {
              this.codeText = `${--time}秒后重试`;
              if (!time) {
                clearInterval(inter);
                this.codeText = "获取验证码";
              }
            }, 1000);
          }
        })
        .catch(() => {
          this.$message.error("获取失败！");
        });
    },
    registerSubmit() {
      if (this.type.length > 0) {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.loading = true;
            registerPost(this.form)
              .then((response) => {
                this.loading = false;
                if (response.data.code == 200) {
                  this.$message({
                    message: "注册成功！",
                    type: "success",
                  });
                  setTimeout(() => {
                    this.$router.push("/loginPage");
                  }, 3000);
                }
              })
              .catch(() => {
                this.$message.error("注册失败！");
              })
              .finally(() => {
                this.loading = false;
              });
          }
        });
      } else {
        this.$message({
          message: "请勾选同意用户服务协议！",
          type: "warning",
        });
      }
    },
    authenticationPage() {
      this.$router.push("/authenticationPage");
    },
  },
  mounted() {},
};
</script>

<style scoped>
.box-login-right /deep/ .el-form-item__error {
  left: 46px !important;
  top: 105% !important;
}

.box-server {
  color: #41b5ac;
  margin-left: 0px !important;
}

.box-code {
  width: 120px;
  height: 38px;
  line-height: 36px;
  text-align: center;
  border: 1px solid #41b5ac;
  box-sizing: border-box;
  font-size: 18px;
  /* padding: 10px 14px; */
  font-family: PingFang SC;
  font-weight: 500;
  color: #41b5ac;
  margin-top: 21px;
}

.box-login-register {
  /* width: 70px; */
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #41b5ac;
  line-height: 17px;
  text-align: right;
  margin-top: 40px;
}

.nones {
  border: none !important;
}

.box-sub {
  width: 450px;
  height: 62px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 31px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff !important;
  margin-top: 60px;
}

.box-sub:hover {
  color: #ffffff;
  opacity: 0.8;
}

.box-login-input span {
  width: 20px;
  height: 22px;
  margin-top: 27px;
  margin-left: 15px;
}

.box-login-input span img {
  width: 100%;
  height: 100%;
}

.box-login-right {
  width: 450px;
  margin-top: 17px;
}

.box-login-right /deep/ .el-form-item {
  margin-bottom: 0px !important;
}

.box-login-right /deep/ .el-tabs__nav-wrap::after {
  height: 0 !important;
}

.box-login-right /deep/ .el-tabs__item {
  font-size: 24px !important;
}

.box-login-right /deep/ .el-input__inner {
  border: none !important;
  margin-top: 20px;
}

.box-login-input {
  width: 450px;
  height: 70px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
}

.box-login-bg {
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/loginBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-login-center {
  width: 1200px;
  height: 700px;
  /* margin: 0 auto; */
  display: flex;
  justify-content: space-between;
}

.box-login-titleImg {
  width: 237px;
  height: 50px;
}

.box-login-titleImg img {
  width: 100%;
  height: 100%;
}

.box-login-img {
  width: 450px;
  height: 366px;
  margin-top: 117px;
}

.box-login-img img {
  width: 100%;
  height: 100%;
}
</style>
