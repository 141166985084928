import http from '../utils/http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
// let resquest = "http://192.168.0.21:8082"

// 获取仓库列表
export function getPageList(params) {
	return http.get('/business/warehouse/list', params)
}
//获取推荐仓库列表
export function getRecommendList(params) {
	return http.get('/business/warehouse/getRecommendList', params)
}
// 获取仓库详情
export function getDetails(id, data = {}) {
	return http.get('/business/warehouse/' + id, data)
}

// 获取仓库分类列表
export function getPageTypeList(id) {
	return http.get('/business/warehouseType/list')
}
//预约入库
export function postWarehousing(params) {
	return http.post('/business/warehousingOrder/reservationWarehousing', params)
}
// 查询商品分类集合
export function goodsTypeListAll(params) {
	return http.get('/business/goodsType/listAll', params)
}

// 查询商品分类集合
export function goodsListAll(params) {
	return http.get('/business/goods/listAll', params)
}

// 查询商品属性管理列表
export function goodsAttrListAll(params) {
	return http.get('/business/goodsAttr/list', params)
}

// 企业认证
export function customerAuth() {
	return http.post('business/customer/login/customerAuth')
}
// 入库认证
export function verifyCanStoredWarehousing() {
	return http.get('business/warehousingOrder/verifyCanStoredWarehousing')
}

export function upDataWarehousingOrder(params) {
	return http.post('/business/warehousingOrder/updateWarehousingOrder', params)
}

export function getTransportationDocuments(params) {
	return http.get('/business/warehousingOrder/getTransportationDocuments', params)
}

export function getBillOfLading(params) {
	return http.get('/business/warehousingOrder/getBillOfLading', params)
}

export function rejectPickupgoods(params) {
	return http.post('/business/warehousingOrder/rejectPickupgoods', params)
}

export function customerApprovedPickupgoods(warehousingOrderDriverId) {
	return http.post('/business/warehousingOrder/customerApprovedPickupgoods/' + warehousingOrderDriverId)
}
export function getWarehousingOrderConfirmation(warehousingOrderDriverId) {
	return http.post('/business/warehousingOrder/getWarehousingOrderConfirmation/' + warehousingOrderDriverId)
}
export function getCustomerUserMsgList(data) {
	return http.get('/baseMsg/getCustomerUserMsgList', data)
}
export function readBaseMsgInfo(id) {
	return http.post('/baseMsg/readBaseMsgInfo?idBaseMsgInfo=' + id)
}
export function getUnreadMessagesQuantity(id) {
	return http.get('/baseMsg/getUnreadMessagesQuantity')
}
export function readAllBaseMsgInfo(id) {
	return http.post('/baseMsg/readAllBaseMsgInfo')
}
