<template>
  <div>
    <template>
      <div class="scrollImg">
        <img ref="image" class="carouselImg" src="../assets/orderBg.png" />
      </div>
      <div class="box-tabs">
        <div class="box-content">
          <span class="box-tabs-name">我的订单</span>
        </div>
      </div>
      <div class="box-content">
        <div class="box-personal">
          <div class="box-details-number">
            <div class="box-number">单号：{{ formData.orderNum }}</div>
            <div class="box-details-content">
              <div class="box-content-left">
                <div class="box-tecxt">
                  转让方：{{ formData.transferorName }}
                </div>
                <div class="box-tecxt">仓库：{{ formData.warehouseName }}</div>
                <div class="box-tecxt">
                  发布时间：{{ formData.warehouseName }}
                </div>
                <div class="box-tecxt">单价(元)：{{ formData.unitPrice }}</div>
                <div class="box-tecxt">
                  冻结期仓储费：{{
                    formData.storageFeeSettlementMethodDuringFreezingPeriod ==
                    "1"
                      ? "由新货权人结算"
                      : "由旧货权人结算按"
                  }}
                </div>
              </div>
              <div class="box-content-right">
                <div class="box-tecxt">
                  转让方联系人：{{ formData.transferorName }}
                </div>
                <div class="box-tecxt">
                  发布时间：{{ formData.releaseTime }}
                </div>
                <!-- <div class="box-tecxt">回购数量：2022-12-15 12:00</div> -->
                <div class="box-tecxt">
                  回购天数：{{ formData.repurchaseDays }}
                </div>
              </div>
            </div>
          </div>
          <div class="box-types">
            <div>
              {{ dict.buy_back_state[formData.orderState] }}
            </div>
            <div v-if="formData.orderState == 9">
              剩余{{ formData.daysRemaining }}天
            </div>
            <div v-if="formData.orderState == 10 && !isTransferor">
              转让方申请赎回
            </div>
          </div>

          <div class="edit-content">
            <div
              class="re-certification"
              v-if="formData.orderState == '1' && !isTransferor"
              @click="confirm"
            >
              接收
            </div>
            <div
              class="re-certification"
              v-if="!isTransferor && formData.orderState == '2'"
              @click="receiverReject"
            >
              取消接收
            </div>
            <div
              class="re-certification"
              v-if="formData.orderState == '9' && isTransferor"
              @click="signRegulatoryAgreement"
            >
              签署解除监管协议
            </div>
            <div
              class="re-certification"
              v-if="formData.orderState == '10' && !isTransferor"
              @click="signRegulatoryAgreement"
            >
              签署解除监管协议
            </div>
            <template
              v-if="
                formData.storageFeeSettlementMethodDuringFreezingPeriod == 1
                  ? !isTransferor
                  : isTransferor
              "
            >
              <div
                v-if="formData.orderState == '8' || formData.orderState == '7'"
                class="re-certification"
                @click="$router.push('/billPage')"
              >
                查看账单并支付
              </div>
              <div
                class="re-certification"
                v-if="formData.orderState == '9'"
                @click="$router.push('/billPage')"
              >
                查看账单
              </div>
            </template>

            <div
              class="re-certification"
              v-if="formData.orderState == '4' || formData.orderState == '6'"
              @click="signRegulatoryAgreement"
            >
              {{
                formData.orderState == 4 ? " 签署监管协议" : "签署转让单协议"
              }}
            </div>
            <div
              class="re-certification"
              v-if="isTransferor && formData.orderState == '1'"
              @click="transferorReject"
            >
              取消回购
            </div>
          </div>
        </div>
      </div>
      <div class="box-content">
        <div class="box-card-title">详情</div>
        <titlePage :titleText="sessiontext" class=""></titlePage>
        <div class="box-table">
          <el-table
            :data="formData.goodsDetailRecordList"
            style="width: 100%"
            :header-cell-style="{
              background: '#F2F2F2',
              'text-align': 'center',
              color: '#333333',
            }"
            border
            width="80"
          >
            <el-table-column
              label="商品类型"
              align="center"
              prop="goodsTypeName"
            >
            </el-table-column>
            <el-table-column label="商品名称" align="center" prop="goodsName">
            </el-table-column>
            <el-table-column
              label="厂家"
              align="center"
              prop="goodsManufacturerName"
            >
            </el-table-column>
            <el-table-column label="型号" align="center" prop="goodsModelName">
            </el-table-column>
            <el-table-column label="规格" align="center" prop="goodsSpecName">
            </el-table-column>
            <el-table-column label="总数量" align="center" prop="totalQuantity">
            </el-table-column>
            <el-table-column label="总重量" align="center" prop="totalWeight">
            </el-table-column>
            <el-table-column label="净重" align="center" prop="netWeight">
            </el-table-column>
            <el-table-column
              label="入库要求"
              align="center"
              prop="warehousingRequirements"
            >
              <template slot-scope="scope">
                {{
                  scope.row.warehousingRequirements == "1"
                    ? "批次入库"
                    : "非批次入库"
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="带托入库"
              align="center"
              prop="isWarehousingWithTray"
            >
              <template slot-scope="scope">
                {{ scope.row.isWarehousingWithTray == "1" ? "是" : "否" }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <template v-if="formData.orderState != 90">
          <template v-if="formData.orderState >= '2'">
            <titlePage :titleText="contracttext" class="li-top"></titlePage>
            <div
              style="text-align: start; margin-bottom: 30px"
              v-if="formData.orderState == 2"
            >
              <el-button type="primary" @click="uploadFile">确认上传</el-button>
            </div>
            <div style="height: 150px">
              <ImageUpload
                ref="file"
                v-model="formData.contractFileList"
                @input="imgInput"
                :disabled="formData.orderState != 2"
              ></ImageUpload>
            </div>
          </template>
          <!-- 监管协议 -->
          <template v-if="formData.orderState >= '4'">
            <titlePage :titleText="agreement" class="li-top"></titlePage>
            <div class="box-details-session">
              <div class="details-address">
                <span
                  >转让方签订时间：{{
                    formData.regulatoryAgreementTransferorSigningTime
                      ? formData.regulatoryAgreementTransferorSigningTime
                      : "暂未签署"
                  }}</span
                >
                <span
                  >接收方签订时间：{{
                    formData.regulatoryAgreementReceiverSigningTime
                      ? formData.regulatoryAgreementReceiverSigningTime
                      : "暂未签署"
                  }}</span
                >
                <span
                  >商务签订时间：{{
                    formData.regulatoryAgreementBusinessManagerSigningTime
                      ? formData.regulatoryAgreementBusinessManagerSigningTime
                      : "暂未签署"
                  }}</span
                >
              </div>
            </div>
            <div class="box-btns box-btns-top">
              <div
                class="box-btns-emg box-all-btn"
                @click="getRegulatoryAgreement"
              >
                查看监管协议
              </div>
            </div>
            <!-- 转让单 -->
            <template v-if="formData.orderState > '5'">
              <titlePage :titleText="transference" class="li-top"></titlePage>
              <div class="box-details-session">
                <div class="details-address">
                  <span
                    >转让方签订时间：{{
                      formData.transferAgreementTransferorSigningTime
                        ? formData.transferAgreementTransferorSigningTime
                        : "暂未签署"
                    }}</span
                  >
                  <span
                    >接收方签订时间：{{
                      formData.transferAgreementReceiverSigningTime
                        ? formData.transferAgreementReceiverSigningTime
                        : "暂未签署"
                    }}</span
                  >
                  <span
                    >商务签订时间：{{
                      formData.transferAgreementBusinessManagerSigningTime
                        ? formData.transferAgreementBusinessManagerSigningTime
                        : "暂未签署"
                    }}</span
                  >
                </div>
              </div>
              <div class="box-btns box-btns-top">
                <div
                  class="box-btns-emg box-all-btn"
                  @click="getTransferContract"
                >
                  查看转让单协议
                </div>
              </div>
            </template>
          </template>

          <!-- 解除监管协议 -->
          <template
            v-if="formData.relieveRegulatoryAgreementBusinessManagerSigningTime"
          >
            <titlePage
              :titleText="secureTransference"
              class="li-top"
            ></titlePage>
            <div class="box-details-session">
              <div class="details-address">
                <span
                  >转让方签订时间：{{
                    formData.relieveRegulatoryAgreementTransferorSigningTime
                      ? formData.relieveRegulatoryAgreementTransferorSigningTime
                      : "暂未签署"
                  }}</span
                >
                <span
                  >接收方签订时间：{{
                    formData.relieveRegulatoryAgreementReceiverSigningTime
                      ? formData.relieveRegulatoryAgreementReceiverSigningTime
                      : "暂未签署"
                  }}</span
                >
                <span
                  >商务签订时间：{{
                    formData.relieveRegulatoryAgreementBusinessManagerSigningTime
                      ? formData.relieveRegulatoryAgreementBusinessManagerSigningTime
                      : "暂未签署"
                  }}</span
                >
              </div>
            </div>
            <div class="box-btns box-btns-top" style="margin-bottom: 60px">
              <div
                class="box-btns-emg box-all-btn"
                @click="getReleaseRegulatoryAgreements"
              >
                查看解除监管协议
              </div>
            </div>
          </template>
        </template>
      </div>
    </template>
    <dialogPage taostTips="运输轨迹" width="1000px" ref="trajectory">
      <template v-slot:content>
        <mapPage></mapPage>
      </template>
    </dialogPage>
  </div>
</template>
<script>
import {
  getBuyBackOrderDetail,
  transferorCancelBuyBackOrder,
  receiverCancelBuyBackOrder,
  receiverReceiving,
  uploadContract,
  transferorSendRelieveRegulatoryAgreement,
  transferorSignRegulatoryAgreement,
  getTransferContract,
  transferorSignTransferAgreement,
  getRegulatoryAgreement,
  receiverSignRelieveRegulatoryAgreement,
  getReleaseRegulatoryAgreements,
} from "@/api/business/buyBack.js";
export default {
  name: "CessNav",
  components: {},
  data() {
    return {
      dicts: ["buy_back_state"],
      dict: {
        buy_back_state: {},
      },
      formData: {},
      contractFileList: [],
      isTransferor: false,
      secureTransference: "解除监管协议",
      transference: "转让单",
      agreement: "监管协议",
      contracttext: "双方合同",
      sessiontext: "商品信息",
      takeAway: "提货信息",
      cartext: "车辆信息",
      activeName: null,
      bannerHeight: "",
      customerId: null,
    };
  },
  created() {
    this.getData();
    this.getunitType();
    const userData = JSON.parse(sessionStorage.getItem("userSession"));
    this.customerId = userData.customer.id;
  },
  methods: {
    getReleaseRegulatoryAgreements() {
      getReleaseRegulatoryAgreements(this.formData.id).then((res) => {
        window.open(res.data.viewpdfUrl);
      });
    },
    getTransferContract() {
      getTransferContract({
        buyBackOrderId: this.formData.id,
      }).then((res) => {
        window.open(res.data.data.viewpdfUrl);
      });
    },
    getRegulatoryAgreement() {
      getRegulatoryAgreement({
        buyBackOrderId: this.formData.id,
      }).then((res) => {
        window.open(res.data.data.viewpdfUrl);
      });
    },
    signRegulatoryAgreement() {
      if (this.formData.orderState == "4") {
        if (
          (this.isTransferor &&
            this.formData.regulatoryAgreementTransferorSigningTime) ||
          (!this.isTransferor &&
            this.formData.regulatoryAgreementReceiverSigningTime)
        ) {
          this.$message.warning("已签署监管协议不能重复签署");
          return;
        }
        transferorSignRegulatoryAgreement(this.formData.id).then((res) => {
          window.open(res.data.data);
        });
      } else if (this.formData.orderState == "6") {
        if (
          (this.isTransferor &&
            this.formData.relieveRegulatoryAgreementTransferorSignatureSeal) ||
          (!this.isTransferor &&
            this.formData.relieveRegulatoryAgreementReceiverSignatureSeal)
        ) {
          this.$message.warning("已签署转让单不能重复签署");
          return;
        }
        transferorSignTransferAgreement(this.formData.id).then((res) => {
          window.open(res.data.data);
        });
      } else if (this.formData.orderState == "9") {
        transferorSendRelieveRegulatoryAgreement(this.formData.id).then(
          (res) => {
            window.open(res.data.msg);
          }
        );
      } else if (this.formData.orderState == "10") {
        receiverSignRelieveRegulatoryAgreement(this.formData.id).then((res) => {
          window.open(res.data.data);
        });
      }
    },
    uploadFile() {
      this.$confirm("是否确认上传合同？", "提示", { type: "warning" }).then(
        () => {
          uploadContract({
            buyBackOrderId: this.formData.id,
            contractFileList: this.contractFileList.map((item) => {
              return {
                fileUrl: item.url,
                fileName: item.originalFilename,
              };
            }),
          }).then((res) => {
            this.$message.success("上传成功！");
            this.getData();
          });
        }
      );
    },
    imgInput() {
      this.contractFileList.push(
        this.$refs.file.fileList[this.$refs.file.fileList.length - 1]
      );
    },
    confirm() {
      this.$confirm("是否接收该回购单?", "提示", { type: "warning" }).then(
        () => {
          receiverReceiving(this.formData.id).then((res) => {
            this.$message.success("接收成功！");
            this.getData();
          });
        }
      );
    },
    receiverReject() {
      this.$confirm("是否取消接收？", "提示", { type: "warning" }).then(() => {
        receiverCancelBuyBackOrder(this.formData.id).then((res) => {
          this.$message.success("取消成功!");
          this.getData();
        });
      });
    },
    transferorReject() {
      this.$confirm("是否取消回购？", "提示", { type: "warning" }).then(() => {
        transferorCancelBuyBackOrder(this.formData.id).then((res) => {
          this.$message.success("取消成功!");
          this.getData();
        });
      });
    },
    getunitType() {
      this.dicts.map((type) => {
        this.getDicts(type).then((res) => {
          this.dict[type] = {};
          res.data.data.map((item) => {
            this.dict[type][item.dictValue] = item.dictLabel;
          });
        });
      });
    },
    getData() {
      getBuyBackOrderDetail(this.$route.query.id).then((res) => {
        console.log(res);
        this.formData = res.data.data;
        this.isTransferor = this.formData.transferor.id == this.customerId;
        this.formData.contractFileList = this.formData.contractFileList
          .map((item) => item.fileUrl)
          .toString();
      });
    },
  },
  mounted() {
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.bannerHeight = (600 / 1550) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (600 / 1550) * this.screenWidth;
    };
  },
};
</script>
<style scoped>
.box-btns-top {
  margin-top: 40px;
}

.box-img-list {
  width: 598px;
  height: 280px;
  background: #ffffff;
  /* border: 1px solid #BCBCBC; */
  margin: 0 auto;
}

.box-picture-list {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.box-picture {
  width: 250px;
  height: 250px;
  margin-bottom: 30px;
}

.box-picture img {
  width: 100%;
  height: 100%;
}

.box-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-btns-warning {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background: #ff9000;
  box-sizing: border-box;
  margin-left: 30px;
}

.box-btns-emg {
  height: 52px;
  line-height: 50px;
  padding: 0 10px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #40b8ad;
  background: #ffffff;
  border: 1px solid #40b8ad;
  box-sizing: border-box;
}

.box-btns-primary {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background: #40b8ad;
  margin-left: 30px;
}

.box-tips-text {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #40b8ad;
  text-align: left;
  margin-bottom: 30px;
}

.box-types {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #40b8ad;
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -12px;
}

.box-details-number {
  width: 670px;
}

.box-tecxt {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-bottom: 10px;
  text-align: left;
}

.box-details-content {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.box-number {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  text-align: left;
}

.box-voucher {
  flex: 1;
  margin-left: 200px;
}

.box-voucher span {
  float: left;
}

.box-voucher img {
  float: left;
  width: 146px;
  height: 146px;
}

.box-details-text {
  margin-top: 50px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 48px;
}

.box-top {
  margin-top: 20px;
}

.box-details-session {
  /* padding: 40px 45px; */
  /* background: #F0F0F0; */
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
}

.details-address {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

/* 
	.details-address:after {
		content: ' ';
		flex: auto;
	} */

/* 
	.details-address span {
		flex: 1;
	} */
.details-address span {
  line-height: 18px;
}

.li-top {
  margin: 60px 0 40px 0;
}

.box-card-title {
  text-align: center;
  height: 42px;
  line-height: 42px;
  font-size: 42px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231714;
  margin-top: 60px;
  margin-bottom: 45px;
}

.box-addBtn {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  background: #40b8ad;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
  cursor: pointer;
}

.box-table {
  margin-top: 30px;
}

.edit-content {
  position: absolute;
  right: 30px;
  display: flex;
  top: 30px;
}

.re-certification {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #40b8ad;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-left: 20px;
  cursor: pointer;
}

.personal-edit {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #ffffff;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #40b8ad;
  cursor: pointer;
}

.personal-phone {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 16px;
  text-align: left;
}

.personal-name {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  margin-top: 16px;
  text-align: left;
}

.personal-corporation {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 72px;
  text-align: left;
}

.personal-corporation span {
  margin-left: 20px;
  padding: 4px 6px;
  background: #ffffff;
  border: 1px solid #ff9000;
  border-radius: 5px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ff9000;
}

.personal-text {
  margin-left: 30px;
}

.box-personal-session {
  float: left;
  margin-left: 50px;
  height: 100%;
  display: flex;
}

.block {
  margin-top: 52px;
}

.box-personal {
  height: auto;
  background: #f6f6f6;
  margin: 60px 0;
  padding: 30px;
  position: relative;
}

.box-tabs-name {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  height: 90px;
  line-height: 90px;
  float: left;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-tabs__header {
  margin: 0;
}

/deep/ .el-tabs__content {
  display: none;
}

/deep/ .el-tabs__item {
  height: 90px;
  line-height: 90px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

/deep/ .el-tabs {
  float: right;
}

.box-tabs {
  height: 90px;
  background: #f6f6f6;
}

.box-select-submit {
  display: block;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 100px;
  width: 160px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 24px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

.box-select-submit:hover {
  opacity: 0.8;
}

.card-address {
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  text-align: left;
  margin-top: 10px;
}

.card-title {
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  text-align: left;
}

.card-img {
  width: 100%;
  height: 246px;
}

.box-card {
  width: 380px;
  height: 340px;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-bottom: 30px;
  transition: all 0.3s;
  /* 上浮这个过程需要的时间 */
  cursor: pointer;
}

.box-card:hover {
  box-shadow: 0px 0px 10px 0px rgba(65, 181, 172, 0.35);
  transform: translate(0, -3px);
}

.box-card-content {
  padding: 20px;
  height: 54px;
}

.box-tab-card {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
}

.borderShare {
  border-right: 1px dashed #eeeeee;
}

.box-tab {
  text-align: center;
  width: 25%;
  box-sizing: border-box;
  cursor: pointer;
}

.box-tab span {
  display: block;
  text-align: center;
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  margin-top: 10px;
}

.box-tab img {
  display: block;
  width: 92px;
  margin: 0 auto;
  margin-top: 13px;
}

.box-fixed-tab {
  position: relative;
  margin: 0 auto;
  margin-top: -88px;
  width: 1200px;
  height: 150px;
  padding: 13px 0;
  background: #ffffff;
  box-shadow: 0px -6px 24px 0px rgba(65, 181, 172, 0.35);
  z-index: 99;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.carouselImg {
  width: 100%;
  height: inherit;
}

.scrollImg {
  width: 100%;
  height: auto;
  /* max-height: 780px; */
  position: relative;
}

/deep/ .el-carousel--horizontal {
  max-height: 780px;
}

/deep/ .el-carousel__container {
  max-height: 780px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.demo {
  display: inline-block;
  width: 600px;
  height: 338px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
</style>
