const times = function(data) {
	if (parseInt(data) < 10) {
		data = '0' + data;
	}
	return data;
}
const ishttp = function(url, nurl) {
	if (isNall(url) == false) {
		return nurl
	} else {
		if (url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1) {
			return url;
		} else {
			return nurl
		}

	}
}
//动态倒计时
const showTime = function(time) {
	let nowTime = new Date();
	let setTime = new Date(time);
	if ((setTime.getTime() - nowTime.getTime()) <= 0) {
		let str = `已结束`
		return str;
	} else {
		let remianAllSeconds = Math.floor((setTime.getTime() - nowTime.getTime()) / 1000);
		let remainDays = Math.floor(remianAllSeconds / (60 * 60 * 24));
		let remainHours = Math.floor(remianAllSeconds / (60 * 60) % 24);
		let remainMinutes = Math.floor(remianAllSeconds / 60 % 60);
		let remainSeconds = Math.floor(remianAllSeconds % 60);
		let str = `${remainDays}天${remainHours}小时${remainMinutes}分钟${remainSeconds}秒`
		return str;
	}
}
//动态时间
const get_time = function() {
	var date = new Date();
	var year = date.getFullYear();
	var month = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1);
	var day = (date.getDate() < 10 ? "0" + (date.getDate()) : date.getDate());
	// var week = date.getDay();
	// var weekStr = "未知";
	var hours = (date.getHours() < 10 ? "0" + (date.getHours()) : date.getHours());
	var minutes = (date.getMinutes() < 10 ? "0" + (date.getMinutes()) : date.getMinutes());
	var seconds = (date.getSeconds() < 10 ? "0" + (date.getSeconds()) : date.getSeconds());
	// switch (week) {
	// 	case 1:
	// 		weekStr = "一 "
	// 		break
	// 	case 2:
	// 		weekStr = "二 "
	// 		break
	// 	case 3:
	// 		weekStr = "三 "
	// 		break
	// 	case 4:
	// 		weekStr = "四 "
	// 		break
	// 	case 5:
	// 		weekStr = "五 "
	// 		break;
	// 	case 6:
	// 		weekStr = "六 "
	// 		break
	// 	default:
	// 		weekStr = "日 ";
	// }

	return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
}
const letP = function(text) {
	let newText = text.replace(/<.*?>/g, "");
	return newText;
}
//深拷贝封装
const deepCopy = function(data) {
	//string,number,bool,null,undefined,symbol
	//object,array,date
	if (data && typeof data === "object") {
		//针对函数的拷贝
		if (typeof data === "function") {
			let tempFunc = data.bind(null);
			tempFunc.prototype = deepCopy(data.prototype);
			return tempFunc;
		}

		switch (Object.prototype.toString.call(data)) {
			case "[object String]":
				return data.toString();
			case "[object Number]":
				return Number(data.toString());
			case "[object Boolean]":
				return new Boolean(data.toString());
			case "[object Date]":
				return new Date(data.getTime());
			case "[object Array]":
				var arr = [];
				for (let i = 0; i < data.length; i++) {
					arr[i] = deepCopy(data[i]);
				}
				return arr;

				//js自带对象或用户自定义类实例
			case "[object Object]":
				var obj = {};
				for (let key in data) {
					//会遍历原型链上的属性方法，可以用hasOwnProperty来控制 （obj.hasOwnProperty(prop)
					obj[key] = deepCopy(data[key]);
				}
				return obj;
		}
	} else {
		//string,number,bool,null,undefined,symbol
		return data;
	}
}
//数组分割
const group = function(array, subGroupLength) {
	let index = 0;
	let newArray = [];
	while (index < array.length) {
		newArray.push(array.slice(index, index += subGroupLength));
	}
	return newArray;
}


//获取两个月份之间的所有月份
const getYearAndMonth = function(start, end) {
	let result = [start];
	let starts = start.split('-');
	let ends = end.split('-');
	let staYear = parseInt(starts[0]);
	let staMon = parseInt(starts[1]);
	let endYear = parseInt(ends[0]);
	let endMon = parseInt(ends[1]);
	while (staYear <= endYear) {
		if (staYear === endYear) {
			while (staMon < endMon) {
				staMon++;
				var str = staYear + '-' + (staMon >= 10 ? staMon : '0' + staMon);
				result.push(str);
			}
			staYear++;
		} else {
			staMon++;
			if (staMon > 12) {
				staMon = 1;
				staYear++;
			}
			let str = staYear + '-' + (staMon >= 10 ? staMon : '0' + staMon);
			result.push(str);
		}
	}
	return result;
}
//获取当前月份前12个月
const newmouth = function() {
	var dataArr = [];
	var data = new Date();
	// var year = data.getFullYear();
	data.setMonth(data.getMonth() + 1, 1) //获取到当前月份,设置月份
	for (var i = 0; i < 12; i++) {
		data.setMonth(data.getMonth() - 1); //每次循环一次 月份值减1
		var m = data.getMonth() + 1;
		m = m < 10 ? "0" + m : m;
		dataArr.push(data.getFullYear() + "-" + (m))
	}
	return dataArr
}

/**
 * 获取当前年份的第一天和最后一天
 * returns {string} 例如 2019-01-01~2019-12-31
 */

const getYearFirstLastDay = function(years) {
	let firstDay = new Date(years);
	// firstDay.setFullYear(firstDay.getFullYear());
	firstDay.setDate(1);
	firstDay.setMonth(0);
	let lastDay = new Date(years);
	lastDay.setFullYear(lastDay.getFullYear() + 2);
	lastDay.setDate(0);
	lastDay.setMonth(-1);
	firstDay = firstDay.Format("yyyy-MM-dd");
	lastDay = lastDay.Format("yyyy-MM-dd");
	// Bus.$emit('dataYear', firstDay + "," + lastDay)
	let timestamp = Date.parse(new Date(firstDay));
	let timestamp1 = Date.parse(new Date(lastDay));
	let timeNew = new Date(timestamp);
	let timeNew1 = new Date(timestamp1);
	return [timeNew, timeNew1];
}
//指定日期加一天
const isdate = function(e) {
	let stringTime = e + ' 00:00:00';
	let timestamp = Date.parse(new Date(stringTime));

	let timeNew = new Date((timestamp / 1000 + 86400) * 1000) //后一天
	let timeNew1 = new Date(timestamp) //当天
	return [timeNew1, timeNew]

}
//获取当年所有月份
const getyearMouth = function(e) {
	let mouth = [];
	for (let i = 0; i < 13; i++) {
		if (i < 10) {
			i = '0' + i;
		}
		mouth.push(e + '-' + i)
	}
	return mouth

}

//日期转换格式yy-mm-dd
const dateNew = function(data, type) {
	let yy = new Date(data).getFullYear();
	let mm = times(new Date(data).getMonth() + 1);
	let dd = times(new Date(data).getDate());
	let hh = times(new Date(data).getHours());
	let mf = times(new Date(data).getMinutes());
	let ss = times(new Date(data).getSeconds());
	if (type == 'YY-MM-DD') {
		return yy + '-' + mm + '-' + dd;
	} else if (type == 'YY-MM') {
		return yy + '-' + mm;
	} else if (type == 'YY-MM-DD hh:mm:ss') {
		return yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
	}
}

//获取当前日期到之前多少天日期的值例：2022-09-01之前15天的日期数组
const newBeforeDate = function(ns) {
	let n = ns;
	let d = new Date();
	let year = d.getFullYear();
	let mon = d.getMonth() + 1;
	let day = d.getDate();
	let endTime = year + '-' + mon + '-' + day;
	if (day <= n) {
		if (mon > 1) {
			mon = mon - 1;
		} else {
			year = year - 1;
			mon = 12;
		}
	}
	d.setDate(d.getDate() - n);
	year = d.getFullYear();
	mon = d.getMonth() + 1;
	day = d.getDate();
	let startTime = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);
	//初始化日期列表，数组
	var arr = [];
	var ab = startTime.split("-");
	var ae = endTime.split("-");
	var db = new Date();
	db.setUTCFullYear(ab[0], ab[1] - 1, ab[2]);
	var de = new Date();
	de.setUTCFullYear(ae[0], ae[1] - 1, ae[2]);
	var unixDb = db.getTime() - 24 * 60 * 60 * 1000;
	var unixDe = de.getTime() - 24 * 60 * 60 * 1000;
	for (var k = unixDb; k <= unixDe;) {
		k = k + 24 * 60 * 60 * 1000;
		arr.push(dateNew((new Date(parseInt(k))), 'YY-MM-DD'));
	}
	return arr;

};
//邮箱验证
const checkEmail = function(rule, value, callback) {
	const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/;
	if (regEmail.test(value)) {
		return callback();
	}
	callback("邮箱格式不合法！");
};
const validatePass = (rule, value, callback, that) => {

	if (value === '') {
		callback(new Error('请输入密码！'));
	} else if (value.length < 6) {
		callback(new Error('请输入6-16位字符！'));
	} else {
		if (that.ruleForm.checkPass !== '') {
			that.$refs.ruleForm.validateField('checkPass');
		}
		callback();
	}
};
const validatePass2 = (rule, value, callback, that) => {
	if (value === '') {
		callback(new Error('请再次输入密码！'));
	} else if (value !== that.ruleForm.npassword) {
		callback(new Error('两次输入密码不一致！'));
	} else {
		callback();
	}
};
const nameCode = function(rule, value, callback) {
	if (value === '') {
		callback(new Error('请输入用户名'));
	} else if (value !== 'admin') {
		callback(new Error('用户名错误!'));
	} else {
		callback();
	}
};
const Token = function() {
	return sessionStorage.getItem("Token");
}
const isNall = function(e) {
	if (e != '' && e != null && e != undefined && e != 'null' && e != 'undefined') {
		return true;
	} else {
		return false;
	}
}
const opens = function(title, text, that) {
	that.$alert(title, text, {
		dangerouslyUseHTMLString: true,
		showClose: false,
		type: 'warning'
	}).then(() => {
		that.Vuexcs.store.commit('leout', 0);

	}).catch(() => {
		// this.$message({
		// 	type: 'info',
		// 	message: '已取消删除'
		// });
	});
}
export default {
	dateNew,
	checkEmail,
	nameCode,
	Token,
	isNall,
	validatePass,
	validatePass2,
	newBeforeDate,
	opens,
	ishttp,
	isdate,
	getYearFirstLastDay,
	group,
	letP,
	getyearMouth,
	getYearAndMonth,
	newmouth,
	get_time,
	showTime,
	deepCopy,
	times
}