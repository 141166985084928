import Vue from 'vue'
import Vuex from 'vuex'
import AMapLoader from "@amap/amap-jsapi-loader";
import { isCustomerSignLogisticsContract } from "@/api/business/warehousingOrder"
window._AMapSecurityConfig = {
	securityJsCode: "39e49fea999fd000a85d82cc180d5257", //在这里填写你的安全密钥
};
import {
	getUnreadMessagesQuantity
} from "@/api/warehouse"

Vue.use(Vuex);
let store = new Vuex.Store({
	// 1. state
	state: {
		userSession: JSON.parse(sessionStorage.getItem("userSession")) || '',
		// 用户精确位置
		accurateAddress: {
			lng: null,
			lat: null,
			addr: null
		},
		Amap: null,
		messageTotal: 0
	},
	getters: {
		getUserSession(state) {
			return state.userSession;
		},
		accurateAddress(state) {
			return state.accurateAddress
		},
		getAmap(state) {
			return state.Amap
		}
	},
	// 3. actions
	// 通常跟api接口打交道
	// 4. mutations
	mutations: {
		setUserSession(state, data) {
			state.userSession = data;
			sessionStorage.setItem("userSession", JSON.stringify(data))
		},
		SET_ACCURATE_ADDRESS(state, data) {
			state.accurateAddress = data
		},
		SET_MAP(state, data) {
			state.Amap = data
		},
		SET_MESSAGE_TOTAL(state, data) {
			state.messageTotal = data
		},
	},
	actions: {
		async getMessageTotal({
			commit
		}) {
			let res = await getUnreadMessagesQuantity()
			if (res.data) {
				commit('SET_MESSAGE_TOTAL', res.data.data)
			}
		},
		getUserAddr(context) {
			AMapLoader.load({
				key: "8117c8d10c281053e3831bdb5fb05af1", // 申请好的Web端开发者Key，首次调用 load 时必填
				version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
				plugins: [
					"AMap.Geolocation",
					"AMap.Geocoder",
					"AMap.CitySearch",
					"AMap.ToolBar",
					"AMap.Scale",
					"AMap.HawkEye",
					"AMap.MapType",
					"AMap.AutoComplete",
					"AMap.PlaceSearch",
					"AMap.MoveAnimation"
				], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
			}).then(AMap => {
				context.commit('SET_MAP', AMap)
				let citySearch = new AMap.CitySearch();
				// ip定位
				citySearch.getLocalCity(function (status, result) {
					if (status === "complete" && result.info === "OK") {
						let geolocation = new AMap.Geolocation();
						geolocation.getCurrentPosition(function (status, result1) {
							if (status == "complete") {
								context.commit('SET_ACCURATE_ADDRESS', {
									lng: result1.position.lng,
									lat: result1.position.lat,
									city: result.city
								})
							} else {
								let geocoder = new AMap.Geocoder();
								geocoder.getLocation(result.city, (params, result2) => {
									context.commit('SET_ACCURATE_ADDRESS', {
										lng: result2.geocodes[0].location.lng,
										lat: result2.geocodes[0].location.lat,
										city: result.city
									})
								});
							}
						});
					}
				});

			})
		}
	},

});

export default store;