<template>
  <div>
    <div class="headers">
      <div class="box-content">
        <img src="../assets/logo.png" class="logo" alt="" srcset="" />
        <!-- <div class="nav"> -->
        <ul class="nav">
          <li
            :class="router == item.path ? 'active' : ''"
            v-for="(item, index) in tabData"
            :key="index"
            @click="setActive(item.path)"
          >
            {{ item.name }}
          </li>
        </ul>
        <div class="box-bell">
          <span class="bell" @click="message">
            <el-badge :value="$store.state.messageTotal" class="item" :hidden="!$store.state.messageTotal">
              <img src="../assets/bell.png" alt="" srcset="" />
            </el-badge>
          </span>
          <span class="head"
            ><img
              @click="userInfo"
              :src="
                getUserSession.customer.avatar
                  ? getUserSession.customer.avatar
                  : require('../assets/head.png')
              "
              alt=""
              srcset=""
          /></span>
          <span class="name">{{
            getUserSession.customer
              ? getUserSession.customer.orgName
              : "未认证企业"
          }}</span>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "headerPage",
  components: {},
  data() {
    return {
      number: 999,
      bannerHeight: "",
      router: null,
      tabData: [
        {
          name: "首页",
          path: "/",
        },
        {
          name: "仓库",
          path: "/storehousePage",
        },
        {
          name: "回购",
          path: "/byBackList",
        },
        {
          name: "预约入库",
          path: "/agreeonWarehousing",
        },
        {
          name: "个人中心",
          path: "/personalCenter",
        },
      ],
      setIndex: 0,
      userSession: {},
    };
  },

  computed: {
    ...mapGetters(["getUserSession"]),
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (val) {
        this.router = val.path;
      },
    },
  },
  created() {
    this.$store.dispatch("getMessageTotal");
  },
  methods: {
    setActive(path) {
      this.$router.push(path);
    },
    message() {
      this.$router.push("/waitingPage");
    },
    userInfo() {
      this.$router.push("/personalCenter");
    },
  },
  mounted() {
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.bannerHeight = (600 / 1550) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (600 / 1550) * this.screenWidth;
    };
  },
};
</script>

<style scoped>
.box-bell {
  height: 100px;
  display: flex;
  align-items: center;
  float: right;
}

.box-bell img {
  width: 100%;
  height: 100%;
}

.name {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #ffffff;
  margin-left: 10px;
  cursor: pointer;
}

.bell {
  width: 28px;
  height: 32px;
  cursor: pointer;
}

.head {
  width: 34px;
  height: 34px;
  margin-left: 20px;
  cursor: pointer;
}

.nav {
  float: left;
  margin: 0;
  padding: 0;
  margin-left: 120px;
  list-style: none;
  display: flex;
  height: 100px;
}

.nav li {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  height: 100px;
  line-height: 100px;
  padding: 0 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.nav li.active {
  background: #41b5ac;
  transform: translate(0, 0);
}

/* .nav li:hover{
		background: #41B5AC;
	} */
.headers {
  width: 100%;
  height: 100px;
  background: rgba(0, 0, 0, 0.6);
  /* opacity: 0.6; */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.logo {
  width: 240px;
  height: 58px;
  float: left;
  margin-top: 21px;
}
</style>
