import Vue from 'vue';
import Router from 'vue-router';
import TaostPage from "@/components/taostPage"
import {
	verifyCanStoredWarehousing,
	customerAuth
} from "@/api/warehouse.js";
import { getSystemConfiguration } from "@/api/business/systemConfiguration"

import readingLogin from './views/readingLogin.vue';
import HomePage from './views/HomePage.vue';

import storehousePage from './views/storehousePage.vue';
import storehouseDetails from './views/storehouseDetails.vue';

import agreeonWarehousing from './views/agreeonWarehousing.vue';
import personalCenter from './views/personalCenter.vue';
import modifyPage from './views/modifyPage.vue';
import waitingPage from './views/waitingPage.vue';

import orderPage from './views/orderPage.vue';
import orderDetailsPage from './views/orderDetailsPage.vue';
import orderOutDetailsPage from './views/orderOutDetailsPage.vue';

import byBackPage from './views/byBackPage.vue';
import byBackDetails from './views/byBackDetails.vue';
import byBackList from './views/byBackList.vue';

import transferencePage from './views/transferencePage.vue';
import transferenceDetails from './views/transferenceDetails.vue';

import contractPage from './views/contractPage.vue';
import contractDetails from './views/contractDetails.vue';

import goodsPage from './views/goodsPage.vue';
import goodsDetails from './views/goodsDetails.vue';

import billPage from './views/billPage.vue';
import billDetails from './views/billDetails.vue';

import loginPage from './views/loginPage.vue';
import registerPage from './views/registerPage.vue';
import findpassPage from './views/findpassPage.vue';
import authenticationPage from './views/authenticationPage.vue';
import userPolicy from "./views/userPolicy.vue"

import agreeonOutWarehousing from './views/agreeonOutWarehousing.vue';


let instance


Vue.use(Router);
var router = new Router({
	routes: [
		{
			path: '/',
			name: 'readingLogin',
			component: readingLogin,
			children: [{
				path: '',
				name: '首页',
				component: HomePage,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			{
				path: 'storehousePage',
				name: '仓库',
				component: storehousePage,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
				// children:[        //子路由,嵌套路由 （此处偷个懒免得单独再列一点）
				//   {path:'',component:Hi},
				//   {path:'hi1',component:Hi1},
				//   {path:'hi2',component:Hi2},
				// ]
			},
			{
				path: 'storehouseDetails',
				name: '仓库详情',
				component: storehouseDetails,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			{
				path: 'agreeonWarehousing',
				name: '预约入库',
				component: agreeonWarehousing,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			{
				path: 'personalCenter',
				name: '个人中心',
				component: personalCenter,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			{
				path: 'modifyPage',
				name: '个人资料',
				component: modifyPage,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			{
				path: 'waitingPage',
				name: '我的待办',
				component: waitingPage,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			{
				path: 'orderPage',
				name: '我的订单',
				component: orderPage,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},

			{
				path: 'byBackPage',
				name: '发布回购',
				component: byBackPage,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			{
				path: 'transferencePage',
				name: '发布转让',
				component: transferencePage,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			{
				path: 'byBackList',
				name: '回购列表',
				component: byBackList,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			{
				path: 'orderDetailsPage',
				name: '入库单详情',
				component: orderDetailsPage,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			{
				path: 'agreeonOutWarehousing',
				name: '预约出库',
				component: agreeonOutWarehousing,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			{
				path: 'orderOutDetailsPage',
				name: '出库单详情',
				component: orderOutDetailsPage,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			{
				path: 'byBackDetails',
				name: '回购单详情',
				component: byBackDetails,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			{
				path: 'transferenceDetails',
				name: '转让单详情',
				component: transferenceDetails,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			{
				path: 'contractPage',
				name: '我的合同',
				component: contractPage,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			{
				path: 'Information',
				name: '企业信息',
				component: () => import('@/views/InformationPage.vue'),
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			{
				path: 'contractDetails',
				name: '合同详情',
				component: contractDetails,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			{
				path: 'goodsPage',
				name: '我的货物',
				component: goodsPage,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			{
				path: 'goodsDetails',
				name: '货物详情',
				component: goodsDetails,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			{
				path: 'billPage',
				name: '我的账单',
				component: billPage,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			{
				path: 'billDetails',
				name: '账单详情',
				component: billDetails,
				meta: {
					title: '',
					requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
				},
			},
			]
		},
		{
			path: '/loginPage',
			name: '登录注册',
			component: loginPage
		},
		{
			path: '/userAgreement',
			name: '用户服务',
			component: () => import('@/views/userAgreement.vue'),
		},
		{
			path: '/monitorPage',
			name: 'monitorPage',
			component: () => import('@/views/monitorPage.vue'),
		},
		{
			path: '/monitorInfo',
			name: 'monitorInfo',
			component: () => import('@/views/monitorInfo.vue'),
		},
		{
			path: '/registerPage',
			name: '注册',
			component: registerPage
		},
		{
			path: '/findpassPage',
			name: '忘记密码',
			component: findpassPage
		},
		{
			path: '/authenticationPage',
			name: '认证',
			component: authenticationPage
		},
		{
			path: '/userPolicy',
			name: '隐私政策',
			component: userPolicy
		}
	]
})

router.beforeEach(async (to, from, next) => {
	// 需要认证的页面
	const path = ['/agreeonWarehousing', '/agreeonOutWarehousing', '/byBackPage', '/transferencePage']
	// const path = []
	if (path.includes(to.path)) {
		const { data } = await verifyCanStoredWarehousing()
		let formData = {
			taostContent: data.msg,
			taostBtnName: null,
			taostTips: "提示",
			path: null,
		}
		if (data.code != 200) {
			if (data.code == 1000) {
				const data00 = await customerAuth()
				formData.taostBtnName = '去认证'
				formData.path = data00.data.data
				formData.pathType = 'window'
			} else if (data.code == 1001) {
				const data01 = await getSystemConfiguration()
				console.log(data01)
				formData.taostContent = `${formData.taostContent}\t\n联系电话：${data01.data.data.customerHotline}`
				formData.taostBtnName = '确定'
				// formData.path = data00.data.data
			} else if (data.code == 1002) {
				// const data00 = await customerAuth()
				formData.taostBtnName = '去签署'
				formData.pathType = 'router'
				formData.path = "/contractPage"
			}

			if (instance) {
				instance.$destroy()
				document.body.removeChild(instance.$el);
			}
			const Toast = Vue.extend(TaostPage)
			instance = new Toast({
				router,
				data: {
					formData,
					taostShow: true
				}
			})
			document.body.appendChild(instance.$mount().$el);
			return
		}
	}

	if (to.matched.some(record => record.meta.requireAuth)) { // 判断该路由是否需要登录权限
		if (localStorage.getItem('Token') != null) { // 判断缓存里面是否有 userName  //在登录的时候设置它的值
			next();
		} else {
			next({
				path: '/loginPage',
				query: {
					redirect: to.fullPath
				} // 将跳转的路由path作为参数，登录成功后跳转到该路由
			})
		}
	} else {
		next();
	}
})

// 获取原型对象push函数
const originalPush = Router.prototype.push

// 获取原型对象replace函数
const originalReplace = Router.prototype.replace

// 修改原型对象中的push函数
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
Router.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch(err => err)
}
export default router