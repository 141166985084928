<template>
  <div class="box-authentication">
    <div class="box-authentication-title">
      <div class="box-authentication-titleLeft">企业认证</div>
      <div class="box-authentication-titleLeft"></div>
    </div>
    <div class="box-authentication-tabs">
      <div class="box-authentication-bar">
        <div
          class="box-authentication-barLeft"
          :class="activeName == true ? 'active' : ''"
        >
          <div class="cirle">1</div>
          <div class="box-authentication-barText">企业信息认证</div>
        </div>
        <div
          class="box-authentication-barRight"
          :class="activeName == false ? 'active' : ''"
        >
          <div class="cirle">2</div>
          <div class="box-authentication-barText">对公账户认证</div>
        </div>
      </div>
      <div class="box-tabBar">
        <transition name="el-fade-in">
          <div class="box-form" v-if="next == 0">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="150px"
              class="demo-ruleForm"
            >
              <el-row>
                <el-col :span="24">
                  <el-col :span="8">
                    <el-form-item label="企业名称：" prop="name">
                      <el-input
                        v-model="ruleForm.name"
                        placeholder="请输入企业名称"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-col>
                <el-col :span="24">
                  <el-col :span="8">
                    <el-form-item label="统一信用代码：" prop="name">
                      <el-input
                        v-model="ruleForm.name"
                        placeholder="请输入统一信用代码"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-col>
                <el-col :span="24">
                  <el-col :span="8">
                    <el-form-item label="证件所在城市：" prop="name">
                      <el-input
                        v-model="ruleForm.name"
                        placeholder="请输入证件所在城市"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-col>
                <el-col :span="24">
                  <el-col :span="8">
                    <el-form-item label="法人代表姓名：" prop="name">
                      <el-input
                        v-model="ruleForm.name"
                        placeholder="请输入姓名"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-col>
                <el-col :span="24">
                  <el-col :span="8">
                    <el-form-item label="法人代表身份证号：" prop="name">
                      <el-input
                        v-model="ruleForm.name"
                        placeholder="请输入身份证号"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-col>
                <el-col :span="24">
                  <el-col :span="24">
                    <el-form-item label="上传营业执照照片：" prop="name">
                      <ImageUpload></ImageUpload>
                    </el-form-item>
                  </el-col>
                </el-col>
                <el-col :span="24">
                  <el-col :span="24">
                    <el-form-item label="上传开票信息：" prop="name">
                      <ImageUpload></ImageUpload>
                    </el-form-item>
                  </el-col>
                </el-col>
              </el-row>
            </el-form>

            <div class="box-next box-all-btn" @click="nextTo">下一步</div>
            <div class="box-Later" @click="submitAuthentication">稍后认证</div>
          </div>
        </transition>
        <transition name="el-fade-in">
          <div class="box-form" v-if="next == 1">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="150px"
              class="demo-ruleForm"
            >
              <el-row>
                <el-col :span="24">
                  <el-col :span="8">
                    <el-form-item label="开户行：" prop="name">
                      <el-input
                        v-model="ruleForm.name"
                        placeholder="请输入开户行"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-col>
                <el-col :span="24">
                  <el-col :span="8">
                    <el-form-item label="开户银行账号：" prop="name">
                      <el-input
                        v-model="ruleForm.name"
                        placeholder="请输入开户银行账号"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-col>
              </el-row>
            </el-form>
            <div class="box-next box-all-btn" @click="submitAuthentication">
              提交认证
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "authenticationPage",
  components: {},
  data() {
    return {
      next: 0,
      activeName: true,
      ruleForm: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "blur",
          },
          {
            min: 3,
            max: 5,
            message: "长度在 3 到 5 个字符",
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: "请选择活动区域",
            trigger: "change",
          },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        resource: [
          {
            required: true,
            message: "请选择活动资源",
            trigger: "change",
          },
        ],
        desc: [
          {
            required: true,
            message: "请填写活动形式",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submitAuthentication() {
      this.$router.push("/");
    },
    selectColor() {
      this.activeName = !this.activeName;
    },
    nextTo() {
      this.next = 1;
      this.activeName = !this.activeName;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.box-tabBar {
  padding-bottom: 140px;
}

.box-Later {
  width: 80px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1fb496;
  margin: 0 auto;
  margin-top: 32px;
}

.box-next {
  width: 450px;
  height: 62px;
  line-height: 62px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 31px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 156px;
}

.box-form {
  width: 790px;
  margin: 0 auto;
  margin-top: 140px;
}

.box-form /deep/ .el-select {
  width: 590px;
}

.box-form /deep/ .el-input {
  width: 590px;
}

.box-authentication {
  width: 1200px;
  background-color: #fff;
  margin: 0 auto;
}

.box-authentication-title {
  padding: 40px 30px;
  display: flex;
  justify-content: space-between;
}

.box-authentication-tabs {
  margin-top: 85px;
  height: auto;
}

.box-authentication-bar {
  width: 790px;
  height: 10px;
  background: #efefef;
  border-radius: 5px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.box-authentication-barLeft {
  width: 395px;
  height: 10px;
  background: #efefef;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.box-authentication-barRight {
  width: 395px;
  height: 10px;
  background: #efefef;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.active {
  background: #41b5ac;
}

.cirle {
  position: absolute;
  top: -50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -18px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  background: #efefef;
  border-radius: 50%;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #999999;
}

.box-authentication-barText {
  width: 100%;
  position: absolute;
  top: 46px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #999999;
}

.active .box-authentication-barText {
  color: #41b5ac;
}

.active .cirle {
  position: absolute;
  top: -50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -18px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  background: #41b5ac;
  border-radius: 50%;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
}
</style>
