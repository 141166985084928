import { render, staticRenderFns } from "./byBackDetails.vue?vue&type=template&id=8740ac5a&scoped=true&"
import script from "./byBackDetails.vue?vue&type=script&lang=js&"
export * from "./byBackDetails.vue?vue&type=script&lang=js&"
import style0 from "./byBackDetails.vue?vue&type=style&index=0&id=8740ac5a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8740ac5a",
  null
  
)

export default component.exports