<template>
  <div class="userPolicy">
    <el-page-header @back="$router.go(-1)"> </el-page-header>
    <p>发布日期：2024/01/17</p>
    <p>生效日期：2024/04/09</p>
    <p>
      易加仓储APP 是一款由 深圳市易加数字仓储物流有限公司
      (以下简称“平台”)提供的产品，应用研发方为深圳市易加数字仓储物流有限公司，平台非常重视用户的隐私和个人信息保护。您在使用服务时，我们可能会收集和使用您的相关信息。我们希望通过《隐私政策》(以下简称“本隐私政策”)向您说明我们在提供服务时如何收集、使用、保存、共享和披露这些信息。
    </p>
    <p>本隐私政策将帮助您了解以下内容：</p>
    <p>1.个人信息的收集和使用</p>
    <p>2.个人信息的披露</p>
    <p>3.个人信息的共享</p>
    <p>4.安全</p>
    <p>5.第三方网站</p>
    <p>6.未成年人保护</p>
    <p>7.联系我们</p>
    <p>8.通知和修订</p>
    <p class="mt20">
      <b
        >本隐私政策与您所使用的平台服务以及该服务所包括的各种业务功能息息相关，希望您在使用服务前仔细阅读并确认您已经充分理解本隐私政策所写明的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策中涉及的相关术语，我们尽量以简明扼要的表述，以便您更好地理解。您使用或在我们更新本隐私政策后(会及时提示您更新的情况)继续使用平台服务的，即意味着您同意本隐私政策(含更新版本)内容，并且同意我们按照本隐私政策收集、使用、保存、共享和披露您的相关信息。</b
      >
    </p>
    <p class="mt20"><b>一、个人信息的收集和使用</b></p>
    <p><b>(一)信息收集目的</b></p>
    <p>
      平台服务向您提供物流管理、信息交流。
      <b>
        平台服务包括一些核心功能，这些功能包含了供物流管理、信息交流及反馈所必须的功能。我们可能会收集、保存和使用与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法使用平台服务。</b
      >
    </p>

    <p><b>(二)信息收集、使用的主要范围及方式</b></p>
    <p><b>接入第三方SDK目录</b></p>
    <p>
      我们的产品基于DCloud uni-app(5+
      App/Wap2App)开发，应用运行期间需要收集您的设备序列号、IMEI/android
      ID/DEVICE_ID/IDFA、SIM 卡 IMSI
      信息、OAID以提供统计分析服务，并通过应用启动数据及异常错误日志分析改进性能和用户体验，为用户提供更好的服务。详情内容请访问《DCloud用户服务条款》。（DCloud用户服务条款超链至：https://ask.dcloud.net.cn/protocol.html）。
    </p>
    <p>SDK名称：移动智能终端补充设备标识体系统一调用SDK</p>
    <p>SDK 用途：获取移动安全联盟广告标识符，用于标识设备</p>
    <p>数据类型：匿名设备标识符（oaid）</p>
    <p>数据处理方式：采取去标识、加密方式进行传输和处理</p>
    <p>官网链接：https://msa-alliance.cn/</p>
    <p>高德开放平台定位 SDK 第三方公司名称：高德软件有限公司</p>
    <p>
      收集个人信息：定位信息（经纬度、精确位置、粗略位置）【通过IP地址、CNSS信息、WIFI状态、WIFI参数、WIFi列表、BSSID/基站信息、信号强度的信息、蓝牙信息、传感器信息（矢量、加速器、压力）、设备信号强度信息获取、外部储存目录】、设备标识信息（IMEi、IDFA、IDFV、Android
      ID、MEID、MAC地址、OAID、IMSI、ICCID、硬件序列号）、当前应用信息（应用名、应用版本号）、设备参数及系统信息（系统属性）。设备型号、操作系统、运营商信息）
    </p>
    <p>使用目的：获取位置信息，帮助用户定位和选择配送地址</p>
    <p>使用场景：用户使用定位和地图服务时使用</p>
    <p>集成方式：APP初始化SDK，SDK通过自己的逻辑调用系统的API获取</p>
    <p>隐私政策：https://lbs.amap.com/pages/privacy/</p>
    <p>我们会为实现本隐私政策下述的各项功能，收集和使用您的个人信息：</p>
    <p><b>1.个人信息</b></p>
    <p>
      是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。<b
        >本隐私政策中涉及的个人信息包括:基本信息(包括个人姓名、性别、个人电话号码、户籍等);个人身份信息(包括身份证、驾驶证等);面部特征;网络身份标识信息(包括系统账号、IP地址等);设备MAC地址用于推送app通知到用户、软件安装列表用于指定推送到设备的哪个app中、通讯录用于联系处理人；</b
      >
    </p>
    <p>个人资料-头像，将获取用户图片信息，用于用户自定义头像资料</p>
    <p><b>2.登录注册</b></p>
    <p>
      当您注册、深圳市易加数字仓储物流有限公司App及相关服务时，您可以通过手机号创建帐号，并且您可以完善相关的网络身份识别信息（头像、昵称、密码），收集这些信息是为了帮助您完成注册。您还可以根据自身需求选择填写性别、生日、地区及个人介绍来完善您的信息。<b
        >本隐私政策中涉及的个人信息包括:基本信息(包括个人姓名、性别、个人电话号码、户籍等);个人身份信息(包括身份证、驾驶证等);面部特征;网络身份标识信息(包括系统账号、IP地址等);设备MAC地址用于推送app通知到用户、软件安装列表用于指定推送到设备的哪个app中、通讯录用于联系处理人；</b
      >
    </p>
    <p><b>3.认证</b></p>
    <p>
      在您使用身份认证的功能或相关服务所需时，根据相关法律法规，您可能需要提供您的真实身份信息（真实姓名、身份证号码、电话号码等）以完成实名验证。
      部分信息属于个人敏感信息，您可以拒绝提供，如果拒绝提供您将可能无法获得相关服务，但不影响其他功能与服务的正常使用。
    </p>
    <p>我的-企业认证，获取用户身份信息，用于用户或企业实名认证，身份信息确认</p>
    <p><b>4.设备信息与日志信息</b></p>
    <p>
      为了保障软件与服务的安全运行、运营的质量及效率，我们会收集您的硬件型号、操作系统版本号、国际移动设备识别码、唯一设备标识符、网络设备硬件地址、IP
      地址、WLAN接入点、蓝牙、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志
    </p>
    <p><b>5.服务开通</b></p>

    <p>
      您在使用平台服务时，您的单位将为您开通相关权限。<b>为您开通使用时，您需要向我们提供您本人的相关信息，我们将通过您提供的信息来验证您的身份是否有效。</b>如果您仅需要使用浏览服务，您不需要开通服务及提供上述信息。
    </p>

    <p><b>6.为您提供其他附加服务</b></p>

    <p>
      为向您提供更便捷、更优质的产品及/或服务，努力提升您的体验，我们在向您提供以下附加服务中可能会收集和使用您的个人信息、如果您不提供这些信息，将无法获得这些附加服务给您带来的用户体验。这些附加服务包括：
    </p>

    <p>
      (1)<b>基于摄像头(相机)的附加服务：</b>您可在开启相机/摄像头权限后使用该功能进行视频拍摄、照片拍摄、扫码以及人脸识别，拍摄照片或视频用于记录、反馈、分享以及进行人脸核验等。
    </p>

    <p>
      (2)<b>基于相册(图片/视频库)的附加服务：</b>您可在开启相册权限后使用该功能上传您的照片/图片/视频，以实现记录、反馈、发表评论、分享等。
    </p>

    <p>
      您理解并同意，上述附加服务可能需要您在您的设备中开启您的位置信息(地理位置)、摄像头(相机)、相册(图片库)的访问权限，以实现这些权限所涉及信息的收集和使用。
    </p>
    <p><b>7.注销账号</b></p>
    <p>
      您可以在【我的】-【账号注销】进行帐号注销。您知悉并理解，注销帐号的行为是不可逆的行为，当您注销帐号后，我们将删除有关您的相关信息或进行匿名化处理，但法律法规另有规定的除外。我们会在1个工作日将账号注销成功
    </p>
    <p><b>二、个人信息的披露</b></p>

    <p>
      1、除非本隐私政策载明的有限披露，我们会妥善保存您的个人信息并不会泄露信息；
    </p>

    <p>
      2、您于此授权，以下情形下，我们将会向第三方披露您的个人信息而无需通过您的同意：
    </p>

    <p>
      (1)如果我们披露这些信息是用来识别、联系或向可能对我们的权利或财产、用户或者其他任何人(包括其他任何人的权利和财产)造成损害之行为提起诉讼之必要；
    </p>

    <p>(2)法律调查之必要；</p>

    <p>
      (3)此类披露是法律规定或应向我们送达的传票或其他执法部门发出的令状之必要。
    </p>

    <p><b>三、个人信息的共享</b></p>

    <p>
      1、我们不会与平台以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：
    </p>

    <p>(1)事先获得您明确的同意或授权；</p>

    <p>
      (2)根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；
    </p>

    <p>
      (3)在法律法规允许的范围内，为维护平台、平台的关联方或合作伙伴、您或其他用户或社会公众利益、财产或安全免遭损害而有必要提供；
    </p>

    <p>
      (4)只有共享您的信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；
    </p>

    <p>(5)应您需求为您处理您与他人的纠纷或争议；</p>

    <p>(6)符合与您签署的相关协议或其他的法律文件约定所提供；</p>

    <p>(7)基于学术研究而使用；</p>

    <p>(8)基于符合法律法规的社会公共利益而使用。</p>

    <p>
      <b
        >2、我们可能会将您的个人信息与我们的关联方共享。但我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。</b
      >
    </p>

    <p>
      <b
        >3、我们可能会向合作伙伴等第三方共享您接单信息、反馈信息、设备信息以及位置信息。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的信息，并且只会共享必要的信息。我们的合作伙伴无权将共享的信息用于任何其他用途。</b
      >我们的合作伙伴包括以下类型：
    </p>

    <p>
      (1)提供服务的供应商。<b>我们可能会将您的信息共享给支持我们功能的第三方。</b>这些支持包括为我们提供技术服务、支付服务、数据处理等。我们共享这些信息的目的是可以实现我们产品与/或服务的核心功能。
    </p>

    <p>(2)事先获得您明确授权或同意的其他第三方。</p>

    <p>
      4、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。在个人敏感数据使用上，我们要求第三方采用数据脱敏和加密技术，从而更好地保护用户数据。
    </p>

    <p>
      5、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护平台、您或其他客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过,这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。
    </p>

    <p><b>四、安全</b></p>

    <p>
      1、对我们来说，个人信息的安全非常重要。我们将尽可能保障您的个人信息安全，并实施合理的措施限制非授权访问、使用或者披露您的个人信息。尽管采取上述措施，但是您应当知晓平台服务不能完全避免与个人信息相关的安全危险。
    </p>

    <p>2、双方同意，如发生下述情形，平台服务不承担任何法律责任：</p>

    <p>(1)依据法律规定或相关政府部门的要求提供您的个人信息；</p>

    <p>(2)由于您的使用不当而导致任何个人信息的泄露；</p>

    <p>
      (3)任何由于黑客攻击，电脑病毒的侵入，非法内容信息、骚扰信息的屏蔽，政府管制以及其他任何网络、技术、通信线路、信息安全管理措施等非“平台服务”原因造成的服务中断、受阻等不能满足用户要求的情形；
    </p>

    <p>您应当协助我们以保护您的个人信息安全。例如，不要泄露您的个人密码。</p>

    <p><b>五、第三方网站</b></p>

    <p>
      1、当您点击平台服务链接的其他网站时，您已经离开平台服务中心并访问其他网站，其他网站可能会收集您的个人信息或匿名数据，因此，我们无法控制、检查或者回应此类网站和网站内容。
    </p>

    <p>2、本隐私政策不适用于在您点击链接后的外部网站收集数据的行为。</p>

    <p><b>六、联系我们</b></p>

    <p>
      1、如果您认为我们存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以与我们联系，联系地址：深圳市龙岗区坂田街道岗头社区新围仔五和大道 4004号名筑大厦。为了保障安全，我们可能需要您提供书面请求，或以其他方式证明您的身份，我们将在收到您反馈并验证您的身份后的15天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段(例如，需要开发新系统或从根本上改变现行惯例)、给他人合法权益带来风险或者非常不切实际(例如，涉及备份磁带上存放的信息)的请求，我们可能会予以拒绝。
    </p>

    <p>2、在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>

    <p>(1)与国家安全、国防安全有关的；</p>

    <p>(2)与公共安全、公共卫生、重大公共利益有关的；</p>

    <p>(3)与犯罪侦查、起诉和审判等有关的；</p>

    <p>(4)有充分证据表明您存在主观恶意或滥用权利的；</p>

    <p>(5)响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</p>

    <p><b>七、通知和修订</b></p>

    <p>
      1、为给您提供更好的服务以及随着业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在平台服务上发出更新版本并在生效前通过公告或以其他适当方式提醒您相关内容的更新，也请您访问平台服务以便及时了解最新的隐私政策。
    </p>

    <p>
      2、对于重大变更，我们还会提供更为显著的通知(我们会通过包括但不限于短信或在平台服务页面做特别提示等方式，说明隐私政策的具体变更内容)。
    </p>

    <p>本隐私政策所指的重大变更包括但不限于：</p>

    <p>
      (1)我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
    </p>

    <p>(2)我们在所有权结构、组织架构等方面发生重大变化；</p>

    <p>(3)个人信息共享、转让或公开披露的主要对象发生变化；</p>

    <p>(4)您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>

    <p>(5)我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>

    <p>(6)个人信息安全影响评估报告表明存在高风险时。</p>
  </div>
</template>
<script>
export default {
  name: "userPolicy",
};
</script>
<style>
.userPolicy {
  text-align: start;
  padding: 30px;
}
</style>
