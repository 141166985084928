import http from '../utils/http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
// let resquest = "http://192.168.0.21:8082"

// 获取验证码
export function sendCode(codeType, params) {
    return http.get('/business/customer/login/sendCode?phone=' + params + '&codeType=' + codeType)
}
//登录
export function loginPost(params) {
    return http.post('/business/customer/login/customerLogin', params)
}
// 注册
export function registerPost(params) {
    return http.post('/business/customer/login/customerRegister', params)
}
//找回密码
export function findpassPost(params) {
    return http.post('/business/customer/login/findPassword', params)
}

export function getUser() {
    return http.get('/business/customer/login/getUserDetail')
}
export function postUpload(params) {
    return http.post('/dev-api/business/customer/login/customerRegister', params)
}
export function putSomeAPI(params) {
    return http.put('/dev-api/business/customer/login/customerRegister', params)
}
export function deleteListAPI(params) {
    return http.delete('/dev-api/business/customer/login/customerRegister', params)
}

export function codeLogin(params) {
    return http.post('business/customer/login/customerLoginByCode', params)
}

export function updateOrgType(data) {
    return http.post('/business/customer/login/updateOrgType', data)
}
export function updateAvatar(data) {
    return http.post('/business/customer/login/updateAvatar', data)
}
export function updateContactName(data) {
    return http.post('/business/customer/login/updateContactName', data)
}
export function systemConfiguration() {
    return http.get('/business/systemConfiguration')
}
export function adListAll(data) {
    return http.get('/business/ad/listAll',data)
}
export function updateCustomerPic(data) {
    return http.post('/business/customer/login/updateCustomerPic',data)
}
