import request from '@/utils/request'

// 查询账单管理列表
export function listBill(query) {
  return request({
    url: '/businessOrAdmin/bill/getCustomerMyBill',
    method: 'get',
    params: query
  })
}

// 查询账单管理详细
export function uploadVoucher(data) {
  return request({
    url: '/businessOrAdmin/bill/uploadVoucher',
    method: 'POST',
    data
  })
}
// 查询账单管理详细
export function getBill(params) {
  return request({
    url: 'businessOrAdmin/bill/getBillDetail',
    method: 'GET',
    params
  })
}

// 新增账单管理
export function addBill(data) {
  return request({
    url: '/business/bill',
    method: 'post',
    data: data
  })
}

// 修改账单管理
export function updateBill(data) {
  return request({
    url: '/business/bill',
    method: 'put',
    data: data
  })
}

// 删除账单管理
export function delBill(id) {
  return request({
    url: '/business/bill/' + id,
    method: 'delete'
  })
}
