import http from '@/utils/http'

// 查询字典数据列表
export function listData(params) {
	return http.get('/system/dict/data/list', params)
}

// 查询字典数据详细
export function getData(dictCode) {
	return http.get('/system/dict/data/' + dictCode)
}

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
	return http.get('/system/dict/data/type/' + dictType)
}

// 新增字典数据
export function addData(params) {
	return http.post('/system/dict/data', params)
}

// 修改字典数据
export function updateData(params) {
	return http.put('/system/dict/data', params)
}

// 删除字典数据
export function delData(dictCode) {
	return http.delete('/system/dict/data/' + dictCode)
}