<template>
  <div>
    <template>
      <div class="scrollImg">
        <el-image
          ref="image"
          class="carouselImg"
          :src="require('../assets/ckimg.jpg')"
        />
      </div>
      <div class="box-content">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="150px"
          class="demo-ruleForm"
        >
          <titlePage :titleText="sessiontext" class="li-top"></titlePage>
          <div class="box-form">
            <el-row>
              <el-col :span="24">
                <el-col :span="8">
                  <el-form-item label="选择入库仓库" prop="warehouseName">
                    <div @click="clickDetail">
                      <el-input
                        v-model="ruleForm.warehouseName"
                        class="input-with-select"
                        readonly="readonly"
                      >
                        <el-button
                          slot="append"
                          icon="el-icon-search"
                        ></el-button>
                      </el-input>
                    </div>
                  </el-form-item>
                </el-col>
              </el-col>
              <el-col :span="24">
                <el-col :span="8">
                  <el-form-item
                    label="预计到货时间"
                    prop="estimatedArrivalTime"
                  >
                    <el-date-picker
                      v-model="ruleForm.estimatedArrivalTime"
                      type="date"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd"
                      :picker-options="pickerOptions"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-row>
            <!-- </el-form> -->
          </div>
          <!-- 商品信息 -->
          <titlePage :titleText="shoptext" class="li-top"></titlePage>
          <div class="box-form">
            <vxe-table
              border
              show-overflow
              keep-source
              ref="xTable"
              align="center"
              :data="ruleForm.goodsDetailRecordList"
              :edit-rules="validRules"
              :edit-config="{
                trigger: 'click',
                mode: 'row',
                showStatus: true,
                icon: 'none',
              }"
            >
              <vxe-column
                field="goodsTypeId"
                title="商品分类"
                :edit-render="{}"
              >
                <template #default="{ row }">
                  <span>{{ row.goodsTypeName }}</span>
                </template>
                <template #edit="{ row }">
                  <vxe-select
                    v-model="row.goodsTypeId"
                    clearable
                    transfer
                    @change="goodsListAll({ row })"
                    @clear="clearGoodsTypeId({ row })"
                  >
                    <vxe-option
                      v-for="item in goodsTypeList"
                      :key="item.id"
                      :value="item.id"
                      :label="item.typeName"
                    ></vxe-option>
                  </vxe-select>
                </template>
              </vxe-column>
              <vxe-column field="goodsId" title="商品名称" :edit-render="{}">
                <template #default="{ row }">
                  <span>{{ row.goodsName }}</span>
                </template>
                <template #edit="{ row }">
                  <vxe-select
                    v-model="row.goodsId"
                    clearable
                    transfer
                    @change="manufacturerListAll({ row })"
                    @clear="clearGoodId({ row })"
                  >
                    <vxe-option
                      v-for="item in row.goodsList"
                      :key="item.id"
                      :value="item.id"
                      :label="item.goodsName"
                    ></vxe-option>
                  </vxe-select>
                </template>
              </vxe-column>
              <vxe-column
                field="goodsManufacturerId"
                title="商品厂家"
                :edit-render="{}"
              >
                <template #default="{ row }">
                  <span>{{ row.goodsManufacturerName }}</span>
                </template>
                <template #edit="{ row }">
                  <vxe-select
                    v-model="row.goodsManufacturerId"
                    clearable
                    transfer
                    @change="goodsModeListAll({ row })"
                    @clear="clearGoodsManufacturerId({ row })"
                  >
                    <vxe-option
                      v-for="item in row.manufacturerList"
                      :key="item.id"
                      :value="item.id"
                      :label="item.attrName"
                    ></vxe-option>
                  </vxe-select>
                </template>
              </vxe-column>
              <vxe-column field="goodsModelId" title="型号" :edit-render="{}">
                <template #default="{ row }">
                  <span>{{ row.goodsModelName }}</span>
                </template>
                <template #edit="{ row }">
                  <vxe-select
                    v-model="row.goodsModelId"
                    clearable
                    transfer
                    @change="goodsSpecListAll({ row })"
                    @clear="clearGoodsModelId({ row })"
                  >
                    <vxe-option
                      v-for="item in row.goodsModeList"
                      :key="item.id"
                      :value="item.id"
                      :label="item.attrName"
                    ></vxe-option>
                  </vxe-select>
                </template>
              </vxe-column>
              <vxe-column field="goodsSpecId" title="规格" :edit-render="{}">
                <template #default="{ row }">
                  <span>{{ row.goodsSpecName }}</span>
                </template>
                <template #edit="{ row }">
                  <vxe-select
                    v-model="row.goodsSpecId"
                    @change="goodsSpecChange(row)"
                    clearable
                    transfer
                  >
                    <vxe-option
                      v-for="item in row.goodsSpecList"
                      :key="item.id"
                      :value="item.id"
                      :label="item.attrName"
                    ></vxe-option>
                  </vxe-select>
                </template>
              </vxe-column>
              <vxe-column field="totalQuantity" title="数量" :edit-render="{}">
                <template #edit="{ row }">
                  <vxe-input
                    v-model="row.totalQuantity"
                    type="text"
                    v-if="row.ifShowUnit == 0"
                  ></vxe-input>
                </template>
              </vxe-column>
              <vxe-column
                field="totalQuantityUnit"
                title="单位"
                width="70"
                :edit-render="{}"
              >
                <template #default="{ row }">
                  <span>{{
                    dict.sys_user_count_unit[row.totalQuantityUnit]
                  }}</span>
                </template>
                <template #edit="{ row }">
                  <vxe-select
                    v-model="row.totalQuantityUnit"
                    clearable
                    transfer
                    v-if="row.ifShowUnit == 0"
                  >
                    <vxe-option
                      v-for="dict in dictOption.sys_user_count_unit"
                      :key="dict.dictValue"
                      :label="dict.dictLabel"
                      :value="dict.dictValue"
                    ></vxe-option>
                  </vxe-select>
                </template>
              </vxe-column>
              <vxe-column field="totalWeight" title="重量" :edit-render="{}">
                <template #edit="{ row }">
                  <vxe-input
                    v-model="row.totalWeight"
                    type="text"
                    v-if="row.ifShowUnit == 1"
                  ></vxe-input>
                </template>
              </vxe-column>
              <vxe-column
                field="totalWeightUnit"
                title="单位"
                width="70"
                :edit-render="{}"
              >
                <template #default="{ row }">
                  <span>{{
                    dict.sys_user_weight_unit[row.totalWeightUnit]
                  }}</span>
                </template>
                <template #edit="{ row }">
                  <vxe-select
                    v-model="row.totalWeightUnit"
                    clearable
                    transfer
                    v-if="row.ifShowUnit == 1"
                  >
                    <vxe-option
                      v-for="dict in dictOption.sys_user_weight_unit"
                      :key="dict.dictValue"
                      :label="dict.dictLabel"
                      :value="dict.dictValue"
                    ></vxe-option>
                  </vxe-select>
                </template>
              </vxe-column>
              <vxe-column
                field="netWeight"
                title="净重"
                :edit-render="{}"
                style="filter: blur(50%)"
              >
                <template #edit="{ row }">
                  <vxe-input
                    v-model="row.netWeight"
                    type="text"
                    v-if="row.ifShowUnit == 2"
                  ></vxe-input>
                </template>
              </vxe-column>
              <vxe-column
                field="netWeightUnit"
                title="单位"
                width="70"
                :edit-render="{}"
              >
                <template #default="{ row }">
                  <span>{{
                    dict.sys_user_weight_unit[row.netWeightUnit]
                  }}</span>
                </template>
                <template #edit="{ row }">
                  <vxe-select
                    v-model="row.netWeightUnit"
                    clearable
                    transfer
                    v-if="row.ifShowUnit == 2"
                  >
                    <vxe-option
                      v-for="dict in dictOption.sys_user_weight_unit"
                      :key="dict.dictValue"
                      :label="dict.dictLabel"
                      :value="dict.dictValue"
                    ></vxe-option>
                  </vxe-select>
                </template>
              </vxe-column>
              <vxe-column
                field="warehousingRequirements"
                title="入库要求"
                :edit-render="{}"
              >
                <template #default="{ row }">
                  <span>{{
                    dict.warehousing_requirements[row.warehousingRequirements]
                  }}</span>
                </template>
                <template #edit="{ row }">
                  <vxe-select
                    v-model="row.warehousingRequirements"
                    clearable
                    transfer
                  >
                    <vxe-option
                      v-for="dict in dictOption.warehousing_requirements"
                      :key="dict.dictValue"
                      :label="dict.dictLabel"
                      :value="dict.dictValue"
                    ></vxe-option>
                  </vxe-select>
                </template>
              </vxe-column>
              <vxe-column
                field="isWarehousingWithTray"
                title="是否带托入库"
                :edit-render="{}"
              >
                <template #default="{ row }">
                  <span>{{ dict.sys_yes_no[row.isWarehousingWithTray] }}</span>
                </template>
                <template #edit="{ row }">
                  <vxe-select
                    v-model="row.isWarehousingWithTray"
                    clearable
                    transfer
                  >
                    <vxe-option
                      v-for="dict in dictOption.sys_yes_no"
                      :key="dict.dictValue"
                      :label="dict.dictLabel"
                      :value="dict.dictValue"
                    ></vxe-option>
                  </vxe-select>
                </template>
              </vxe-column>
              <vxe-column title="操作" width="50">
                <template #default="{ row }">
                  <el-link :underline="false" @click="shopRemoveRowEvent(row)"
                    >删除</el-link
                  >
                  <!-- <vxe-button @click="shopRemoveRowEvent(row)"></vxe-button> -->
                </template>
              </vxe-column>
            </vxe-table>
            <div style="display: flex; justify-content: space-between">
              <el-link
                type="primary"
                :underline="false"
                @click="$refs.addShop.showDialog()"
                >未找到您的商品，申请添加
              </el-link>

              <el-link
                @click="createTpzlht"
                :underline="false"
                v-if="!this.isSignPalletRental"
                >去签署托盘租赁合同
              </el-link>
            </div>
          </div>
          <div class="box-addBtn" @click="insertEvent()">新增商品</div>

          <titlePage :titleText="goodstext" class="li-top"></titlePage>
          <div class="box-form">
            <el-row>
              <el-col :span="24">
                <el-col :span="8">
                  <el-form-item
                    label=" 是否需要平台司机"
                    prop="isNeedPlatformDriver"
                  >
                    <el-select
                      v-model="ruleForm.isNeedPlatformDriver"
                      placeholder="请选择"
                    >
                      <el-option label="使用平台司机" value="1"></el-option>
                      <el-option label="自备司机" value="0"></el-option>
                    </el-select>
                    <div class="customer-sign">
                      <el-link
                        @click="createWlht"
                        :underline="false"
                        v-if="
                          ruleForm.isNeedPlatformDriver == 1 &&
                          !this.isCustomerSign
                        "
                        >去签署物流合同
                      </el-link>
                    </div>
                  </el-form-item>
                </el-col>
              </el-col>
              <template v-if="ruleForm.isNeedPlatformDriver == 1">
                <el-col :span="24">
                  <el-col :span="8">
                    <el-form-item label="提货地址">
                      <maps
                        @mapHere="costPlannedAmountChange"
                        style="width: 600px"
                      >
                      </maps>
                    </el-form-item>
                  </el-col>
                </el-col>
                <el-col :span="24">
                  <el-col :span="8">
                    <el-form-item label="提货详细地址" prop="deliveryAddress">
                      <el-input v-model="ruleForm.deliveryAddress"></el-input>
                    </el-form-item>
                  </el-col>
                </el-col>
                <el-col :span="24">
                  <el-col :span="8">
                    <el-form-item
                      label="提货联系人"
                      prop="deliveryContact"
                      :rules="[
                        {
                          required: true,
                          message: '司机联系名称不能为空',
                        },
                      ]"
                    >
                      <el-input
                        v-model="ruleForm.deliveryContact"
                        placeholder="请输入提货联系人"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-col>
                <el-col :span="24">
                  <el-col :span="8">
                    <el-form-item
                      label="联系人手机号"
                      prop="deliveryContactPhone"
                      :rules="[
                        {
                          required: true,
                          message: '司机联系方式不能为空',
                        },
                        {
                          pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
                          message: '请输入正确的手机号',
                        },
                      ]"
                    >
                      <el-input
                        v-model="ruleForm.deliveryContactPhone"
                        placeholder="请输入联系人手机号"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-col>
                <el-col :span="24">
                  <el-col :span="24">
                    <el-form-item
                      label="提货凭证单"
                      prop="deliveryVoucherPic"
                      :rules="[
                        {
                          required: true,
                          message: '提货凭证单不能为空',
                        },
                      ]"
                    >
                      <ImageUpload
                        v-model="ruleForm.deliveryVoucherPic"
                        :limit="1"
                      ></ImageUpload>
                    </el-form-item>
                  </el-col>
                </el-col>
              </template>
            </el-row>
          </div>
          <div class="box-table" v-if="ruleForm.isNeedPlatformDriver == '0'">
            <el-form-item
              prop="isNeedPlatformDriver"
              v-if="ruleForm.isNeedPlatformDriver == '0'"
            >
              <vxe-table
                border
                show-overflow
                keep-source
                align="center"
                ref="driverTable"
                max-height="400"
                :data="driverTableData"
                :edit-rules="driverValidRules"
                :edit-config="{
                  trigger: 'click',
                  mode: 'cell',
                  showStatus: true,
                  icon: 'none',
                }"
              >
                <vxe-column
                  field="driverName"
                  title="司机姓名"
                  :edit-render="{ autofocus: '.vxe-input--inner' }"
                >
                  <template #edit="{ row }">
                    <vxe-input v-model="row.driverName" type="text"></vxe-input>
                  </template>
                </vxe-column>
                <vxe-column
                  field="driverPhone"
                  title="手机号"
                  :edit-render="{ autofocus: '.vxe-input--inner' }"
                >
                  <template #edit="{ row }">
                    <vxe-input
                      v-model="row.driverPhone"
                      type="text"
                    ></vxe-input>
                  </template>
                </vxe-column>
                <vxe-column
                  field="driverIdCard"
                  title="身份证号"
                  :edit-render="{ autofocus: '.vxe-input--inner' }"
                >
                  <template #edit="{ row }">
                    <vxe-input
                      v-model="row.driverIdCard"
                      type="text"
                    ></vxe-input>
                  </template>
                </vxe-column>
                <vxe-column
                  field="carNumber"
                  title="车牌号"
                  :edit-render="{ autofocus: '.vxe-input--inner' }"
                >
                  <template #edit="{ row }">
                    <vxe-input v-model="row.carNumber" type="text"></vxe-input>
                  </template>
                </vxe-column>
                <vxe-column title="运输商品">
                  <template #default="{ row }">
                    <vxe-button @click="addGoods(row)">{{
                      row.driverGoodsList ? "编辑" : "添加"
                    }}</vxe-button>
                  </template>
                </vxe-column>
                <vxe-column title="操作">
                  <template #default="{ row }">
                    <vxe-button @click="removeRowEvent(row)">删除</vxe-button>
                  </template>
                </vxe-column>
              </vxe-table>
            </el-form-item>
          </div>

          <div
            class="box-addBtn"
            v-if="ruleForm.isNeedPlatformDriver == '0'"
            @click="driverInsertEvent"
          >
            新增司机
          </div>
          <el-row>
            <el-col :span="24">
              <el-form-item
                label="简要备注"
                prop="remark"
                style="margin-top: 60px"
              >
                <el-input
                  v-model="ruleForm.remark"
                  type="textarea"
                  placeholder="请输入备注"
                  class="box-textarea"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="agreeOn-success" @click="submitFrom">
          {{ $route.query.orderId ? "修改" : "提交" }}
        </div>
      </div>
    </template>
    <taostStorehouse
      ref="company"
      @warehouseSelect="warehouseSelect"
    ></taostStorehouse>
    <dialogPage taostTips="添加商品" ref="confirmGoods" width="80%">
      <el-table
        :data="selectGoodsList"
        style="width: 100%"
        :header-cell-style="{
          background: '#F2F2F2',
          'text-align': 'center',
          color: '#333333',
        }"
        border
        width="80"
        ref="selectGoods"
      >
        <el-table-column label="商品分类" align="center" prop="goodsTypeName">
        </el-table-column>
        <el-table-column label="商品名称" align="center" prop="goodsName">
        </el-table-column>
        <el-table-column
          label="商品厂家"
          align="center"
          prop="goodsManufacturerName"
        >
        </el-table-column>
        <el-table-column label="型号" align="center" prop="goodsModelName">
        </el-table-column>
        <el-table-column label="规格" align="center" prop="goodsSpecName">
        </el-table-column>
        <el-table-column label="总数量" align="center" prop="totalQuantity">
        </el-table-column>
        <el-table-column
          label="总数量单位"
          align="center"
          prop="totalQuantityUnit"
        >
          <template slot-scope="scope">
            {{ dict.sys_user_count_unit[scope.row.totalQuantityUnit] }}
          </template>
        </el-table-column>
        <el-table-column label="总重量" align="center" prop="totalWeight">
        </el-table-column>
        <el-table-column
          label="总重量单位"
          align="center"
          prop="totalWeightUnit"
        >
          <template slot-scope="scope">
            {{ dict.sys_user_weight_unit[scope.row.totalWeightUnit] }}
          </template>
        </el-table-column>
        <el-table-column label="净重" align="center" prop="netWeight">
        </el-table-column>
        <el-table-column label="净重单位" align="center" prop="netWeightUnit">
          <template slot-scope="scope">
            {{ dict.sys_user_weight_unit[scope.row.netWeightUnit] }}
          </template>
        </el-table-column>
        <el-table-column
          label="入库要求"
          align="center"
          prop="warehousingRequirements"
        >
          <template slot-scope="scope">
            <span>{{
              dict.warehousing_requirements[scope.row.warehousingRequirements]
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="是否带托入库"
          align="center"
          prop="isWarehousingWithTray"
        >
          <template slot-scope="scope">
            <span>{{ dict.sys_yes_no[scope.row.isWarehousingWithTray] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="运输量" align="center">
          <template slot-scope="scope">
            <el-input
              placeholder="请输入"
              @change="totalChange(scope.row)"
              v-model="scope.row.total"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
      <el-button type="primary" style="margin-top: 50px" @click="confirmGoods"
        >确定</el-button
      >
    </dialogPage>
    <dialogPage taostTips="选择商品" ref="selectShop" width="80%">
      <el-table
        :data="gridData"
        style="width: 100%"
        :header-cell-style="{
          background: '#F2F2F2',
          'text-align': 'center',
          color: '#333333',
        }"
        border
        width="80"
        ref="selectGoods"
        @selection-change="handleSelectGoods"
      >
        <el-table-column label="商品分类" align="center" prop="goodsTypeName">
        </el-table-column>
        <el-table-column label="商品名称" align="center" prop="goodsName">
        </el-table-column>
        <el-table-column
          label="商品厂家"
          align="center"
          prop="goodsManufacturerName"
        >
        </el-table-column>
        <el-table-column label="型号" align="center" prop="goodsModelName">
        </el-table-column>
        <el-table-column label="规格" align="center" prop="goodsSpecName">
        </el-table-column>
        <el-table-column label="总数量" align="center" prop="totalQuantity">
        </el-table-column>
        <el-table-column
          label="总数量单位"
          align="center"
          prop="totalQuantityUnit"
        >
          <template slot-scope="scope">
            {{ dict.sys_user_count_unit[scope.row.totalQuantityUnit] }}
          </template>
        </el-table-column>
        <el-table-column label="总重量" align="center" prop="totalWeight">
        </el-table-column>
        <el-table-column
          label="总重量单位"
          align="center"
          prop="totalWeightUnit"
        >
          <template slot-scope="scope">
            {{ dict.sys_user_weight_unit[scope.row.totalWeightUnit] }}
          </template>
        </el-table-column>
        <el-table-column label="净重" align="center" prop="netWeight">
        </el-table-column>
        <el-table-column label="净重单位" align="center" prop="netWeightUnit">
          <template slot-scope="scope">
            {{ dict.sys_user_weight_unit[scope.row.netWeightUnit] }}
          </template>
        </el-table-column>
        <el-table-column
          label="入库要求"
          align="center"
          prop="warehousingRequirements"
        >
          <template slot-scope="scope">
            <span>{{
              dict.warehousing_requirements[scope.row.warehousingRequirements]
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="是否带托入库"
          align="center"
          prop="isWarehousingWithTray"
        >
          <template slot-scope="scope">
            <span>{{
              dict.sys_user_count_unit[scope.row.isWarehousingWithTray]
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="全选" type="selection" width="55">
        </el-table-column>
      </el-table>
      <el-button style="margin-top: 50px" type="primary" @click="selectGoods"
        >选择商品</el-button
      >
    </dialogPage>
    <dialogPage taostTips="添加商品" ref="addShop" width="50%">
      <el-form
        :model="goodsData"
        label-width="100px"
        :rules="goodsDataRules"
        ref="goodsForm"
      >
        <el-form-item label="商品类型" prop="goodsTypeName">
          <el-input
            v-model="goodsData.goodsTypeName"
            placeholder="请输入商品类型"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品名称" prop="goodsName">
          <el-input
            v-model="goodsData.goodsName"
            placeholder="请输入商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品厂家" prop="goodsManufacturerName">
          <el-input
            v-model="goodsData.goodsManufacturerName"
            placeholder="请输入商品厂家"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品型号" prop="goodsModelName">
          <el-input
            v-model="goodsData.goodsModelName"
            placeholder="请输入商品型号"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品规格" prop="goodsSpecName">
          <el-input
            v-model="goodsData.goodsSpecName"
            placeholder="请输入商品规格"
          ></el-input>
        </el-form-item>
        <el-form-item label="规格单位" prop="ifShowUnit">
          <el-select
            style="width: 100%"
            v-model="goodsData.ifShowUnit"
            placeholder="请选择规格单位"
            @change="goodsSpecNameChange"
          >
            <el-option label="净重" value="2"></el-option>
            <el-option label="重量" value="1"></el-option>
            <el-option label="数量" value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button style="margin-top: 50px" type="primary" @click="userAddGoods"
        >申请添加</el-button
      >
    </dialogPage>
  </div>
</template>
<script>
import VXETable from "vxe-table";
import maps from "@/components/maps/index";
import taostStorehouse from "@/components/taostStorehouse.vue";
import {
  postWarehousing,
  goodsListAll,
  goodsTypeListAll,
  goodsAttrListAll,
  getTransportationDocuments,
  upDataWarehousingOrder,
} from "../api/warehouse.js";
import {
  getWarehousingOrder,
  isCustomerSignLogisticsContract,
  isCustomerSignPalletRentalContract,
  createWlht,
  createTpzlht,
  applyAddGoods,
} from "@/api/business/warehousingOrder";
export default {
  name: "CessNav",
  components: {
    taostStorehouse,
    maps,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          const date = new Date();
          return time.getTime() < date.getTime() - 8.64e7; //禁止选取当天之后的日期（不包括静止当天）
        },
      },
      goodsData: {},
      goodsDataRules: {
        goodsName: [
          {
            required: true,
            message: "商品名称不能为空",
          },
        ],
        goodsSpecName: [
          {
            required: true,
            message: "商品规格不能为空",
          },
        ],
        goodsTypeName: [
          {
            required: true,
            message: "商品分类不能为空",
          },
        ],
        goodsModelName: [
          {
            required: true,
            message: "商品型号不能为空",
          },
        ],
        goodsManufacturerName: [
          {
            required: true,
            message: "商品厂家不能为空",
          },
        ],
        ifShowUnit: [
          {
            required: true,
            message: "商品单位不能为空",
          },
        ],
      },
      dicts: [
        "sys_yes_no",
        "sys_user_count_unit",
        "sys_user_weight_unit",
        "warehousing_requirements",
      ],
      dictOption: {
        warehousing_requirements: {},
      },
      gridData: [],
      isCustomerSign: null,
      validRules: {
        goodsTypeId: [
          {
            required: true,
            message: "商品分类不能为空",
          },
        ],
        goodsId: [
          {
            required: true,
            message: "商品名称不能为空",
          },
        ],
        goodsManufacturerId: [
          {
            required: true,
            message: "商品厂家不能为空",
          },
        ],
        goodsModelId: [
          {
            required: true,
            message: "商品型号不能为空",
          },
        ],
        goodsSpecId: [
          {
            required: true,
            message: "规格不能为空",
          },
        ],
        totalQuantity: [
          {
            required: true,
            message: "总数量不能为空",
          },
          {
            pattern: /^\d+$/,
            message: "请输入数字",
          },
        ],
        totalQuantityUnit: [
          {
            required: true,
            message: "总数量单位不能为空",
          },
        ],
        totalWeight: [
          {
            required: true,
            message: "总重量不能为空",
          },
          {
            pattern: /^\d+$/,
            message: "请输入数字",
          },
        ],
        totalWeightUnit: [
          {
            required: true,
            message: "总重量单位不能为空",
          },
        ],
        netWeight: [
          {
            required: true,
            message: "净重不能为空",
          },
          {
            pattern: /^\d+$/,
            message: "请输入数字",
          },
        ],
        netWeightUnit: [
          {
            required: true,
            message: "净重单位不能为空",
          },
        ],
        warehousingRequirements: [
          {
            required: true,
            message: "入库要求不能为空",
          },
        ],
        isWarehousingWithTray: [
          {
            required: true,
            message: "是否带托入库不能为空",
          },
        ],
      },
      //商品分类集合
      goodsTypeList: [],
      //商品集合
      goodsList: [],
      // 厂家列表
      manufacturerList: [],
      //型号列表
      goodsModeList: [],
      //规格列表
      goodsSpecList: [],
      //仓位列表
      warehouseBinList: [],
      //司机表格
      driverTableData: [],
      //司机校验
      driverValidRules: {
        driverName: [
          {
            required: true,
            message: "司机名称不能为空",
          },
          {
            min: 1,
            max: 20,
            message: "司机名称长度在 1 到 20 个",
          },
        ],
        driverPhone: [
          {
            required: true,
            message: "司机联系方式不能为空",
          },
          {
            pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
            message: "请输入正确的手机号",
          },
        ],
        driverIdCard: [
          {
            required: true,
            message: "司机身份证号不能为空",
          },
          {
            pattern:
              /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/,
            message: "请输入正确的身份证号",
          },
        ],
        carNumber: [
          {
            required: true,
            message: "车牌号不能为空",
          },
          {
            pattern:
              /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z][A-HJ-NP-Z0-9]{4,5}[A-HJ-NP-Z0-9挂学警港澳]$/,
            message: "请输入正确的车牌号",
          },
        ],
      },
      bannerHeight: "",
      sessiontext: "基本信息",
      shoptext: "商品信息",
      goodstext: "提货信息",
      tableData: [],
      ruleForm: {
        warehouseId: "",
        warehouseName: "",
        driverList: [],
        goodsDetailRecordList: [],
      },
      rules: {
        estimatedArrivalTime: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        isNeedPlatformDriver: [
          {
            required: true,
            message: "请选择是否平台司机",
            trigger: "change",
          },
        ],
        deliveryAddress: {
          required: true,
          message: "请选择/填写提货详细地址",
          trigger: "change",
        },
        warehouseName: [
          {
            required: true,
            message: "请选择一个仓库",
            trigger: "blur",
          },
        ],
      },
      dict: {},
      selectGoodsList: [],
      activeDriverList: {},
      deepGridData: null,
      isSignPalletRental: null,
    };
  },
  computed: {},
  created() {
    //获取商品分类集合
    this.goodsTypeListAll();
    this.getunitType();
    this.isCustomerSignLogisticsContract();
    this.isCustomerSignPalletRentalContract();
    if (this.$route.query.orderId) {
      this.getInfo();
    }
    if (this.$route.query.warehouseId) {
      this.ruleForm.warehouseName = this.$route.query.warehouseName;
      this.ruleForm.warehouseId = this.$route.query.warehouseId;
    }
  },
  methods: {
    goodsSpecNameChange(val) {
      if (val == "0") {
        this.goodsData.isShowTotalQuantity = "1";
      } else if (val == "1") {
        this.goodsData.isShowTotalWeight = "1";
      } else if (val == "2") {
        this.goodsData.isShowNetWeight = "1";
      }
    },
    userAddGoods() {
      this.$refs.goodsForm.validate((flag) => {
        if (flag) {
          applyAddGoods(this.goodsData).then((res) => {
            this.$message.success("申请成功!");
            this.$refs.addShop.closeDialog();
          });
        }
      });
    },
    createTpzlht() {
      createTpzlht().then((res) => {
        window.open(res.data.msg);
      });
    },
    createWlht() {
      createWlht().then((res) => {
        window.open(res.data.msg);
      });
    },
    isCustomerSignPalletRentalContract() {
      isCustomerSignPalletRentalContract().then((res) => {
        this.isSignPalletRental = res.data.data;
      });
    },
    isCustomerSignLogisticsContract() {
      isCustomerSignLogisticsContract().then((res) => {
        this.isCustomerSign = res.data.data;
      });
    },
    getInfo() {
      getWarehousingOrder(this.$route.query.orderId).then((response) => {
        this.ruleForm = response.data.data;
        this.ruleForm.goodsDetailRecordList.map((item, index) => {
          this.$set(
            item,
            "warehousingRequirements",
            item.warehousingRequirements.toString()
          );
          goodsListAll({
            goodsType: item.goodsTypeId,
          }).then((res) => {
            item.goodsList = res.data.data;
          });
          goodsAttrListAll({
            goodsId: item.goodsId,
            parentId: 0,
          }).then((res) => {
            item.manufacturerList = res.data.data;
          });
          goodsAttrListAll({
            goodsId: item.goodsId,
            parentId: item.goodsManufacturerId,
          }).then((res) => {
            item.goodsModeList = res.data.data;
          });
          goodsAttrListAll({
            goodsId: item.goodsId,
            parentId: item.goodsModelId,
          }).then((res) => {
            item.goodsSpecList = res.data.data;
          });
          if (item.totalQuantity) {
            item.ifShowUnit = 0;
          }
          if (item.totalWeight) {
            item.ifShowUnit = 1;
          }
          if (item.netWeight) {
            item.ifShowUnit = 2;
          }
          item.inventoryVOList = item.inventoryRecordList;
          item.subscriptn = index;
        });

        if (this.ruleForm.driverRecordList) {
          this.driverTableData = this.ruleForm.driverRecordList.map((item) => {
            let arr = item.goodsList.map((item) => {
              let data = response.data.data.goodsDetailRecordList.find(
                (child) => {
                  return child.id == item.warehousingOrderGoodsDetailId;
                }
              );
              if (data) {
                return {
                  ...data,
                  weight: item.totalWeight,
                  weightUnit: item.totalWeightUnit,
                  quantity: item.totalQuantity,
                  quantityUnit: item.totalQuantityUnit,
                };
              }
              return data;
            });
            return {
              driverIdCard: item.driverRecord.driverIdCard,
              driverName: item.driverRecord.driverName,
              driverPhone: item.driverRecord.driverPhone,
              carNumber: item.carNumber,
              warehousingOrderDriverId: item.warehousingOrderDriverId,
              driverGoodsList: arr,
            };
          });
        }
      });
    },
    totalChange(row) {
      let total = 0;
      if (row.netWeight) {
        total = row.netWeight;
      }
      if (row.totalQuantity) {
        total = row.totalQuantity;
      }
      if (row.totalWeightUnit) {
        total = row.totalWeight;
      }
      if (Number(row.total) > Number(total)) {
        this.$message.warning("运输量不能大于剩余运输量");
        row.total = total;
        return;
      }
    },
    handleSelectGoods(row) {
      this.selectGoodsList = JSON.parse(JSON.stringify(row));
    },
    deteleConfirmGoods(row) {
      this.$confirm("是否删除该条数据？", "提示", { type: "warning" }).then(
        (res) => {
          this.$delete(this.selectGoodsList, this.selectGoodsList.indexOf(row));
        }
      );
    },
    selectGoods() {
      this.$refs.selectShop.closeDialog();
      this.$refs.confirmGoods.showDialog();
    },
    confirmGoods(row) {
      let item = this.selectGoodsList.find((item) => item.total);
      if (!item) {
        this.$message.warning("输入司机运输量");
        return;
      }
      let driverGoodsList = this.selectGoodsList;
      // let driverGoodsList = this.selectGoodsList.filter((item) => {
      //   return item.total;
      // });
      console.log(driverGoodsList);
      this.activeDriverList.driverGoodsList = JSON.parse(
        JSON.stringify(driverGoodsList)
      );
      this.selectGoodsList = [];
      this.$refs.confirmGoods.closeDialog();
    },
    addGoods(row) {
      if (!this.$refs.xTable.tableData.length) {
        this.$message.warning("请先添加商品");
        return;
      }
      this.gridData = this.$refs.xTable.tableData.map((item, index) => {
        return {
          ...item,
          subscriptn: index,
        };
      });
      this.activeDriverList = row;
      if (row.driverGoodsList) {
        row.driverGoodsList.map((item, index) => {
          item.totalWeight = this.gridData[item.subscriptn].totalWeight;
          item.totalQuantity = this.gridData[item.subscriptn].totalQuantity;
          item.netWeight = this.gridData[item.subscriptn].netWeight;
        });
        this.$set(
          this,
          "selectGoodsList",
          JSON.parse(JSON.stringify(row.driverGoodsList))
        );
      } else {
        this.$set(
          this,
          "selectGoodsList",
          JSON.parse(JSON.stringify(this.gridData))
        );
      }
      this.$refs.confirmGoods.showDialog();
    },
    //清空表单
    clearForm() {
      this.ruleForm = {
        warehouseId: "",
        warehouseName: "",
        driverList: [],
        goodsDetailList: [],
        customerId: 0,
        deliveryAddress: null,
        deliveryAddressLatitude: "",
        deliveryAddressLongitude: "",
        deliveryContact: "",
        deliveryContactPhone: "",
        deliveryVoucherPic: "",
        estimatedArrivalTime: "",
        isNeedPlatformDriver: "",
        remark: "",
      };
      this.tableData = [];
      this.goodsList = [];
    },
    //提交表单
    async submitFrom() {
      let xTable = this.$refs.xTable.getTableData().fullData;
      if (xTable.length == 0) {
        this.$message.warning("请添加商品");
        return;
      }

      let goods = xTable.find((item) => {
        return item.weight && item.netWeight && item.quantity;
      });
      if (goods) {
        this.$message.warning("请将商品数量补充完整！");
        return;
      }
      let isCustomerSign = await this.isCustomerSignLogisticsContract();
      if (this.ruleForm.isNeedPlatformDriver == 1 && !this.isCustomerSign) {
        this.$message.warning("请签署物流合同");
        return;
      }
      let isSignPalletRental = await this.isCustomerSignPalletRentalContract();
      let find = xTable.find((item) => item.isWarehousingWithTray == 1);
      if (find && !this.isSignPalletRental) {
        this.$message.warning("请签署托盘协议");
        return;
      }

      if (
        !this.ruleForm.deliveryAddress &&
        this.ruleForm.isNeedPlatformDriver == 1
      ) {
        this.$message.info("请“确认”提货地址");
        return;
      }
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.ruleForm.goodsDetailList = xTable;
          this.boxSub();
        } else {
          this.$message.warning("请核对填入信息");
        }
      });
    },
    boxSub() {
      if (this.ruleForm.isNeedPlatformDriver == "0") {
        if (this.$refs.driverTable.getTableData().fullData.length > 0) {
          let tables = this.$refs.driverTable.getTableData().fullData;
          this.ruleForm.driverList = tables.map((item) => {
            item.driverGoodsList = item.driverGoodsList.map((child) => {
              return {
                ...child,
                totalQuantity: child.totalQuantityUnit ? child.total : "",
                totalWeight: child.totalWeightUnit ? child.total : "",
                netWeight: child.netWeightUnit ? child.total : "",
              };
            });
            return item;
          });
        } else {
          this.$message({
            message: "请填写自备司机信息",
            type: "warning",
          });
          return false;
        }
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.$route.query.orderId) {
        upDataWarehousingOrder(this.ruleForm)
          .then((response) => {
            if (response.data.code == 200) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.clearForm();
              this.$router.go(-1);
            } else {
              this.$message.error("修改失败！");
            }
          })
          .finally(() => {
            loading.close();
          });
      } else {
        postWarehousing(this.ruleForm)
          .then((response) => {
            if (response.data.code == 200) {
              this.$message({
                message: "提交成功！",
                type: "success",
              });
              this.clearForm();
              this.$router.go(-1);
            } else {
              this.$message.error("预约失败！");
            }
          })
          .finally(() => {
            loading.close();
          });
      }
    },
    //商品表格 删除行
    async shopRemoveRowEvent(row) {
      const type = await VXETable.modal.confirm("您确定要删除该数据?");
      const $table = this.$refs.xTable;
      if (type === "confirm") {
        $table.remove(row);
      }
    },
    //司机表格 删除行
    async removeRowEvent(row) {
      const type = await VXETable.modal.confirm("您确定要删除该数据?");
      const $table = this.$refs.driverTable;
      if (type === "confirm") {
        $table.remove(row);
      }
    },
    //选择提货地址
    costPlannedAmountChange(value) {
      // this.ruleForm.deliveryAddress = `${value.district}${value.address}`;
      this.$set(
        this.ruleForm,
        "deliveryAddress",
        `${value.district}${value.address}`
      );
      this.ruleForm.deliveryAddressLongitude = value.location.lng;
      this.ruleForm.deliveryAddressLatitude = value.location.lat;
    },
    //录入商品信息
    async insertEvent() {
      const $table = this.$refs.xTable;
      const { row: newRow } = await $table.insert();
      // 插入一条数据并触发校验
      const errMap = await $table.validate(newRow).catch((errMap) => errMap);
      if (errMap) {
        // 校验不通过
      }
    },
    async driverInsertEvent() {
      const $table = this.$refs.driverTable;
      const { row: newRow } = await $table.insert();
      // 插入一条数据并触发校验
      const errMap = await $table.validate(newRow).catch((errMap) => errMap);
      if (errMap) {
        // 校验不通过
      }
    },
    //获取商品分类集合
    goodsTypeListAll() {
      goodsTypeListAll().then((response) => {
        this.goodsTypeList = response.data.data;
        this.goodsList = [];
        this.manufacturerList = [];
        this.goodsModeList = [];
        this.goodsSpecList = [];
      });
    },
    //获取商品集合
    goodsListAll({ row }) {
      Object.keys(row).forEach((item) => {
        if (item != "goodsTypeId" && item != "_XID") {
          row[item] = null;
        }
      });
      let typeObj = {};
      this.goodsTypeList.forEach((item) => {
        if (row.goodsTypeId == item.id) {
          typeObj = item;
        }
      });
      if (typeObj.isShowTotalQuantity == "1") {
        row.ifShowUnit = 0;
      }
      if (typeObj.isShowTotalWeight == "1") {
        row.ifShowUnit = 1;
      }
      if (typeObj.isShowNetWeight == "1") {
        row.ifShowUnit = 2;
      }
      let data = {
        goodsType: row.goodsTypeId,
      };
      const item = this.goodsTypeList.find(
        (child) => child.id === row.goodsTypeId
      );
      row.goodsTypeName = item.typeName;
      row.goodsTypeName;
      goodsListAll(data).then((response) => {
        row.goodsList = response.data.data;
        row.manufacturerList = [];
        row.goodsModeList = [];
        row.goodsSpecList = [];
        this.clearGoodsTypeId({
          row,
        });
      });
    },
    warehouseSelect(e) {
      this.ruleForm.warehouseName = e.warehouseName;
      this.ruleForm.warehouseId = e.id;
      this.$refs.company.closeDialog();
    },
    // 厂家商品属性管理列表
    manufacturerListAll({ row }) {
      let data = {
        goodsId: row.goodsId,
        parentId: 0,
      };
      row.goodsName = row.goodsList.find((e) => e.id === row.goodsId).goodsName;
      goodsAttrListAll(data).then((response) => {
        row.manufacturerList = response.data.data;
        row.goodsModeList = [];
        row.goodsSpecList = [];
        this.clearGoodId({
          row,
        });
      });
    },
    goodsSpecChange(row) {
      row.goodsSpecName = row.goodsSpecList.find(
        (e) => e.id === row.goodsSpecId
      ).attrName;
    },
    // 规格商品属性管理列表
    goodsSpecListAll({ row }) {
      let data = {
        goodsId: row.goodsId,
        parentId: row.goodsModelId,
      };
      row.goodsModelName = row.goodsModeList.find(
        (e) => e.id === row.goodsModelId
      ).attrName;
      goodsAttrListAll(data).then((response) => {
        row.goodsSpecList = response.data.data;
      });
    },
    // 型号商品属性管理列表
    goodsModeListAll({ row }) {
      let data = {
        goodsId: row.goodsId,
        parentId: row.goodsManufacturerId,
      };
      //商品属性管理列表数据转换
      row.goodsManufacturerName = row.manufacturerList.find(
        (e) => e.id === row.goodsManufacturerId
      ).attrName;
      goodsAttrListAll(data).then((response) => {
        row.goodsSpecList = [];
        row.goodsModeList = response.data.data;
        this.clearGoodsManufacturerId({
          row,
        });
      });
    },
    clearGoodsTypeId({ row }) {
      row.goodsId = "";
      row.goodsSpecId = "";
      row.goodsManufacturerId = "";
      row.goodsModelId = "";
    },
    clearGoodId({ row }) {
      row.goodsManufacturerId = "";
      row.goodsModelId = "";
      row.goodsSpecId = "";
    },
    clearGoodsManufacturerId({ row }) {
      row.goodsSpecId = "";
      row.goodsModelId = "";
    },
    clearGoodsModelId({ row }) {
      row.goodsSpecId = "";
    },
    //获取字典
    getunitType() {
      this.dicts.map((item) => {
        this.getDicts(item).then((res) => {
          this.dictOption[item] = res.data.data;
          this.dict[item] = {};
          res.data.data.map((value) => {
            this.dict[item][value.dictValue] = value.dictLabel;
          });
        });
      });
    },
    clickDetail() {
      this.$refs.company.showDialog();
    },
  },
  mounted() {
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.bannerHeight = (600 / 1550) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (600 / 1550) * this.screenWidth;
    };
  },
};
</script>
<style scoped>
.box-textarea /deep/ textarea {
  width: 1000px;
  height: 270px;
}
.customer-sign {
  display: flex;
  justify-content: flex-end;
  width: 590px;
}
.box-form {
  margin-top: 60px;
}

.box-form /deep/ .el-select {
  width: 590px;
}

.box-form /deep/ .el-input {
  width: 590px;
}

.box-addBtn {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  background: #40b8ad;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 40px;
  cursor: pointer;
}

.box-table {
  margin-top: 30px;
}

.box-allweight {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  margin-top: 40px;
  text-align: left;
}

.li-top {
  margin-top: 80px;
}

.agreeOn-success {
  width: 450px;
  height: 62px;
  line-height: 62px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 31px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 158px;
  margin-bottom: 140px;
  cursor: pointer;
}

.carouselImg {
  width: 100%;
  height: 100%;
}

/deep/ .el-carousel--horizontal {
  max-height: 780px;
}

/deep/ .el-carousel__container {
  max-height: 780px;
}

::v-deep.vxe-header--column ::v-deep.vxe-cell--edit-icon {
  display: none !important;
}
</style>
