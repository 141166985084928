<template>
	<div class="box-title-tip">
		<div class="verticalLine"></div>
		<span>{{titleText}}</span>
	</div>
</template>

<script>
	export default {
		name: 'titlePage',
		components: {},
		props: {
			titleText:{
				type:String,
				default:''
			}
		},
		data() {
			return {}
		},
		methods: {},
		mounted() {}
	}
</script>

<style scoped>
	.box-title-tip{
		height: 25px;
	}
	.box-title-tip span {
		font-size: 24px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #333333;
		margin-left: 15px;
		float: left;
		height: 25px;
		line-height: 25px;
	}

	.verticalLine {
		float: left;
		width: 5px;
		height: 25px;
		background: #41B5AC;
		border-radius: 3px;
	}
</style>