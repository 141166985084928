import http from '../utils/http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
// let resquest = "http://192.168.0.21:8082"

// 获取仓库列表
export function getPageList(params) {
	return http.get('/business/warehouse/list', params)
}
//获取推荐仓库列表
export function getRecommendList(params) {
	return http.get('/business/warehouse/getRecommendList', params)
}
// 获取仓库详情
export function getDetails(id) {
	return http.get('/business/warehouse/' + id)
}

// 获取仓库分类列表
export function getPageTypeList(id) {
	return http.get('/business/warehouseType/list')
}
//预约出库
export function postWarehousing(params) {
	return http.post('/business/outboundOrder/reservationOutbound', params)
}
//出库商品列表
export function outShopLits(params) {
	return http.get('/business/outboundOrder/getInventoryGoodsListGroupCargoNumber', params)
}
//出库商品关联订单列表
export function outOrderLits(params) {
	return http.get('/business/outboundOrder/getInventoryGoodsList', params)
}
export function getInventoryGoodsListGroupCargoNumber(params) {
	return http.get('/business/outboundOrder/getInventoryGoodsListGroupCargoNumber', params)
}

// 查询商品分类集合
export function goodsTypeListAll(params) {
	return http.get('/business/goodsType/listAll', params)
}

// 查询商品分类集合
export function goodsListAll(params) {
	return http.get('/business/goods/listAll', params)
}

// 查询商品属性管理列表
export function goodsAttrListAll(params) {
	return http.get('/business/goodsAttr/list', params)
}
//在库商品列表
export function libraryShop(params) {
	return http.get('/business/outboundOrder/getInventoryGoodsList', params)
}

export function updateOutboundOrder(params) {
	return http.post('/business/outboundOrder/updateOutboundOrder', params)
}
