<template>
  <div>
    <template>
      <div class="scrollImg">
        <img ref="image" class="carouselImg" src="../assets/ckimg.jpg" />
      </div>
      <div class="box-content">
        <div class="box-select-type">
          <div class="type-lists">
            <div class="type-name">商品品类:</div>
            <div class="type-text">
              <span
                :class="shopTypeIndex == index ? 'active' : ''"
                v-for="(item, index) in shopType"
                @click="selecttype(index, 0)"
                :key="index"
                >{{ item.typeName }}</span
              >
            </div>
          </div>
          <div class="type-lists">
            <div class="type-name">回购天数:</div>
            <div class="type-text">
              <span
                :class="dayNumIndex == index ? 'active' : ''"
                v-for="(item, index) in dayNum"
                @click="selecttype(index, 1)"
                :key="index"
                >{{ item.name }}</span
              >
            </div>
          </div>
          <div class="type-lists">
            <el-dropdown>
              <div>
                <span class="type-name">{{ typeName.label }}:</span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) of typeList" :key="index"
                  ><div @click="typeName = item">
                    {{ item.label }}
                  </div></el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>

            <div class="type-text">
              <span
                :class="weightIndex == index ? 'active' : ''"
                v-for="(item, index) in typeName.value == 3 ? quantity : weight"
                @click="selecttype(index, 2)"
                :key="index"
                >{{ item.name }}</span
              >
            </div>
          </div>
          <div class="type-lists">
            <div class="type-name">排序:</div>
            <div class="type-text">
              <span
                :class="sortListIndex == index ? 'active' : ''"
                v-for="(item, index) in sortList"
                @click="selecttype(index, 3)"
                :key="index"
                >{{ item.name }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- 入库单 -->
      <transition name="el-fade-in">
        <div class="box-content">
          <div class="box-table">
            <el-table
              :data="tableData"
              style="width: 100%"
              :header-cell-style="{
                background: '#F2F2F2',
                'text-align': 'center',
              }"
              border
              width="80"
            >
              <el-table-column
                label="转让方"
                align="center"
                prop="transferorName"
              >
              </el-table-column>
              <el-table-column
                label="商品品类"
                align="center"
                prop="goodsTypeLabel"
              >
              </el-table-column>
              <el-table-column
                label="商品名称"
                align="center"
                prop="goodsLabel"
              >
              </el-table-column>
              <el-table-column
                label="厂家"
                align="center"
                prop="goodsManufacturerLabel"
              >
              </el-table-column>
              <el-table-column
                label="型号"
                align="center"
                prop="goodsModelLabel"
              >
              </el-table-column>
              <el-table-column
                label="规格"
                align="center"
                prop="goodsSpecLabel"
              >
              </el-table-column>
              <el-table-column
                label="回购数量"
                align="center"
                prop="totalQuantity"
              >
              </el-table-column>
              <el-table-column label="单位" align="center">
                <template slot-scope="scope">
                  {{ dict.sys_user_count_unit[scope.row.totalQuantityUnit] }}
                </template>
              </el-table-column>
              <el-table-column
                label="回购重量"
                align="center"
                prop="totalWeight"
              >
                <template slot-scope="scope">
                  {{ scope.row.totalWeight }}
                </template>
              </el-table-column>
              <el-table-column
                label="单位"
                align="center"
                prop="totalWeightUnit"
              >
                <template slot-scope="scope">
                  {{ dict.sys_user_weight_unit[scope.row.totalWeightUnit] }}
                </template>
              </el-table-column>
              <el-table-column label="回购净重" align="center" prop="netWeight">
                <template slot-scope="scope">
                  {{ scope.row.netWeight }}
                </template>
              </el-table-column>
              <el-table-column label="单位" align="center" prop="netWeightUnit">
                <template slot-scope="scope">
                  {{ dict.sys_user_weight_unit[scope.row.netWeightUnit] }}
                </template>
              </el-table-column>
              <el-table-column label="单价" align="center" prop="unitPrice">
              </el-table-column>
              <el-table-column
                label="发布时间"
                align="center"
                prop="releaseTime"
              >
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <div class="table_button" @click="navTo(scope.row)">接单</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="box-pagination">
            <PaginationPage
              v-show="total > 0"
              :total="total"
              :page.sync="queryData.pageNum"
              :limit.sync="queryData.pageSize"
              @pagination="getData"
            ></PaginationPage>
          </div>
        </div>
      </transition>
    </template>
  </div>
</template>
<script>
import { buyBackOrder } from "@/api/business/buyBack";
import { goodsTypeListAll } from "@/api/warehouse";
export default {
  name: "CessNav",
  components: {},
  data() {
    return {
      dicts: ["sys_user_count_unit", "sys_user_weight_unit"],
      dict: { sys_user_count_unit: {}, sys_user_weight_unit: {} },
      total: 0,
      tableData: [],
      typeName: {
        label: "重量",
        value: 1,
      },
      typeList: [
        {
          label: "重量",
          value: 1,
        },
        {
          label: "净重",
          value: 2,
        },
        {
          label: "数量",
          value: 3,
        },
      ],
      queryData: {
        pageNum: 1,
        pageSize: 10,
      },
      activeName: "1",
      bannerHeight: "",
      shopTypeIndex: 0,
      dayNumIndex: 0,
      weightIndex: 0,
      sortListIndex: 0,
      shopType: [{ typeName: "全部", id: null }],
      dayNum: [
        {
          name: "全部",
          value: null,
        },
        {
          name: "> 30天",
          value: 2,
        },
        {
          name: "<= 30天",
          value: 1,
        },
      ],
      weight: [
        {
          name: "全部",
          value: null,
        },
        {
          name: "<= 10吨",
          value: 1,
        },
        {
          name: "> 10吨且< 30吨",
          value: 2,
        },
        {
          name: "> 30吨且 <= 100吨",
          value: 3,
        },
        {
          name: "> 100吨",
          value: 4,
        },
      ],
      quantity: [
        {
          name: "全部",
          value: null,
        },
        {
          name: "<= 100",
          value: 1,
        },
        {
          name: "> 100且< 300",
          value: 2,
        },
        {
          name: "> 300且 <= 1000",
          value: 3,
        },
        {
          name: "> 1000",
          value: 4,
        },
      ],

      sortList: [
        {
          name: "默认排序",
          value: null,
        },
        {
          name: "最新时间",
          value: 1,
        },
        {
          name: "单价从高到底",
          value: 2,
        },
        {
          name: "回购天数从高到底",
          value: 3,
        },
      ],
    };
  },
  computed: {},
  created() {
    this.getData();
    this.getGoodsType();
    this.getunitType();
  },
  methods: {
    navTo(row) {
      this.$router.push({
        path: "/byBackDetails",
        query: {
          id: row.buyBackOrderId,
          isTransferor: false,
        },
      });
    },
    getunitType() {
      this.dicts.map((item) => {
        this.getDicts(item).then((res) => {
          this.dict[item] = {};
          res.data.data.map((value) => {
            this.dict[item][value.dictValue] = value.dictLabel;
          });
        });
      });
    },
    getData() {
      buyBackOrder(this.queryData).then((res) => {
        this.tableData = res.data.data.list;
        this.total = res.data.data.total;
      });
    },
    getGoodsType() {
      goodsTypeListAll().then((res) => {
        this.shopType = this.shopType.concat(res.data.data);
      });
    },
    selecttype(index, e) {
      if (e == 0) {
        this.shopTypeIndex = index;
        this.queryData.goodsTypeId = this.shopType[index].id;
      } else if (e == 1) {
        this.dayNumIndex = index;
        this.queryData.queryRepurchaseDays = this.dayNum[index].value;
      } else if (e == 2) {
        this.weightIndex = index;
        this.queryData.queryWeight = null;
        this.queryData.queryNetWeight = null;
        this.queryData.queryQuantity = null;
        if (this.typeName.value == 1) {
          this.queryData.queryWeight = this.weight[index].value;
        } else if (this.typeName.value == 2) {
          this.queryData.queryNetWeight = this.weight[index].value;
        } else if (this.typeName.value == 3) {
          this.queryData.queryQuantity = this.quantity[index].value;
        }
      } else if (e == 3) {
        this.sortListIndex = index;
        this.queryData.sortCriteria = this.sortList[index].value;
      }
      this.getData();
    },
  },
  mounted() {
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.bannerHeight = (600 / 1550) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (600 / 1550) * this.screenWidth;
    };
  },
};
</script>
<style scoped>
.table_button {
  height: 38px;
  text-align: center;
  line-height: 38px;
  background: #40b8ad;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}
.type-lists {
  display: flex;
  border-bottom: 1px dashed #d6d6d6;
}

.type-text {
  display: flex;
  align-items: center;
  width: calc(100% - 150px);
  overflow: scroll;
  overflow-y: hidden;
}
.type-text::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0);
}
.type-text::-webkit-scrollbar:hover {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.1);
}
.type-text::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
  background-color: #40b8ad;
  border-radius: 5px;
}

.type-text span {
  padding: 9px 13px;
  text-wrap: nowrap;
  border-radius: 5px;
  color: #666666;
  font-size: 18px;
  font-family: PingFang SC;
  cursor: pointer;
  margin-left: 20px;
}

.type-text .active {
  background: #40b8ad;
  color: #ffffff !important;
}

.type-name {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: 70px;
  margin-left: 40px;
  text-align: right;
}

.box-select-type {
  background: #f4f4f4;
  height: auto;
  margin-top: 60px;
}

.box-addBtn {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  background: #40b8ad;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
  cursor: pointer;
}

.box-table {
  margin-top: 30px;
}

.edit-content {
  float: right;
  margin-right: 30px;
  display: flex;
  margin-top: 30px;
}

.re-certification {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #40b8ad;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-left: 20px;
  cursor: pointer;
}

.personal-edit {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #ffffff;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #40b8ad;
  cursor: pointer;
}

.personal-phone {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 16px;
  text-align: left;
}

.personal-name {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  margin-top: 16px;
  text-align: left;
}

.personal-corporation {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 72px;
  text-align: left;
}

.personal-corporation span {
  margin-left: 20px;
  padding: 4px 6px;
  background: #ffffff;
  border: 1px solid #ff9000;
  border-radius: 5px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ff9000;
}

.personal-text {
  margin-left: 30px;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-tabs__header {
  margin: 0;
}

/deep/ .el-tabs__content {
  display: none;
}

/deep/ .el-tabs__item {
  height: 90px;
  line-height: 90px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

/deep/ .el-tabs {
  float: right;
}

.box-tabs {
  height: 90px;
  background: #f6f6f6;
}

.box-select-submit {
  display: block;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 100px;
  width: 160px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 24px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

.box-select-submit:hover {
  opacity: 0.8;
}

.card-address {
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  text-align: left;
  margin-top: 10px;
}

.card-title {
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  text-align: left;
}

.card-img {
  width: 100%;
  height: 246px;
}

.box-card {
  width: 380px;
  height: 340px;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-bottom: 30px;
  transition: all 0.3s;
  /* 上浮这个过程需要的时间 */
  cursor: pointer;
}

.box-card:hover {
  box-shadow: 0px 0px 10px 0px rgba(65, 181, 172, 0.35);
  transform: translate(0, -3px);
}

.box-card-content {
  padding: 20px;
  height: 54px;
}

.box-tab-card {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
}

.box-card-title {
  text-align: center;
  height: 42px;
  line-height: 42px;
  font-size: 42px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231714;
  margin-top: 167px;
}

.borderShare {
  border-right: 1px dashed #eeeeee;
}

.box-tab {
  text-align: center;
  width: 25%;
  box-sizing: border-box;
  cursor: pointer;
}

.box-tab span {
  display: block;
  text-align: center;
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  margin-top: 10px;
}

.box-tab img {
  display: block;
  width: 92px;
  margin: 0 auto;
  margin-top: 13px;
}

.box-fixed-tab {
  position: relative;
  margin: 0 auto;
  margin-top: -88px;
  width: 1200px;
  height: 150px;
  padding: 13px 0;
  background: #ffffff;
  box-shadow: 0px -6px 24px 0px rgba(65, 181, 172, 0.35);
  z-index: 99;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.carouselImg {
  width: 100%;
  height: inherit;
}

.scrollImg {
  width: 100%;
  height: auto;
  /* max-height: 780px; */
  position: relative;
}

/deep/ .el-carousel--horizontal {
  max-height: 780px;
}

/deep/ .el-carousel__container {
  max-height: 780px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.demo {
  display: inline-block;
  width: 600px;
  height: 338px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
</style>
