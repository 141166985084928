<template>
  <div class="taostBackground" v-if="taostShow">
    <div class="taost" :style="{ width: width }">
      <div class="taost-title">
        <span class="taost-tips">{{ taostTips }}</span>
        <span class="taost-close" @click="taostShow = false"
          ><img src="../assets/taostClose.png" alt="" srcset=""
        /></span>
      </div>
      <div class="taost-content">
        <slot name="content"></slot>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "taostPage",
  components: {},
  props: {
    width: {
      type: String,
      default: "600px",
    },
    taostContent: {
      type: String,
      default: "测试的内容数据",
    },
    taostBtnName: {
      type: String,
      default: "我点击",
    },
    taostTips: {
      type: String,
      default: "选择商品",
    },
  },
  data() {
    return {
      taostShow: false,
      tableData: [],
      ruleForm: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
    };
  },
  methods: {
    addShop() {
      this.$refs.addShop.showDialog();
    },
    clickDetail() {
      console.log("点击搜索");
    },
    closeDialog() {
      this.taostShow = false;
    },
    showDialog() {
      this.taostShow = true;
    },
    choseTab(e) {
      //点击tab
      var indexBefore = 0,
        indexNow = 0,
        tabidnow = 0; //需要移动的宽
      this.tabList.forEach((item, index) => {
        if (item.id == this.tabChose) {
          indexBefore = index; //之前的下标
        }
        if (e.id == item.id) {
          indexNow = index;
        }
      });
      this.tabChose = e.id; //赋值当前选中的值
      for (let i = indexBefore; i < indexNow; i++) {
        //循环中间间隔了多少
        tabidnow +=
          document.getElementById("tab" + this.tabList[i].id).clientWidth + 50;
        if (i == indexNow - 1) {
          this.tabIndex -= tabidnow;
        }
      }
    },
    tabLeft() {
      //左箭头切换
      if (this.tabChose != 0) {
        //判断是否是初始位置
        var tabid = "";
        this.tabList.forEach((item, index) => {
          if (item.id == this.tabChose) {
            tabid = "tab" + this.tabList[index - 1].id;
          }
        });
        this.tabChose = tabid.replace(/tab/g, "");
        this.tabIndex += document.getElementById(tabid).clientWidth + 10;
        //内容往左边移动上一个div的宽
      }
    },
    tabRight() {
      //右箭头切换
      var tabid = "",
        tabidnow = "tab" + this.tabChose;
      this.tabList.forEach((item, index) => {
        if (item.id == this.tabChose) {
          tabid = "tab" + this.tabList[index + 1].id;
        }
      });
      this.tabChose = tabid.replace(/tab/g, "");
      this.tabIndex -= document.getElementById(tabidnow).clientWidth + 10;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.box-shop-text {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  text-align: center;
}

.box-shop-text span {
  color: #41b5ac;
  cursor: pointer;
}

.box-shop-img {
  display: block;
  width: 232px;
  height: 210px;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 90px;
}

.box-shop-none {
}

.box-table {
  margin-top: 30px;
}

.warp-content {
  height: auto;
  overflow: hidden;
}

.card-address {
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  text-align: left;
  margin-top: 10px;
}

.card-title {
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  text-align: left;
}

.card-img {
  width: 100%;
  height: 246px;
}

.box-card {
  width: 380px;
  height: 337px;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-bottom: 30px;
  transition: all 0.3s;
  /* 上浮这个过程需要的时间 */
  cursor: pointer;
}

.box-card:hover {
  box-shadow: 0px 0px 10px 0px rgba(65, 181, 172, 0.35);
  transform: translate(0, -3px);
}

.box-card-content {
  padding: 20px;
  height: 50px;
}

.box-tab-card {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 28px;
}

.box-card-title {
  text-align: center;
  height: 42px;
  line-height: 42px;
  font-size: 42px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231714;
  margin-top: 167px;
}

.box-city {
  float: left;
}

.box-city span {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}

.el-icon-arrow-left {
  position: absolute;
  left: -36px;
  top: 10px;
  font-size: 20px;
}

.el-icon-arrow-right {
  position: absolute;
  right: -36px;
  top: 10px;
  font-size: 20px;
}

.wrap {
  float: right;
  margin-right: 36px;
  width: 342px;
  position: relative;
}

.navBox {
  width: 342px;
  background: #ffffff;
  position: relative;
  overflow: hidden;
}

.tabBox {
  padding: 0 12px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  background: #eaeaea;
  float: left;
  margin-right: 10px;
}

.tabBox:hover {
  cursor: pointer;
}

.tabChose {
  background: #40b8ad;
  color: #ffffff;
}

.good_city {
  width: 9999px;
  height: 40px;
  transition: all 0.5s;
}

.contrlBox {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 80px;
  height: 80px;
  background: #ffffff;
}

.contrlBox i {
  font-size: 18px;
  flex-wrap: bold;
}

.contrlBox i:hover {
  cursor: pointer;
}

.taost-success {
  width: 246px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 35px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  position: absolute;
  bottom: 65px;
  left: 50%;
  margin-left: -123px;
  cursor: pointer;
}

.taost-content {
  padding: 30px;
  height: auto;
}

.taost-title {
  width: 100%;
  height: 80px;
  background: #f2f2f2;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.taost-tips {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  height: 80px;
  line-height: 80px;
  margin-left: 20px;
}

.taost-close {
  width: 34px;
  height: 34px;
  margin-top: 23px;
  margin-right: 20px;
  cursor: pointer;
}

.taost-close img {
  width: 34px;
  height: 34px;
}

.taostBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.taost {
  /* position: absolute;
		top: 50%;
		margin-top: -457px;
		left: 50%;
		margin-left: -425px;
		width: 850px;
		height: 914px; */
  background: #ffffff;
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
}
</style>
