import request from '@/utils/request'

// 回购发布
export function releaseBuyBackOrder(data) {
  return request({
    url: '/business/buyBackOrder/releaseBuyBackOrder',
    method: 'POST',
    data
  })
}

export function getTransferorBuyBackOrderList(params) {
  return request({
    url: '/business/buyBackOrder/getTransferorBuyBackOrderList',
    method: 'GET',
    params
  })
}
export function getReceiverBuyBackOrderList(params) {
  return request({
    url: '/business/buyBackOrder/getReceiverBuyBackOrderList',
    method: 'GET',
    params
  })
}
export function getBuyBackOrderDetail(id) {
  return request({
    url: '/business/buyBackOrder/getBuyBackOrderDetail/' + id,
    method: 'POST',
  })
}
export function buyBackOrder(params) {
  return request({
    url: "business/buyBackOrder/pendingRepurchaseOrdersList",
    method: 'GET',
    params
  })
}

export function transferorCancelBuyBackOrder(buyBackOrderId) {
  return request({
    url: "/business/buyBackOrder/transferorCancelBuyBackOrder/" + buyBackOrderId,
    method: 'POST',
  })
}
export function receiverReceiving(buyBackOrderId) {
  return request({
    url: "/business/buyBackOrder/receiverReceiving/" + buyBackOrderId,
    method: 'POST',
  })
}

export function receiverCancelBuyBackOrder(buyBackOrderId) {
  return request({
    url: "/business/buyBackOrder/receiverCancelBuyBackOrder/" + buyBackOrderId,
    method: 'POST',
  })
}

export function uploadContract(data) {
  return request({
    url: "/business/buyBackOrder/uploadContract",
    method: 'POST',
    data
  })
}
export function transferorSignRegulatoryAgreement(buyBackOrderId) {
  return request({
    url: "/business/buyBackOrder/transferorSignRegulatoryAgreement/" + buyBackOrderId,
    method: 'POST',
  })
}
export function receiverSignRegulatoryAgreement(buyBackOrderId) {
  return request({
    url: "/business/buyBackOrder/receiverSignRegulatoryAgreement/" + buyBackOrderId,
    method: 'POST',
  })
}

export function transferorSignTransferAgreement(buyBackOrderId) {
  return request({
    url: "/business/buyBackOrder/transferorSignTransferAgreement/" + buyBackOrderId,
    method: 'POST',
  })
}

export function receiverSignTransferAgreement(buyBackOrderId) {
  return request({
    url: "/business/buyBackOrder/receiverSignTransferAgreement/" + buyBackOrderId,
    method: 'POST',
  })
}
export function getRegulatoryAgreement(params) {
  return request({
    url: "/business/buyBackOrder/getRegulatoryAgreement",
    method: 'GET',
    params
  })
}
export function getTransferContract(params) {
  return request({
    url: "/business/buyBackOrder/getTransferContract",
    method: 'GET',
    params
  })
}
export function transferorSendRelieveRegulatoryAgreement(buyBackOrderId) {
  return request({
    url: "/business/buyBackOrder/transferorSendRelieveRegulatoryAgreement/" + buyBackOrderId,
    method: 'POST',
  })
}
export function receiverSignRelieveRegulatoryAgreement(buyBackOrderId) {
  return request({
    url: "/business/buyBackOrder/receiverSignRelieveRegulatoryAgreement/" + buyBackOrderId,
    method: 'POST',
  })
}

export function getReleaseRegulatoryAgreements(id) {
  return request({
    url: "/business/buyBackOrder/getReleaseRegulatoryAgreements/" + id,
    method: 'POST',
  })
}