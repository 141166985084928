import request from '@/utils/request'

// 查询系统基础配置详细
export function getSystemConfiguration() {
  return request({
    url: '/business/systemConfiguration',
    method: 'get'
  })
}

// 修改 用户服务协议
export function updateUserServiceAgreement(data) {
  return request({
    url: '/business/systemConfiguration/updateUserServiceAgreement',
    method: 'post',
    data: data
  })
}

// 修改 系统设置
export function updateSystemSettings(data) {
  return request({
    url: '/business/systemConfiguration/updateSystemSettings',
    method: 'post',
    data: data
  })
}
