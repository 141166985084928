<template>
  <div class="contianer">
    <template>
      <div class="scrollImg">
        <img ref="image" class="carouselImg" src="../assets/orderBg.png" />
      </div>
      <div class="box-tabs">
        <div class="box-content">
          <span class="box-tabs-name">我的订单</span>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              :label="item.label"
              :name="item.name"
              v-for="item in tabsList"
              :key="item.name"
            ></el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="box-content" style="margin-top: 40px">
        <el-form :model="queryParams" :inline="true" label-width="80px">
          <el-row>
            <el-form-item
              label="仓库名称"
              v-show="activeName == 1 || activeName == 2"
            >
              <el-input v-model="queryParams.warehouseName"></el-input>
            </el-form-item>
            <el-form-item
              label=" 接收方"
              v-show="activeName == 3 || activeName == 5"
            >
              <el-input v-model="queryParams.receiverName"></el-input>
            </el-form-item>
            <el-form-item label=" 转让方" v-show="activeName == 4">
              <el-input v-model="queryParams.transferorName"></el-input>
            </el-form-item>
            <el-form-item label="商品类型">
              <el-input v-model="queryParams.goodsTypeName"> </el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="queryParams.orderState">
                <el-option
                  v-for="(item, index) of orderStateList"
                  :key="index"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-button icon="el-icon-search" @click="handleClick"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" @click="resetQuery"
              >重置</el-button
            >
          </el-row>
        </el-form>
      </div>

      <!-- 入库单 -->
      <transition name="el-fade-in">
        <div class="box-content" v-if="activeName == '1'">
          <div class="box-table">
            <el-table
              :data="warehousingOrderList"
              style="width: 100%"
              :header-cell-style="{
                background: '#F2F2F2',
                'text-align': 'center',
              }"
              border
              width="80"
            >
              <!-- <el-table-column type="selection" width="55" align="center" /> -->
              <el-table-column
                label="入库预约号"
                align="center"
                prop="orderNum"
                width="180"
                :show-overflow-tooltip="true"
              />
              <el-table-column
                label="仓库"
                align="center"
                prop="warehouseName"
                :show-overflow-tooltip="true"
              />
              <el-table-column
                label="预计到货时间"
                align="center"
                prop="estimatedArrivalTime"
                width="130"
              >
                <template slot-scope="scope">
                  <span>{{
                    parseTime(scope.row.estimatedArrivalTime, "{y}-{m}-{d}")
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="类型"
                align="center"
                prop="goodsTypeLabel"
                :show-overflow-tooltip="true"
              />
              <el-table-column
                label="商品型号"
                align="center"
                prop="goodsModelLabel"
                :show-overflow-tooltip="true"
              />
              <el-table-column
                label="总重量"
                align="center"
                prop="totalWeight"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="{ row }">
                  {{ row.totalWeight ? row.totalWeight : "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="总净重"
                align="center"
                prop="netWeight"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="{ row }">
                  {{ row.netWeight ? row.netWeight : "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="总数量"
                align="center"
                prop="totalQuantity"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="{ row }">
                  {{ row.totalQuantity ? row.totalQuantity : "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="状态"
                align="center"
                prop="orderState"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  {{ getState(scope.row.orderState) }}
                </template>
                <!-- <template slot-scope="scope">
									<dict-tag :options="dict.type.warehousing_status" :value="scope.row.orderState" />
								</template> -->
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                class-name="small-padding fixed-width"
                width="130"
              >
                <template slot-scope="scope">
                  <div
                    class="box-select-btn box-btn-center"
                    @click="selectDetails(scope.row)"
                  >
                    查看详情
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="box-pagination">
            <PaginationPage
              v-show="total > 0"
              :total="total"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="getList"
            ></PaginationPage>
          </div>
        </div>
      </transition>
      <!-- 出库单 -->
      <transition name="el-fade-in">
        <div class="box-content" v-if="activeName == '2'">
          <div class="box-table">
            <el-table
              :data="warehousingOutOrderList"
              style="width: 100%"
              :header-cell-style="{
                background: '#F2F2F2',
                'text-align': 'center',
              }"
              border
              width="80"
            >
              <el-table-column
                label="出库预约号"
                align="center"
                prop="orderNum"
              >
              </el-table-column>
              <el-table-column label="仓库" align="center" prop="warehouseName">
              </el-table-column>
              <el-table-column label="预计出库时间" align="center" prop="name">
                <template slot-scope="scope">
                  <span>{{ scope.row.estimatedDeliveryTime }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="商品"
                align="center"
                prop="goodsTypeLabel"
              >
              </el-table-column>
              <el-table-column label="总重量" align="center" prop="totalWeight">
                <template slot-scope="{ row }">
                  {{ row.totalWeight ? row.totalWeight : "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="总净重"
                align="center"
                prop="netWeight"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="{ row }">
                  {{ row.netWeight ? row.netWeight : "--" }}
                </template>
              </el-table-column>

              <el-table-column
                label="总数量"
                align="center"
                prop="totalQuantity"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="{ row }">
                  {{ row.totalQuantity ? row.totalQuantity : "--" }}
                </template>
              </el-table-column>
              <el-table-column label="状态" align="center" prop="orderState">
                <template slot-scope="scope">
                  <!-- {{ getState(scope.row.orderState)  }} -->
                  {{ dict.warehousing_out_status[scope.row.orderState] }}
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <!-- <el-button size="mini" type="danger"
									@click="handleDelete(scope.$index, scope.row)">删除</el-button> -->
                  <div
                    class="box-select-btn box-btn-center"
                    @click="selectOutDetails(scope.row)"
                  >
                    查看详情
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="box-pagination">
            <PaginationPage
              v-show="total > 0"
              :total="total"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="getOutList"
            ></PaginationPage>
          </div>
        </div>
      </transition>
      <!-- 回购单 -->
      <transition name="el-fade-in">
        <div class="box-content" v-if="activeName == '3'">
          <div class="box-table">
            <el-table
              :data="buyBackOrderList"
              style="width: 100%"
              :header-cell-style="{
                background: '#F2F2F2',
                'text-align': 'center',
              }"
              border
              width="80"
            >
              <el-table-column label="回购单号" align="center" prop="orderNum">
              </el-table-column>
              <el-table-column
                label="接收方"
                align="center"
                prop="receiverName"
              >
                <template slot-scope="{ row }">
                  {{ row.receiverName ? row.receiverName : "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="商品信息"
                align="center"
                prop="goodsTypeLabel"
              >
              </el-table-column>
              <el-table-column label="总重量" align="center" prop="totalWeight">
                <template slot-scope="{ row }">
                  {{ row.totalWeight ? row.totalWeight : "--" }}
                </template>
              </el-table-column>
              <el-table-column label="总净重" align="center" prop="netWeight">
                <template slot-scope="{ row }">
                  {{ row.netWeight ? row.netWeight : "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="总数量"
                align="center"
                prop="totalQuantity"
              >
                <template slot-scope="{ row }">
                  {{ row.totalQuantity ? row.totalQuantity : "--" }}
                </template>
              </el-table-column>
              <el-table-column label="状态" align="center" prop="orderState">
                <template slot-scope="scope">
                  {{ dict.buy_back_state[scope.row.orderState] }}
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <div
                    class="box-select-btn box-btn-center"
                    @click="byBackDetails(scope.row, true)"
                  >
                    查看详情
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="box-pagination">
            <PaginationPage
              v-show="total > 0"
              :total="total"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="getBuyBackList"
            ></PaginationPage>
          </div>
        </div>
      </transition>
      <transition name="el-fade-in">
        <div class="box-content" v-if="activeName == '4'">
          <div class="box-table">
            <el-table
              :data="receiverBuyBackOrderList"
              style="width: 100%"
              :header-cell-style="{
                background: '#F2F2F2',
                'text-align': 'center',
              }"
              border
              width="80"
            >
              <el-table-column label="回购单号" align="center" prop="orderNum">
              </el-table-column>
              <el-table-column
                label="转让方"
                align="center"
                prop="transferorName"
              >
              </el-table-column>
              <el-table-column
                label="商品信息"
                align="center"
                prop="goodsTypeLabel"
              >
              </el-table-column>
              <el-table-column label="总重量" align="center" prop="totalWeight">
                <template slot-scope="{ row }">
                  {{ row.totalWeight ? row.totalWeight : "--" }}
                </template>
              </el-table-column>
              <el-table-column label="总净重" align="center" prop="netWeight">
                <template slot-scope="{ row }">
                  {{ row.netWeight ? row.netWeight : "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="总数量"
                align="center"
                prop="totalQuantity"
              >
                <template slot-scope="{ row }">
                  {{ row.totalQuantity ? row.totalQuantity : "--" }}
                </template>
              </el-table-column>
              <el-table-column label="状态" align="center" prop="orderState">
                <template slot-scope="scope">
                  {{
                    dict.buy_back_state &&
                    dict.buy_back_state[scope.row.orderState]
                  }}
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <div
                    class="box-select-btn box-btn-center"
                    @click="byBackDetails(scope.row, false)"
                  >
                    查看详情
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="box-pagination">
            <PaginationPage
              v-show="total > 0"
              :total="total"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="getBuyBackList"
            ></PaginationPage>
          </div>
        </div>
      </transition>
      <!-- 转让单 -->
      <transition name="el-fade-in">
        <div class="box-content" v-if="activeName == '5'">
          <div class="box-table">
            <el-table
              :data="transferOrderList"
              style="width: 100%"
              :header-cell-style="{
                background: '#F2F2F2',
                'text-align': 'center',
              }"
              border
              width="80"
            >
              <el-table-column label="回购单号" align="center" prop="orderNum">
              </el-table-column>
              <el-table-column
                label="接收方"
                align="center"
                prop="receiverName"
              >
                <template slot-scope="{ row }">
                  {{ row.receiverName ? row.receiverName : "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="商品信息"
                align="center"
                prop="goodsTypeLabel"
              >
              </el-table-column>
              <el-table-column label="总重量" align="center" prop="totalWeight">
                <template slot-scope="{ row }">
                  {{ row.totalWeight ? row.totalWeight : "--" }}
                </template>
              </el-table-column>
              <el-table-column label="总净重" align="center" prop="netWeight">
                <template slot-scope="{ row }">
                  {{ row.netWeight ? row.netWeight : "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="总数量"
                align="center"
                prop="totalQuantity"
              >
                <template slot-scope="{ row }">
                  {{ row.totalQuantity ? row.totalQuantity : "--" }}
                </template>
              </el-table-column>
              <el-table-column label="状态" align="center" prop="orderState">
                <template slot-scope="scope">
                  {{
                    dict.transfer_order_state &&
                    dict.transfer_order_state[scope.row.orderState]
                  }}
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <div
                    class="box-select-btn box-btn-center"
                    @click="transferenceDetails(scope.row, false)"
                  >
                    查看详情
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="box-pagination">
            <el-pagination
              v-show="total > 0"
              :total="total"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="getList"
              background
              layout="prev, pager, next"
            >
            </el-pagination>
          </div>
        </div>
      </transition>
    </template>
  </div>
</template>
<script>
import { listWarehousingOrder } from "@/api/business/warehousingOrder";
import { goodsTypeListAll } from "@/api/warehouse";
import { listWarehousingOutOrder } from "@/api/business/warehousingOutOrder";
import {
  getTransferorBuyBackOrderList,
  getReceiverBuyBackOrderList,
} from "@/api/business/buyBack";
import { getCustomerTransferOrderList } from "@/api/business/transference";

export default {
  name: "CessNav",
  components: {},
  data() {
    return {
      dicts: [
        "buy_back_state",
        "transfer_order_state",
        "warehousing_status",
        "warehousing_out_status",
      ],
      dict: {
        buy_back_state: {},
        transfer_order_state: {},
      },
      dictOption: {},
      warehousingStatus: [],
      orderStateList: [],
      sessiontext: "我的货物",
      activeName: "1",
      bannerHeight: "",
      // 总条数
      total: 0,
      // 入库单记录表格数据
      warehousingOrderList: [],
      //出库单记录表格数据
      warehousingOutOrderList: [],
      buyBackOrderList: [],
      receiverBuyBackOrderList: [],
      transferOrderList: [],
      tabsList: [
        {
          label: "入库单",
          name: "1",
        },
        {
          label: "出库单",
          name: "2",
        },
        {
          label: "回购单(转让)",
          name: "3",
        },
        {
          label: "回购单(接收)",
          name: "4",
        },
        {
          label: "转让单",
          name: "5",
        },
      ],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        warehouseName: "",
        goodsTypeName: "",
        orderState: "",
        receiverName: "",
        transferorName: "",
      },
    };
  },
  computed: {
    getState() {
      return (value) => {
        if (this.warehousingStatus) {
          const item = this.warehousingStatus.find(
            (item) => parseInt(item.dictValue) === value
          );
          return item ? item.dictLabel : value;
        }
      };
    },
  },
  created() {
    this.getList();
    this.getDictsData();
    this.getunitType();
    //获取仓库列表
    // this.warehouseListAll()
  },
  methods: {
    getDictsData() {
      this.getDicts("warehousing_status").then((res) => {
        this.warehousingStatus = res.data.data;
      });
    },
    getunitType() {
      this.dicts.map((item) => {
        this.getDicts(item).then((res) => {
          this.dict[item] = {};
          this.dictOption[item] = res.data.data;
          res.data.data.map((value) => {
            this.dict[item][value.dictValue] = value.dictLabel;
          });
        });
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    /** 查询入库单记录列表 */
    getList() {
      this.loading = true;
      listWarehousingOrder(this.queryParams).then((response) => {
        this.warehousingOrderList = response.data.data.list;
        this.total = response.data.data.total;
        this.loading = false;
      });
    },
    //查询出库单纪录列表
    getOutList() {
      this.loading = true;
      listWarehousingOutOrder(this.queryParams).then((response) => {
        this.warehousingOutOrderList = response.data.rows;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    getBuyBackList() {
      this.loading = true;
      getTransferorBuyBackOrderList(this.queryParams).then((response) => {
        this.buyBackOrderList = response.data.data.list;
        this.total = response.data.data.total;
        this.loading = false;
      });
    },
    getReceiverBuyBackOrderList() {
      this.loading = true;
      getReceiverBuyBackOrderList(this.queryParams).then((response) => {
        this.receiverBuyBackOrderList = response.data.data.list;
        this.total = response.data.data.total;
        this.loading = false;
      });
    },
    getCustomerTransferOrderList() {
      this.loading = true;
      getCustomerTransferOrderList(this.queryParams).then((response) => {
        this.transferOrderList = response.data.data.list;
        this.total = response.data.data.total;
        this.loading = false;
      });
    },
    //仓库集合
    // warehouseListAll() {
    // 	warehouseListAll().then(response => {
    // 		this.warehouseList = response.data
    // 	})
    // },
    resetQuery() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        warehouseName: "",
        goodsTypeName: "",
        orderState: "",
        receiverName: "",
        transferorName: "",
      };
      this.handleClick();
    },
    handleClick() {
      if (this.activeName == "1") {
        this.queryParams.transferorName = "";
        this.queryParams.receiverName = "";
        this.orderStateList = this.dictOption.warehousing_status;
        this.getList();
      } else if (this.activeName == "2") {
        this.queryParams.transferorName = "";
        this.queryParams.receiverName = "";
        this.orderStateList = this.dictOption.warehousing_out_status;
        this.getOutList();
      } else if (this.activeName == "3") {
        this.queryParams.warehouseName = "";
        this.queryParams.transferorName = "";
        this.orderStateList = this.dictOption.buy_back_state;
        this.getBuyBackList();
      } else if (this.activeName == "4") {
        this.queryParams.warehouseName = "";
        this.queryParams.receiverName = "";
        this.orderStateList = this.dictOption.buy_back_state;
        this.getReceiverBuyBackOrderList();
      } else if (this.activeName == "5") {
        this.queryParams.warehouseName = "";
        this.queryParams.transferorName = "";
        this.orderStateList = this.dictOption.transfer_order_state;
        this.getCustomerTransferOrderList();
      }
    },
    selectOutDetails(row) {
      this.$router.push({
        path: "/orderOutDetailsPage",
        query: {
          id: row.id,
        },
      });
    },
    byBackDetails(row, isTransferor) {
      this.$router.push({
        path: "/byBackDetails",
        query: {
          id: row.id,
        },
      });
    },
    selectDetails(row) {
      /** 修改按钮操作 */
      this.$router.push({
        path: "/orderDetailsPage",
        query: {
          id: row.orderId,
        },
      });
      // this.$router.push('/orderDetailsPage')
    },
    transferenceDetails(row) {
      this.$router.push({
        path: "/transferenceDetails",
        query: {
          id: row.id,
        },
      });
    },
  },
  mounted() {
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.bannerHeight = (600 / 1550) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (600 / 1550) * this.screenWidth;
    };
  },
};
</script>
<style scoped>
.contianer {
  text-align: left;
}
.box-addBtn {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  background: #40b8ad;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
  cursor: pointer;
}

.box-table {
  margin-top: 30px;
}

.edit-content {
  float: right;
  margin-right: 30px;
  display: flex;
  margin-top: 30px;
}

.re-certification {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #40b8ad;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-left: 20px;
  cursor: pointer;
}

.personal-edit {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #ffffff;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #40b8ad;
  cursor: pointer;
}

.personal-phone {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 16px;
  text-align: left;
}

.personal-name {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  margin-top: 16px;
  text-align: left;
}

.personal-corporation {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 72px;
  text-align: left;
}

.personal-corporation span {
  margin-left: 20px;
  padding: 4px 6px;
  background: #ffffff;
  border: 1px solid #ff9000;
  border-radius: 5px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ff9000;
}

.personal-text {
  margin-left: 30px;
}

.box-personal-session {
  float: left;
  margin-left: 50px;
  height: 100%;
  display: flex;
}

.block {
  margin-top: 52px;
}

.box-personal {
  height: 238px;
  background: #f6f6f6;
  margin: 60px 0;
  position: relative;
}

.box-tabs-name {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  height: 90px;
  line-height: 90px;
  float: left;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-tabs__header {
  margin: 0;
}

/deep/ .el-tabs__content {
  display: none;
}

/deep/ .el-tabs__item {
  height: 90px;
  line-height: 90px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

/deep/ .el-tabs {
  float: right;
}

.box-tabs {
  height: 90px;
  background: #f6f6f6;
}

.box-select-submit {
  display: block;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 100px;
  width: 160px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 24px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

.box-select-submit:hover {
  opacity: 0.8;
}

.card-address {
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  text-align: left;
  margin-top: 10px;
}

.card-title {
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  text-align: left;
}

.card-img {
  width: 100%;
  height: 246px;
}

.box-card {
  width: 380px;
  height: 340px;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-bottom: 30px;
  transition: all 0.3s;
  /* 上浮这个过程需要的时间 */
  cursor: pointer;
}

.box-card:hover {
  box-shadow: 0px 0px 10px 0px rgba(65, 181, 172, 0.35);
  transform: translate(0, -3px);
}

.box-card-content {
  padding: 20px;
  height: 54px;
}

.box-tab-card {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
}

.box-card-title {
  text-align: center;
  height: 42px;
  line-height: 42px;
  font-size: 42px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231714;
  margin-top: 167px;
}

.borderShare {
  border-right: 1px dashed #eeeeee;
}

.box-tab {
  text-align: center;
  width: 25%;
  box-sizing: border-box;
  cursor: pointer;
}

.box-tab span {
  display: block;
  text-align: center;
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  margin-top: 10px;
}

.box-tab img {
  display: block;
  width: 92px;
  margin: 0 auto;
  margin-top: 13px;
}

.box-fixed-tab {
  position: relative;
  margin: 0 auto;
  margin-top: -88px;
  width: 1200px;
  height: 150px;
  padding: 13px 0;
  background: #ffffff;
  box-shadow: 0px -6px 24px 0px rgba(65, 181, 172, 0.35);
  z-index: 99;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.carouselImg {
  width: 100%;
  height: inherit;
}

.scrollImg {
  width: 100%;
  height: auto;
  /* max-height: 780px; */
  position: relative;
}

/deep/ .el-carousel--horizontal {
  max-height: 780px;
}

/deep/ .el-carousel__container {
  max-height: 780px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.demo {
  display: inline-block;
  width: 600px;
  height: 338px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
</style>
