<template>
  <div>
    <template>
      <div class="scrollImg">
        <img ref="image" class="carouselImg" src="../assets/orderBg.png" />
      </div>
      <div class="box-tabs">
        <div class="box-content">
          <span class="box-tabs-name">我的订单</span>
        </div>
      </div>
      <div class="box-content">
        <div class="box-personal">
          <div class="box-details-number">
            <div class="box-number">单号：{{ orderForm.orderNum }}</div>
            <div class="box-details-content">
              <div class="box-content-left">
                <div class="box-tecxt">仓库：{{ orderForm.warehouseName }}</div>
                <div class="box-tecxt">
                  预计出库时间：{{ orderForm.estimatedDeliveryTime }}
                </div>
                <div class="box-tecxt">备注：{{ orderForm.remark }}</div>
              </div>
              <div class="box-content-right">
                <div class="box-tecxt">
                  下单时间：{{ orderForm.createTime }}
                </div>
                <div class="box-tecxt">
                  最晚出库时间：{{ orderForm.lastEstimatedDeliveryTime }}
                </div>
              </div>
            </div>
          </div>
          <div class="box-types">
            <view>{{ dict.warehousing_out_status[orderForm.orderState] }}</view>
          </div>

          <div class="edit-content">
            <!-- <div class="personal-edit" v-if="orderForm.orderState > 5">
              查看账单
            </div> -->
            <div
              class="personal-edit"
              v-if="orderForm.orderState == 1"
              @click="uploadData"
            >
              修改信息
            </div>
            <div
              class="re-certification"
              v-if="orderForm.orderState == 1"
              @click="$refs.rejectDialog.showDialog()"
            >
              取消出库
            </div>
          </div>
        </div>
      </div>
      <div class="box-content">
        <div class="box-card-title">详情</div>
        <titlePage :titleText="sessiontext" class=""></titlePage>
        <div class="box-table">
          <el-table
            :data="orderForm.goodsDetailRecordList"
            style="width: 100%"
            :header-cell-style="{
              background: '#F2F2F2',
              'text-align': 'center',
              color: '#333333',
            }"
            border
            width="80"
          >
            <el-table-column
              label="商品分类"
              align="center"
              prop="goodsTypeName"
            >
            </el-table-column>
            <el-table-column label="商品名称" align="center" prop="goodsName">
            </el-table-column>
            <el-table-column
              label="商品厂家"
              align="center"
              prop="goodsManufacturerName"
            >
            </el-table-column>
            <el-table-column label="型号" align="center" prop="goodsModelName">
            </el-table-column>
            <el-table-column label="规格" align="center" prop="goodsSpecName">
            </el-table-column>
            <el-table-column label="总数量" align="center" prop="totalQuantity">
              <template slot-scope="{ row }">
                {{ row.totalQuantity ? row.totalQuantity : "--" }}
              </template>
            </el-table-column>
            <el-table-column
              label="总数量单位"
              align="center"
              prop="totalQuantityUnit"
            >
              <template slot-scope="{ row }">
                {{
                  row.totalQuantityUnit
                    ? dict.sys_user_count_unit[row.totalQuantityUnit]
                    : "--"
                }}
              </template>
            </el-table-column>
            <el-table-column label="总重量" align="center" prop="totalWeight">
              <template slot-scope="{ row }">
                {{ row.totalWeight ? row.totalWeight : "--" }}
              </template>
            </el-table-column>
            <el-table-column
              label="总重量单位"
              align="center"
              prop="totalWeightUnit"
            >
              <template slot-scope="{ row }">
                {{
                  row.totalWeightUnit
                    ? dict.sys_user_weight_unit[row.totalWeightUnit]
                    : "--"
                }}
              </template>
            </el-table-column>
            <el-table-column label="净重" align="center" prop="netWeight">
              <template slot-scope="{ row }">
                {{ row.netWeight ? row.netWeight : "--" }}
              </template>
            </el-table-column>
            <el-table-column
              label="净重单位"
              align="center"
              prop="netWeightUnit"
            >
              <template slot-scope="{ row }">
                {{
                  row.netWeightUnit
                    ? dict.sys_user_weight_unit[row.netWeightUnit]
                    : "--"
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="入库要求"
              align="center"
              prop="warehousingRequirements"
            >
              <template slot-scope="scope">
                <span>{{
                  dict.warehousing_requirements[
                    scope.row.warehousingRequirements
                  ]
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="是否带托入库"
              align="center"
              prop="isWarehousingWithTray"
            >
              <template slot-scope="scope">
                <span>{{
                  dict.sys_yes_no[scope.row.isWarehousingWithTray]
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <template v-if="orderForm.isNeedPlatformDriver == '1'">
          <titlePage :titleText="takeAway" class="li-top"></titlePage>
          <div class="box-details-session">
            <div class="take-delivery-info">
              <div>
                司机类型：{{
                  orderForm.isNeedPlatformDriver == "1"
                    ? "平台司机"
                    : "自备司机"
                }}
              </div>
              <div>提货联系人：{{ orderForm.receiptContact }}</div>
              <div>联系人电话：{{ orderForm.receiptContactPhone }}</div>
            </div>
            <div class="details-address box-top">
              <span>提货地址：{{ orderForm.receiptAddress }}</span>
              <div class="box-voucher">
                <span>提货凭证单：</span
                ><span
                  ><img
                    :src="item ? item : ''"
                    alt=""
                    srcset=""
                    v-for="(item, index) in orderForm.receiptVoucherPic"
                    :key="index"
                  />
                </span>
              </div>
            </div>
          </div>
        </template>

        <titlePage
          :titleText="cartext"
          class="li-top"
          v-if="orderForm.driverRecordList && orderForm.driverRecordList.length"
        ></titlePage>
        <div v-for="item in orderForm.driverRecordList" :key="item.id">
          <div class="box-tips-text">
            {{ dict.warehousing_out_drvier_status[item.driverState] }}
          </div>
          <div class="box-details-session" style="margin-bottom: 40px">
            <div class="details-address">
              <span>司机姓名：{{ item.driverRecord.driverName }}</span>
              <span>司机手机号：{{ item.driverRecord.driverPhone }}</span>
              <span>司机身份证号：{{ item.driverRecord.driverIdCard }}</span>
              <span>车牌号：{{ item.carNumber }}</span>
            </div>
          </div>
          <div
            class="box-table"
            style="margin-bottom: 40px"
            v-if="item.driverState"
          >
            <el-table
              :data="
                item.confirmationRecord
                  ? item.confirmationRecord.detailList
                  : item.goodsList
              "
              style="width: 100%"
              :header-cell-style="{
                background: '#F2F2F2',
                'text-align': 'center',
                color: '#333333',
              }"
              border
              width="80"
            >
              <el-table-column
                label="商品分类"
                align="center"
                prop="goodsDetailRecord.goodsTypeName"
              >
              </el-table-column>
              <el-table-column
                label="商品名称"
                align="center"
                prop="goodsDetailRecord.goodsName"
              >
              </el-table-column>
              <el-table-column
                label="商品厂家"
                align="center"
                prop="goodsDetailRecord.goodsManufacturerName"
              >
              </el-table-column>
              <el-table-column
                label="型号"
                align="center"
                prop="goodsDetailRecord.goodsModelName"
              >
              </el-table-column>
              <el-table-column
                label="规格"
                align="center"
                prop="goodsDetailRecord.goodsSpecName"
              >
              </el-table-column>
              <el-table-column
                label="总数量"
                align="center"
                prop="totalQuantity"
              >
                <template slot-scope="{ row }">
                  {{ row.totalQuantity ? row.totalQuantity : "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="总数量单位"
                align="center"
                prop="totalQuantityUnit"
              >
                <template slot-scope="{ row }">
                  {{
                    row.totalQuantityUnit
                      ? dict.sys_user_count_unit[row.totalQuantityUnit]
                      : "--"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="总重量" align="center" prop="totalWeight">
                <template slot-scope="{ row }">
                  {{ row.totalWeight ? row.totalWeight : "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="总重量单位"
                align="center"
                prop="totalWeightUnit"
              >
                <template slot-scope="{ row }">
                  {{
                    row.totalWeightUnit
                      ? dict.sys_user_weight_unit[row.totalWeightUnit]
                      : "--"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="净重" align="center" prop="netWeight">
                <template slot-scope="{ row }">
                  {{ row.netWeight ? row.netWeight : "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="净重单位"
                align="center"
                prop="netWeightUnit"
              >
                <template slot-scope="{ row }">
                  {{
                    row.netWeightUnit
                      ? dict.sys_user_weight_unit[row.netWeightUnit]
                      : "--"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="入库要求" align="center">
                <template slot-scope="scope">
                  <span>{{
                    dict.warehousing_requirements[
                      scope.row.goodsDetailRecord.warehousingRequirements
                    ]
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="是否带托入库" align="center">
                <template slot-scope="scope">
                  <span>{{
                    dict.sys_yes_no[
                      scope.row.goodsDetailRecord.isWarehousingWithTray
                    ]
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="box-btns">
            <div class="box-btns-emg box-all-btn" @click="transportation(item)">
              查看运输单
            </div>
            <div
              v-if="item.driverState == 21"
              class="box-btns-primary box-all-btn"
              @click="postConfirm(item)"
            >
              核对出库单
            </div>
            <div
              class="box-btns-primary box-all-btn"
              v-if="item.isDispatchForklift && orderForm.orderState == 3"
              @click="monitorInfo(item)"
            >
              查看监控
            </div>
            <div
              v-if="
                item.confirmationRecord &&
                item.confirmationRecord.confirmationState == 4
              "
              class="box-btns-primary box-all-btn"
              @click="lookPostConfirm(item)"
            >
              查看出库单
            </div>

            <div
              v-if="item.driverState == 3"
              class="box-btns-primary box-all-btn"
              @click="selectTrajectory(item)"
            >
              查看位置
            </div>
            <div
              v-if="
                item.driverState == 4 && orderForm.isNeedPlatformDriver == 1
              "
              class="box-btns-primary box-all-btn"
              @click="selectPicture(item)"
            >
              查看送达信息
            </div>
          </div>
        </div>
      </div>
    </template>
    <dialogPage taostTips="运输轨迹" width="1000px" ref="trajectory">
      <template v-slot:content>
        <mapPage :lineArr="lineArr"></mapPage>
      </template>
    </dialogPage>
    <dialogPage taostTips="出库送达照片" width="870px" ref="picture">
      <template v-slot:content>
        <div class="box-picture-list">
          <div
            class="box-picture"
            v-for="(item, index) in pictureImg.split(',')"
            :key="index"
          >
            <img :src="item" alt="" srcset="" />
          </div>
        </div>
      </template>
    </dialogPage>
    <dialogPage taostTips="视频" width="800px" ref="video">
      <template v-slot:content>
        <video src="" width="100%" height="450"></video>
      </template>
    </dialogPage>
    <dialogPage taostTips="取消出库" width="800px" ref="rejectDialog">
      <el-form>
        <el-form-item label="原因：" label-width="80px">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入内容"
            v-model="rejectrReason"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="$refs.rejectDialog.closeDialog()">取 消</el-button>
        <el-button type="primary" @click="reject">确 定</el-button>
      </div>
    </dialogPage>
    <dialogPage taostTips="查看运输单" ref="transportation" width="80%">
      <template v-slot:content>
        <el-table
          :data="transportationList"
          style="width: 100%"
          :header-cell-style="{
            background: '#F2F2F2',
            'text-align': 'center',
            color: '#333333',
          }"
          border
          width="80"
        >
          <el-table-column
            label="商品分类"
            align="center"
            prop="goodsDetailRecord.goodsTypeName"
          >
          </el-table-column>
          <el-table-column
            label="商品名称"
            align="center"
            prop="goodsDetailRecord.goodsName"
          >
          </el-table-column>
          <el-table-column
            label="商品厂家"
            align="center"
            prop="goodsDetailRecord.goodsManufacturerName"
          >
          </el-table-column>
          <el-table-column
            label="型号"
            align="center"
            prop="goodsDetailRecord.goodsModelName"
          >
          </el-table-column>
          <el-table-column
            label="规格"
            align="center"
            prop="goodsDetailRecord.goodsSpecName"
          >
          </el-table-column>
          <el-table-column label="总数量" align="center" prop="totalQuantity">
          </el-table-column>
          <el-table-column
            label="总数量单位"
            align="center"
            prop="totalQuantityUnit"
          >
            <template slot-scope="{ row }">
              {{ dict.sys_user_count_unit[row.totalQuantityUnit] }}
            </template>
          </el-table-column>
          <el-table-column label="总重量" align="center" prop="totalWeight">
          </el-table-column>
          <el-table-column
            label="总重量单位"
            align="center"
            prop="totalWeightUnit"
          >
            <template slot-scope="{ row }">
              {{ dict.sys_user_weight_unit[row.totalWeightUnit] }}
            </template>
          </el-table-column>
          <el-table-column label="净重" align="center" prop="netWeight">
          </el-table-column>
          <el-table-column label="净重单位" align="center">
            <template slot-scope="{ row }">
              {{ dict.sys_user_weight_unit[row.netWeightUnit] }}
            </template>
          </el-table-column>
          <el-table-column
            label="入库要求"
            align="center"
            prop="goodsDetailRecord.warehousingRequirements"
          >
            <template slot-scope="scope">
              <span>{{
                dict.warehousing_requirements[
                  scope.row.goodsDetailRecord.warehousingRequirements
                ]
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="是否带托入库"
            align="center"
            prop="isWarehousingWithTray"
          >
            <template slot-scope="scope">
              <span>{{
                dict.sys_yes_no[
                  scope.row.goodsDetailRecord.isWarehousingWithTray
                ]
              }}</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </dialogPage>
  </div>
</template>
<script>
import {
  getWarehousingOrder,
  postConfirm,
  applyCancel,
  getTransportationDocuments,
  customerAbandon,
  getOutboundOrderConfirmationFormContract,
  getTransportationTrajectory,
} from "@/api/business/warehousingOutOrder";
export default {
  name: "CessNav",
  data() {
    return {
      lineArr: [],
      transportationList: [],
      pictureImg: "",
      dicts: [
        "sys_yes_no",
        "sys_user_count_unit",
        "sys_user_weight_unit",
        "warehousing_requirements",
        "warehousing_out_drvier_status",
        "warehousing_out_status",
      ],
      dict: {
        sys_yes_no: {},
        sys_user_count_unit: {},
        sys_user_weight_unit: {},
        warehousing_requirements: {},
        warehousing_out_drvier_status: {},
        warehousing_out_status: {},
      },
      sessiontext: "商品信息",
      takeAway: "提货信息",
      cartext: "车辆信息",
      activeName: null,
      bannerHeight: "",
      orderConfirmData: {},
      rejectrReason: "",
      params: "",
      orderForm: {
        goodsDetailRecordList: [],
      },
    };
  },
  computed: {},
  created() {
    var params = this.$route.query.id;
    this.params = params;
    this.getWarehousingOrder();
  },
  methods: {
    monitorInfo(item) {
      this.$router.push({
        path: "/monitorPage",
        query: {
          orderType: 2,
          orderId: this.orderForm.id,
          orderDriverId: item.outboundOrderDriverId,
        },
      });
    },
    lookPostConfirm(item) {
      getOutboundOrderConfirmationFormContract({
        outboundOrderDriverId: item.outboundOrderDriverId,
      }).then((res) => {
        window.open(res.data.data.viewpdfUrl);
      });
    },
    uploadData() {
      this.$router.push({
        path: "/agreeonOutWarehousing",
        query: {
          orderId: this.orderForm.id,
        },
      });
    },
    customerAbandon() {
      this.$confirm("是否驳回出库确认单？", "提示", {
        type: "warning",
      }).then((res) => {
        customerAbandon(this.orderConfirmData.id).then((res) => {
          if (res.data.code == 200) {
            this.$message.success("操作成功");
          }
        });
      });
    },
    transportation(item) {
      getTransportationDocuments({
        outboundOrderDriverId: item.outboundOrderDriverId,
      }).then((res) => {
        console.log(res);
        this.transportationList = res.data.data;
        this.$refs.transportation.showDialog();
      });
    },
    reject() {
      if (!this.rejectrReason) {
        this.$message.info("请输入取消原因");
        return;
      }
      this.$confirm("是否取消入库", "提示", {
        type: "warning",
      }).then((res) => {
        applyCancel({
          outboundOrderId: this.orderForm.id,
          reason: this.rejectrReason,
        }).then((res) => {
          this.$message.success("取消成功");
          this.$refs.rejectDialog.closeDialog();
          this.getWarehousingOrder();
        });
      });
    },
    getWarehousingOrder() {
      getWarehousingOrder(this.params).then((response) => {
        if (response.data.data.receiptVoucherPic) {
          let imgUrl = response.data.data.receiptVoucherPic.split(",");
          response.data.data.receiptVoucherPic = imgUrl;
        } else {
          response.data.data.receiptVoucherPic = [];
        }
        this.orderForm = response.data.data;
        this.orderForm.driverRecordList.map((item, index) => {
          if (item.confirmationRecord) {
            item.goodsList = item.confirmationRecord.detailList;
          }
        });
      });
    },

    selectPicture(item) {
      this.pictureImg = item.deliveryPhotos;
      this.$refs.picture.showDialog();
    },
    selectTrajectory(item) {
      getTransportationTrajectory({
        outboundOrderDriverId: item.outboundOrderDriverId,
      }).then((res) => {
        if (res.data.data.length) {
          this.lineArr = res.data.data.map((item) => {
            return [item.longitude, item.latitude];
          });
        }
        this.$refs.trajectory.showDialog();
      });
    },
    postConfirm(item) {
      postConfirm(item.outboundOrderDriverId).then((res) => {
        window.open(res.data.data);
      });
    },
    //获取字典
    getunitType() {
      this.dicts.map((item) => {
        this.getDicts(item).then((res) => {
          this.dict[item] = {};
          res.data.data.map((value) => {
            this.dict[item][value.dictValue] = value.dictLabel;
          });
        });
      });
    },
  },
  mounted() {
    this.getunitType();
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.bannerHeight = (600 / 1550) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (600 / 1550) * this.screenWidth;
    };
  },
};
</script>
<style scoped>
.box-picture-list {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.box-picture {
  width: 250px;
  height: 250px;
  margin-bottom: 30px;
}

.box-picture img {
  width: 100%;
  height: 100%;
}

.box-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirm-order_button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.box-btns-warning {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background: #ff9000;
  box-sizing: border-box;
  margin-left: 30px;
}

.box-btns-emg {
  width: 150px;
  height: 52px;
  line-height: 50px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #40b8ad;
  background: #ffffff;
  border: 1px solid #40b8ad;
  box-sizing: border-box;
}

.box-btns-primary {
  padding: 0 5px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background: #40b8ad;
  margin-left: 30px;
}

.box-tips-text {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #40b8ad;
  text-align: left;
  margin-bottom: 30px;
}

.box-types {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #40b8ad;
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -12px;
}

.box-details-number {
  width: 670px;
}

.box-tecxt {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-bottom: 10px;
  text-align: left;
}

.box-details-content {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.box-number {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  text-align: left;
}

.box-voucher {
  flex: 1;
  margin-left: 200px;
}

.box-voucher span {
  float: left;
}

.box-voucher img {
  float: left;
  width: 146px;
  height: 146px;
}

.box-details-text {
  margin-top: 50px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 48px;
}

.box-top {
  margin-top: 20px;
}

.box-details-session {
  /* padding: 40px 45px; */
  /* background: #F0F0F0; */
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
}

.details-address {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

/* 
	.details-address:after {
		content: ' ';
		flex: auto;
	} */

/* 
	.details-address span {
		flex: 1;
	} */
.details-address span {
  line-height: 18px;
}

.li-top {
  margin: 60px 0 40px 0;
}

.box-card-title {
  text-align: center;
  height: 42px;
  line-height: 42px;
  font-size: 42px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231714;
  margin-top: 60px;
  margin-bottom: 45px;
}

.box-addBtn {
  width: 150px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  background: #40b8ad;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
  cursor: pointer;
}

.box-table {
  margin-top: 30px;
}

.edit-content {
  position: absolute;
  right: 30px;
  display: flex;
  top: 30px;
}

.re-certification {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #40b8ad;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-left: 20px;
  cursor: pointer;
}

.personal-edit {
  padding: 6px 12px;
  height: 18px;
  line-height: 18px;
  background: #ffffff;
  border: 1px solid #40b8ad;
  border-radius: 16px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #40b8ad;
  cursor: pointer;
}

.personal-phone {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 16px;
  text-align: left;
}

.personal-name {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  margin-top: 16px;
  text-align: left;
}

.personal-corporation {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  margin-top: 72px;
  text-align: left;
}

.personal-corporation span {
  margin-left: 20px;
  padding: 4px 6px;
  background: #ffffff;
  border: 1px solid #ff9000;
  border-radius: 5px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ff9000;
}

.personal-text {
  margin-left: 30px;
}

.box-personal-session {
  float: left;
  margin-left: 50px;
  height: 100%;
  display: flex;
}

.block {
  margin-top: 52px;
}

.box-personal {
  height: auto;
  background: #f6f6f6;
  margin: 60px 0;
  padding: 30px;
  position: relative;
}

.box-tabs-name {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  height: 90px;
  line-height: 90px;
  float: left;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-tabs__header {
  margin: 0;
}

/deep/ .el-tabs__content {
  display: none;
}

/deep/ .el-tabs__item {
  height: 90px;
  line-height: 90px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

/deep/ .el-tabs {
  float: right;
}

.box-tabs {
  height: 90px;
  background: #f6f6f6;
}

.box-select-submit {
  display: block;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 100px;
  width: 160px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 24px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

.box-select-submit:hover {
  opacity: 0.8;
}

.card-address {
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  text-align: left;
  margin-top: 10px;
}

.card-title {
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  text-align: left;
}

.card-img {
  width: 100%;
  height: 246px;
}

.box-card {
  width: 380px;
  height: 340px;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-bottom: 30px;
  transition: all 0.3s;
  /* 上浮这个过程需要的时间 */
  cursor: pointer;
}

.box-card:hover {
  box-shadow: 0px 0px 10px 0px rgba(65, 181, 172, 0.35);
  transform: translate(0, -3px);
}

.box-card-content {
  padding: 20px;
  height: 54px;
}

.box-tab-card {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
}

.borderShare {
  border-right: 1px dashed #eeeeee;
}

.box-tab {
  text-align: center;
  width: 25%;
  box-sizing: border-box;
  cursor: pointer;
}
.take-delivery-info {
  display: flex;
}
.take-delivery-info div {
  width: 30%;
  text-align: left;
}
.box-tab span {
  display: block;
  text-align: center;
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #231714;
  margin-top: 10px;
}

.box-tab img {
  display: block;
  width: 92px;
  margin: 0 auto;
  margin-top: 13px;
}

.box-fixed-tab {
  position: relative;
  margin: 0 auto;
  margin-top: -88px;
  width: 1200px;
  height: 150px;
  padding: 13px 0;
  background: #ffffff;
  box-shadow: 0px -6px 24px 0px rgba(65, 181, 172, 0.35);
  z-index: 99;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.carouselImg {
  width: 100%;
  height: inherit;
}

.scrollImg {
  width: 100%;
  height: auto;
  /* max-height: 780px; */
  position: relative;
}

/deep/ .el-carousel--horizontal {
  max-height: 780px;
}

/deep/ .el-carousel__container {
  max-height: 780px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.demo {
  display: inline-block;
  width: 600px;
  height: 338px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
</style>
