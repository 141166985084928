import http from '@/utils/http'
// 查询出库单记录列表
export function listWarehousingOutOrder(params) {
  return http.get('/business/outboundOrder/getCustomerOutboundOrderList', params)
}
//查询在库商品所属仓库
export function outWarehousingOrder(params) {
  return http.get('/business/outboundOrder/getInventoryGoodsWarehouseList', params)
}
// 查询出库单记录详细
export function getWarehousingOrder(id) {
  return http.get('/business/outboundOrder/getOutboundOrderDetail/' + id)
}

// 新增出库单记录
export function addWarehousingOrder(params) {
  return http.post('/business/warehousingOrder', params)
}

// 修改出库单记录
export function updateWarehousingOrder(params) {
  return http.put('/business/warehousingOrder', params)
}

// 删除出库单记录
export function delWarehousingOrder(id) {
  return http.delete('/business/warehousingOrder/' + id)
}
//出库单确认
export function postConfirm(id) {
  return http.post('/business/outboundOrder/customerConfirmation/' + id)
}

//出库单确认
export function applyCancel(data) {
  return http.post('/business/outboundOrder/applyCancel', data)
}

export function getTransportationDocuments(data) {
  return http.get('/business/outboundOrder/getTransportationDocuments', data)
}
export function getOutboundOrderConfirmation(outboundOrderDriverId) {
  return http.post('/business/outboundOrder/getOutboundOrderConfirmation/' + outboundOrderDriverId)
}
export function customerAbandon(outboundOrderDriverId) {
  return http.post('/business/outboundOrder/customerAbandon/' + outboundOrderDriverId)
}
export function getOutboundOrderDetail(outboundOrderDriverId) {
  return http.get('/business/outboundOrder/getOutboundOrderDetail/' + outboundOrderDriverId)
}
export function getInventoryGoodsListGroupCargoNumber(data) {
  return http.get('/business/outboundOrder/getInventoryGoodsListGroupCargoNumber',data)
}
export function getOutboundOrderConfirmationFormContract(data) {
  return http.get('/business/outboundOrder/getOutboundOrderConfirmationFormContract',data)
}
export function getTransportationTrajectory(data) {
  return http.get('/admin/outboundOrder/getTransportationTrajectory',data)
}
