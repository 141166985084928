<template>
  <div>
    <template>
      <div class="scrollImg">
        <img ref="image" class="carouselImg" src="../assets/ckimg.jpg" />
      </div>
      <div class="box-content">
        <div class="box-details-title">
          {{ contents.warehouseName }}
        </div>
        <div class="box-details-session">
          <div class="details-address">
            <span>地址：{{ contents.city }}</span>
            <span>距您{{ contents.distance }}km</span>
          </div>
          <div class="details-address box-top">
            <span>类型：{{ contents.warehouseType }}</span>
            <span>仓库面积：{{ contents.area }}㎡</span>
            <span
              >总容量：{{ contents.capacityCount
              }}{{ contents.capacityCountUnit }}</span
            >
          </div>
        </div>
        <div class="box-details-text" v-html="contents.remark"></div>
        <div class="agreeOn-success" @click="agreeonWarehousing">预约入库</div>
      </div>
    </template>
  </div>
</template>
<script>
import { getDetails } from "../api/warehouse.js";
import { isCustomerSignWarehousingContract } from "@/api/business/warehousingOrder";
export default {
  name: "CessNav",
  components: {},
  data() {
    return {
      bannerHeight: "",
      contents: {},
    };
  },
  methods: {
    handleClick(tab, event) {},
    agreeonWarehousing() {
      isCustomerSignWarehousingContract({
        warehouseId: this.contents.id,
      }).then((res) => {
        if (res.data.data) {
          this.$router.push({
            path: "/agreeonWarehousing",
            query: {
              warehouseName: this.contents.warehouseName,
              warehouseId: this.contents.id,
            },
          });
        } else {
          this.$message("该仓库未签署仓储协议");
        }
      });
    },
    //获取详情内容
    getWarehousingDetails(id) {
      getDetails(id, {
        longitude: this.$store.state.accurateAddress.lng,
        latitude: this.$store.state.accurateAddress.lat,
      })
        .then((response) => {
          if (response.data.code == 200) {
            this.contents = response.data.data;
          }
        })
        .catch(() => {
          this.$message.error("获取失败！");
        });
    },
  },
  mounted() {
    this.getWarehousingDetails(this.$route.query.id);
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.bannerHeight = (600 / 1550) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (600 / 1550) * this.screenWidth;
    };
  },
};
</script>
<style scoped>
.agreeOn-success {
  width: 450px;
  height: 62px;
  line-height: 62px;
  text-align: center;
  background: linear-gradient(-40deg, #41b5ac, #48dcb8);
  box-shadow: 0px 3px 7px 0px rgba(65, 181, 172, 0.35);
  border-radius: 31px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 158px;
  margin-bottom: 140px;
  cursor: pointer;
}
.box-details-text {
  margin-top: 50px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 48px;
}
.box-top {
  margin-top: 20px;
}

.box-details-session {
  padding: 40px 45px;
  background: #f0f0f0;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}

.details-address {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.box-details-title {
  font-size: 36px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  text-align: center;
  margin: 80px 0 50px 0;
}

.carouselImg {
  width: 100%;
  height: inherit;
}

.scrollImg {
  width: 100%;
  height: auto;
  /* max-height: 780px; */
  position: relative;
}

/deep/ .el-carousel--horizontal {
  max-height: 780px;
}

/deep/ .el-carousel__container {
  max-height: 780px;
}
</style>
